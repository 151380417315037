import {
    TRANSACTIONS_FETCH,
    TRANSACTIONS_FETCHED,
    TRANSACTIONS_RESET,
    TRANSACTIONS_COUNT_FETCH,
    TRANSACTIONS_COUNT_FETCHED,
    TRANSACTIONS_COUNT_RESET,
    TRANSACTIONS_COUNT_ERROR
} from "./types"

import {
    modelTransactionsCountFetch,
    modelTransactionsFetch
} from "../models/Transactions"

export const transactionsFetch = (
    type,
    state = null,
    location = null,
    filters = {},
    previous = null,
    next = null,
    from = null
) => {
    return async dispatch => {
        try {
            dispatch({
                type: TRANSACTIONS_FETCH,
                previous,
                next
            })

            const transactions = await modelTransactionsFetch(
                type,
                state,
                location,
                filters,
                previous,
                next,
                from
            )

            // console.log('transactions: ', transactions)

            dispatch({
                type: TRANSACTIONS_FETCHED,
                previous,
                next,
                payload: transactions,
                ssr: false
            })
        } catch (error) {
            console.log("Error transactions list: ", error)
        }
    }
}

export const asyncTransactionsFetch = async (
    type,
    state = null,
    location = null,
    filters = {},
    previous = null,
    next = null,
    from = null
) => {
    try {
        const transactions = await modelTransactionsFetch(
            type,
            state,
            location,
            filters,
            previous,
            next,
            from
        )
        return {
            type: TRANSACTIONS_FETCHED,
            payload: transactions,
            ssr: true
        }
    } catch (error) {
        console.log("Error transactions list: ", error)
    }
}

export const asyncTransactionsCountFetch = async (
    type,
    state = null,
    location = null,
    filters = {}
) => {
    try {
        const count = await modelTransactionsCountFetch(
            type,
            state,
            location,
            filters
        )
        return {
            type: TRANSACTIONS_COUNT_FETCHED,
            payload: count,
        }
    } catch (error) {
        console.log("Error transactions count: ", error)
    }
}

export const transactionsCountFetch = (
    type,
    state = null,
    locations = null,
    filters = {}
) => {
    return async dispatch => {
        dispatch({
            type: TRANSACTIONS_COUNT_FETCH
        })
        try {
            const count = await modelTransactionsCountFetch(
                type,
                state,
                locations,
                filters
            )
            dispatch({
                type: TRANSACTIONS_COUNT_FETCHED,
                payload: count
            })
        } catch (error) {
            console.log("Error transactions count: ", error)
            dispatch({
                type: TRANSACTIONS_COUNT_ERROR,
                payload: error
            })
        }
    }
}

export const transactionsReset = () => {
    return {
        type: TRANSACTIONS_RESET
    }
}

export const transactionsCountReset = () => {
    return {
        type: TRANSACTIONS_COUNT_RESET
    }
}