import axios from "../config/axios"
import _ from "lodash"
import getConfig from "next/config"
import Cookies from "universal-cookie"

import MD5 from "../helpers/MD5"

import {
    CHANGE_STATUS,
    CHANGE_TYPE,
    CHANGE_MIN,
    CHANGE_MAX,
    ADD_LOCATION,
    REMOVE_LOCATION,
    SET_FILTERED_LOCATIONS,
    COMPUTE_TARGET,
    COMPUTED_TARGET,
    CHANGE_EMAIL,
    CHANGE_EMAIL_CONFIRMATION,
    SUBMIT_FIRST_STEP,
    SUBMITTED_FIRST_STEP,
    RESET_FIRST_STEP,
    ERROR_SUBMITTED_FIRST_STEP,
    SUBMIT_SECOND_STEP,
    SUBMITTED_SECOND_STEP,
    ERROR_SUBMITTED_SECOND_STEP,
    RESET_SECOND_STEP,
    SUBMIT_THIRD_STEP,
    SUBMITTED_THIRD_STEP,
    RESET_THIRD_STEP,
    ERROR_SUBMITTED_THIRD_STEP,
    SUBMIT_FOURTH_STEP,
    SUBMITTED_FOURTH_STEP,
    RESET_FOURTH_STEP,
    ERROR_SUBMITTED_FOURTH_STEP,
    SUBMIT_FIFTH_STEP,
    SUBMITTED_FIFTH_STEP,
    RESET_FIFTH_STEP,
    ERROR_SUBMITTED_FIFTH_STEP,
    GO_TO_STEP,
    SAVE_OLD_PHONE,
    /* first step */
    CHANGE_FIRSTNAME,
    CHANGE_LASTNAME,
    CHANGE_POSITION,
    CHANGE_BRAND,
    CHANGE_ACTIVITY_TYPE,
    CHANGE_PHONE,
    /* first step */
    /* PROSPECTION */
    NAME_CHANGED,
    IS_FIELD_CHANGED,
    REST_SURFACE_ACCESS_CHANGED,
    GROUNDFLOOR_SURFACE_CHANGED,
    REST_SURFACE_CHANGED,
    SURFACE_ATTRIBUTES_CHANGED,
    FACADE_LENGTH_CHANGED,
    HEIGHT_LENGTH_CHANGED,
    COS_CHANGED,
    MIN_BUILDING_HEIGHT_CHANGED,
    LEASE_CHANGED,
    LEASE_ATTRIBUTES_CHANGED,
    PRICE_CHANGED,
    PRICE_ATTRIBUTES_CHANGED,
    CENTER_CHANGED,
    NUMBER_ONE_CHANGED,
    NUMBER_ONE_BIS_CHANGED,
    AXE_PASSANT_CHANGED,
    MALL_CHANGED,
    GALERIE_CHANGED,
    TRANSPORTATION_CHANGED,
    SUBURB_CHANGED,
    COMMERCIAL_AREA_CHANGED,
    INDUSTRIAL_AREA_CHANGED,
    BUY_CHANGED,
    REQUIRED_LICENSE_4_CHANGED,
    ALLOWED_CATERING_INDUSTRY_CHANGED,
    DIAMETER_CHANGED,
    ALLOWED_CATERING_INDUSTRY_WITH_EXTRACTION_CHANGED,
    PMR_CHANGED,
    PARKING_CHANGED,
    CLOSE_PARKING_CHANGED,
    COMMENT_CHANGED,
    LOCATIONS_COMMENT_CHANGED,
    ACTIVITY_TYPE_CHANGED,
    DISPLAY_NUMBER_CHANGED,
    FUNDING_CHANGED,
    CONCEPT_CHANGED,
    COMMERCIAL_INDUSTRIAL_DESTINATION_CHANGED,
    HABITATION_DESTINATION_CHANGED,
    BUY_ONLY_CHANGED,
    /* PROSPECTION */
    QUIT_TERMINATED_FORM,
    SUBSCRIBE_BRAND_TOGGLE_PRIVACY_POLICY,
    ALLOW_NON_BUILDING_LAND_CHANGED
} from "./types"

export const changeStatus = status => {
    return { type: CHANGE_STATUS, payload: status }
}

export const changeType = type => {
    return { type: CHANGE_TYPE, payload: type }
}

export const changeMin = min => {
    return { type: CHANGE_MIN, payload: min }
}

export const changeMax = max => {
    return { type: CHANGE_MAX, payload: max }
}

export const addLocation = location => {
    return { type: ADD_LOCATION, payload: location }
}

export const removeLocation = locationId => {
    return { type: REMOVE_LOCATION, payload: locationId }
}

export const setFilteredLocations = filteredLocations => {
    return { type: SET_FILTERED_LOCATIONS, payload: filteredLocations }
}

export const computeTarget = (locations, targetCache = {}) => {
    return dispatch => {
        const hash = MD5(
            _.reduce(locations, (result, value, key) => result + value.id, "")
        )

        if (targetCache[hash]) {
            dispatch({
                type: COMPUTED_TARGET,
                payload: targetCache[hash]
            })
            return
        }

        dispatch({ type: COMPUTE_TARGET, payload: locations })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        instance
            .post("/compute_target_stats", {
                locations
            })
            .then(res => {
                dispatch({
                    type: COMPUTED_TARGET,
                    payload: res.data,
                    locations
                })
            })
    }
}

export const changeEmail = email => {
    return { type: CHANGE_EMAIL, payload: email }
}

export const changeEmailConfirmation = emailConfirmation => {
    return { type: CHANGE_EMAIL_CONFIRMATION, payload: emailConfirmation }
}

export const submitFirstStep = (datas, callback) => {
    return dispatch => {
        dispatch({
            type: SUBMIT_FIRST_STEP
        })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        const cookies = new Cookies()
        instance
            .post("/subscribe_first_step", { ...datas, partner: cookies.get("uec-partner") })
            .then(res => {
                dispatch({ type: SUBMITTED_FIRST_STEP, payload: res.data })

                if (typeof callback === "function") {
                    callback(res.data)
                }
            })
            .catch(error => {
                dispatch({ type: ERROR_SUBMITTED_FIRST_STEP, payload: error })
            })
    }
}

export const resetFirstStep = () => {
    return {
        type: RESET_FIRST_STEP
    }
}

export const submitSecondStep = (datas, callback) => {
    return dispatch => {
        dispatch({
            type: SUBMIT_SECOND_STEP
        })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        instance
            .post("/subscribe_second_step", datas)
            .then(res => {
                dispatch({ type: SUBMITTED_SECOND_STEP, payload: res.data })

                if (typeof callback === "function") {
                    callback(res.data)
                }
            })
            .catch(error => {
                dispatch({ type: ERROR_SUBMITTED_SECOND_STEP, payload: error })
            })
    }
}

export const resetSecondStep = () => {
    return {
        type: RESET_SECOND_STEP
    }
}

export const submitThirdStep = (datas, callback) => {
    return dispatch => {
        dispatch({
            type: SUBMIT_THIRD_STEP
        })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        instance
            .post("/subscribe_third_step", datas)
            .then(res => {
                dispatch({ type: SUBMITTED_THIRD_STEP, payload: res.data })

                if (typeof callback === "function") {
                    callback(res.data)
                }
            })
            .catch(error => {
                dispatch({ type: ERROR_SUBMITTED_THIRD_STEP, payload: error })
            })
    }
}

export const resetThirdStep = () => {
    return {
        type: RESET_THIRD_STEP
    }
}

export const submitFourthStep = (datas, callback) => {
    return dispatch => {
        dispatch({
            type: SUBMIT_FOURTH_STEP
        })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        instance
            .post("/subscribe_fourth_step", datas)
            .then(res => {
                dispatch({ type: SUBMITTED_FOURTH_STEP, payload: res.data })

                if (typeof callback === "function") {
                    callback(res.data)
                }
            })
            .catch(error => {
                dispatch({ type: ERROR_SUBMITTED_FOURTH_STEP, payload: error })
            })
    }
}

export const resetFourthStep = () => {
    return {
        type: RESET_FOURTH_STEP
    }
}

export const submitFifthStep = (datas, callback) => {
    return dispatch => {
        dispatch({
            type: SUBMIT_FIFTH_STEP
        })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        instance
            .post("/subscribe_fifth_step", datas)
            .then(res => {
                dispatch({ type: SUBMITTED_FIFTH_STEP, payload: res.data })

                if (typeof callback === "function") {
                    callback(res.data)
                }
            })
            .catch(error => {
                dispatch({ type: ERROR_SUBMITTED_FIFTH_STEP, payload: error })
            })
    }
}

export const resetFifthStep = () => {
    return {
        type: RESET_FIFTH_STEP
    }
}

export const goToStep = step => {
    return { type: GO_TO_STEP, payload: step }
}

export const saveOldPhone = oldPhone => {
    return { type: SAVE_OLD_PHONE, payload: oldPhone }
}

export const changeFirstname = firstname => {
    return { type: CHANGE_FIRSTNAME, payload: firstname }
}

export const changeLastname = lastname => {
    return { type: CHANGE_LASTNAME, payload: lastname }
}

export const changePosition = position => {
    return { type: CHANGE_POSITION, payload: position }
}

export const changeBrand = brand => {
    return { type: CHANGE_BRAND, payload: brand }
}

export const changeActivityType = activityType => {
    return { type: CHANGE_ACTIVITY_TYPE, payload: activityType }
}

export const changePhone = phone => {
    return { type: CHANGE_PHONE, payload: phone }
}

export const nameChanged = value => {
    return {
        type: NAME_CHANGED,
        payload: value
    }
}

export const isFieldChanged = value => {
    return {
        type: IS_FIELD_CHANGED,
        payload: value
    }
}

export const restSurfaceAccessChanged = value => {
    return {
        type: REST_SURFACE_ACCESS_CHANGED,
        payload: value
    }
}

export const groundfloorSurfaceChanged = value => {
    return {
        type: GROUNDFLOOR_SURFACE_CHANGED,
        payload: value
    }
}

export const restSurfaceChanged = value => {
    return {
        type: REST_SURFACE_CHANGED,
        payload: value
    }
}

export const surfaceAttributesChanged = value => {
    return {
        type: SURFACE_ATTRIBUTES_CHANGED,
        payload: value
    }
}

export const facadeLengthChanged = value => {
    return {
        type: FACADE_LENGTH_CHANGED,
        payload: value
    }
}

export const heightLengthChanged = value => {
    return {
        type: HEIGHT_LENGTH_CHANGED,
        payload: value
    }
}

export const cosChanged = value => {
    return {
        type: COS_CHANGED,
        payload: value
    }
}

export const minBuildingHeightChanged = value => {
    return {
        type: MIN_BUILDING_HEIGHT_CHANGED,
        payload: value
    }
}

export const leaseChanged = value => {
    return {
        type: LEASE_CHANGED,
        payload: value
    }
}

export const leaseAttributesChanged = value => {
    return {
        type: LEASE_ATTRIBUTES_CHANGED,
        payload: value
    }
}

export const priceChanged = value => {
    return {
        type: PRICE_CHANGED,
        payload: value
    }
}

export const priceAttributesChanged = value => {
    return {
        type: PRICE_ATTRIBUTES_CHANGED,
        payload: value
    }
}

export const centerChanged = value => {
    return {
        type: CENTER_CHANGED,
        payload: value
    }
}

export const numberOneChanged = value => {
    return {
        type: NUMBER_ONE_CHANGED,
        payload: value
    }
}

export const numberOneBisChanged = value => {
    return {
        type: NUMBER_ONE_BIS_CHANGED,
        payload: value
    }
}

export const axePassantChanged = value => {
    return {
        type: AXE_PASSANT_CHANGED,
        payload: value
    }
}

export const mallChanged = value => {
    return {
        type: MALL_CHANGED,
        payload: value
    }
}

export const galerieChanged = value => {
    return {
        type: GALERIE_CHANGED,
        payload: value
    }
}

export const transportationChanged = value => {
    return {
        type: TRANSPORTATION_CHANGED,
        payload: value
    }
}

export const suburbChanged = value => {
    return {
        type: SUBURB_CHANGED,
        payload: value
    }
}

export const commercialAreaChanged = value => {
    return {
        type: COMMERCIAL_AREA_CHANGED,
        payload: value
    }
}

export const industrialAreaChanged = value => {
    return {
        type: INDUSTRIAL_AREA_CHANGED,
        payload: value
    }
}

export const buyChanged = value => {
    return {
        type: BUY_CHANGED,
        payload: value
    }
}

export const requiredLicense4Changed = value => {
    return {
        type: REQUIRED_LICENSE_4_CHANGED,
        payload: value
    }
}

export const allowedCateringIndustryChanged = value => {
    return {
        type: ALLOWED_CATERING_INDUSTRY_CHANGED,
        payload: value
    }
}

export const diameterChanged = value => {
    return {
        type: DIAMETER_CHANGED,
        payload: value
    }
}

export const allowedCateringIndustryWithExtractionChanged = value => {
    return {
        type: ALLOWED_CATERING_INDUSTRY_WITH_EXTRACTION_CHANGED,
        payload: value
    }
}

export const pmrChanged = value => {
    return {
        type: PMR_CHANGED,
        payload: value
    }
}

export const parkingChanged = value => {
    return {
        type: PARKING_CHANGED,
        payload: value
    }
}

export const closeParkingChanged = value => {
    return {
        type: CLOSE_PARKING_CHANGED,
        payload: value
    }
}

export const commentChanged = value => {
    return {
        type: COMMENT_CHANGED,
        payload: value
    }
}

export const locationsCommentChanged = value => {
    return {
        type: LOCATIONS_COMMENT_CHANGED,
        payload: value
    }
}

export const activityTypeChanged = value => {
    return {
        type: ACTIVITY_TYPE_CHANGED,
        payload: value
    }
}

export const displayNumberChanged = value => {
    return {
        type: DISPLAY_NUMBER_CHANGED,
        payload: value
    }
}

export const fundingChanged = value => {
    return {
        type: FUNDING_CHANGED,
        payload: value
    }
}

export const conceptChanged = value => {
    return {
        type: CONCEPT_CHANGED,
        payload: value
    }
}

export const commercialIndustrialDestinationChanged = value => {
    return {
        type: COMMERCIAL_INDUSTRIAL_DESTINATION_CHANGED,
        payload: value
    }
}

export const habitationDestinationChanged = value => {
    return {
        type: HABITATION_DESTINATION_CHANGED,
        payload: value
    }
}

export const buyOnlyChanged = value => {
    return {
        type: BUY_ONLY_CHANGED,
        payload: value
    }
}

export const allowNonBuildingLandChanged = value => {
    return {
        type: ALLOW_NON_BUILDING_LAND_CHANGED,
        payload: value
    }
}

export const quitTerminatedForm = () => {
    return {
        type: QUIT_TERMINATED_FORM
    }
}

export const subscribeBrandTogglePrivacyPolicy = privacyPolicy => {
    return {
        type: SUBSCRIBE_BRAND_TOGGLE_PRIVACY_POLICY,
        payload: privacyPolicy
    }
}
