import {
    BODACC_PRICES_FETCH,
    BODACC_PRICES_FETCHED,
    BODACC_PRICES_ERROR
} from "./types"

import {
    modelBodaccPricesFetch,
    modelDepartmentsBodaccPricesFetch,
    modelStatesBodaccPricesFetch,
    modelFranceBodaccPricesFetch
} from "../models/Bodacc"

export const bodaccPricesFetch = (
    insee,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    return async dispatch => {
        dispatch({
            type: BODACC_PRICES_FETCH
        })

        try {
            const res = await modelBodaccPricesFetch(insee)

            dispatch({
                type: BODACC_PRICES_FETCHED,
                payload: res
            })
        } catch (e) {
            dispatch({
                type: BODACC_PRICES_ERROR,
                payload: e
            })
        }
    }
}

export const asyncBodaccPricesFetch = async (
    insee,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        const res = await modelBodaccPricesFetch(
            insee,
            runtimeConfig,
            serverInstance,
            refreshCache
        )

        return {
            type: BODACC_PRICES_FETCHED,
            payload: res
        }
    } catch (e) {
        return {
            type: BODACC_PRICES_ERROR,
            payload: e
        }
    }
}

export const asyncDepartmentsBodaccPricesFetch = async (
    deptCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        const res = await modelDepartmentsBodaccPricesFetch(
            deptCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )

        return {
            type: BODACC_PRICES_FETCHED,
            payload: res
        }
    } catch (e) {
        return {
            type: BODACC_PRICES_ERROR,
            payload: e
        }
    }
}

export const asyncStatesBodaccPricesFetch = async (
    stateCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        const res = await modelStatesBodaccPricesFetch(
            stateCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )

        return {
            type: BODACC_PRICES_FETCHED,
            payload: res
        }
    } catch (e) {
        return {
            type: BODACC_PRICES_ERROR,
            payload: e
        }
    }
}

export const asyncFranceBodaccPricesFetch = async (
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        const res = await modelFranceBodaccPricesFetch(
            runtimeConfig,
            serverInstance,
            refreshCache
        )

        return {
            type: BODACC_PRICES_FETCHED,
            payload: res
        }
    } catch (e) {
        return {
            type: BODACC_PRICES_ERROR,
            payload: e
        }
    }
}