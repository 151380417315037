import {
    INDUSTRIAL_AREAS_FETCH,
    INDUSTRIAL_AREAS_FETCHED,
    INDUSTRIAL_AREAS_ERROR
} from "./types"

import {
    modelIndustrialAreasFetch
} from "../models/IndustrialAreas"

export const industrialAreasFetch = (
    type,
    state,
    location = null,
    runtimeConfig = null,
    serverInstance = null
) => {
    return async dispatch => {
        dispatch({
            type: INDUSTRIAL_AREAS_FETCH
        })

        try {
            const res = await modelIndustrialAreasFetch(
                type,
                state,
                location,
                runtimeConfig,
                serverInstance
            )

            dispatch({
                type: INDUSTRIAL_AREAS_FETCHED,
                payload: res
            })
        } catch (e) {
            dispatch({
                type: INDUSTRIAL_AREAS_ERROR,
                payload: e
            })
        }
    }
}

export const asyncIndustrialAreasFetch = async (
    type,
    state = null,
    location = null,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const res = await modelIndustrialAreasFetch(
            type,
            state,
            location,
            runtimeConfig,
            serverInstance
        )

        return {
            type: INDUSTRIAL_AREAS_FETCHED,
            payload: res
        }
    } catch (e) {
        return {
            type: INDUSTRIAL_AREAS_ERROR,
            payload: e
        }
    }
}