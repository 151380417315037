import axios from "../config/axios"
import getConfig from "next/config"

import {
    SUBSCRIBE_PRO_SUBMIT,
    SUBSCRIBE_PRO_SUBMITTED,
    SUBSCRIBE_PRO_ERROR_SUBMITTED,
    SUBSCRIBE_PRO_RESET,
    SUBSCRIBE_PRO_GO_TO_STEP,
    /* first step */
    SUBSCRIBE_PRO_CHANGE_FIRSTNAME,
    SUBSCRIBE_PRO_CHANGE_LASTNAME,
    SUBSCRIBE_PRO_CHANGE_POSITION,
    SUBSCRIBE_PRO_CHANGE_CUSTOM_POSITION,
    SUBSCRIBE_PRO_CHANGE_NETWORK,
    SUBSCRIBE_PRO_CHANGE_CUSTOM_NETWORK,
    SUBSCRIBE_PRO_CHANGE_ACTIVITY,
    /* first step */
    SUBSCRIBE_PRO_QUIT_TERMINATED_FORM,
    SUBSCRIBE_PRO_ADD_LOCATION,
    SUBSCRIBE_PRO_REMOVE_LOCATION,
    SUBSCRIBE_PRO_CHANGE_ADDRESS,
    SUBSCRIBE_PRO_ADD_LOCATION2,
    SUBSCRIBE_PRO_REMOVE_LOCATION2,
    SUBSCRIBE_PRO_CHANGE_EMAIL,
    SUBSCRIBE_PRO_CHANGE_EMAIL_CONFIRMATION,
    SUBSCRIBE_PRO_CHANGE_PHONE,
    SUBSCRIBE_PRO_SET_MATCH_ALL_LOCATIONS,
    SUBSCRIBE_PRO_TOGGLE_PRIVACY_POLICY
} from "./types"

export const subscribeProAddLocation = location => {
    return { type: SUBSCRIBE_PRO_ADD_LOCATION, payload: location }
}

export const subscribeProAddLocation2 = location => {
    return { type: SUBSCRIBE_PRO_ADD_LOCATION2, payload: location }
}

export const subscribeProRemoveLocation2 = () => {
    return { type: SUBSCRIBE_PRO_REMOVE_LOCATION2 }
}

export const subscribeProRemoveLocation = locationId => {
    return { type: SUBSCRIBE_PRO_REMOVE_LOCATION, payload: locationId }
}

export const subscribeProSubmitFirstStep = () => {
    return {
        type: SUBSCRIBE_PRO_SUBMIT
    }
}

export const subscribeProReset = () => {
    return {
        type: SUBSCRIBE_PRO_RESET
    }
}

export const subscribeProGoToStep = step => {
    return { type: SUBSCRIBE_PRO_GO_TO_STEP, payload: step }
}

export const subscribeProChangeFirstname = firstname => {
    return { type: SUBSCRIBE_PRO_CHANGE_FIRSTNAME, payload: firstname }
}

export const subscribeProChangeLastname = lastname => {
    return { type: SUBSCRIBE_PRO_CHANGE_LASTNAME, payload: lastname }
}

export const subscribeProChangePosition = position => {
    return { type: SUBSCRIBE_PRO_CHANGE_POSITION, payload: position }
}

export const subscribeProChangeCustomPosition = customPosition => {
    return {
        type: SUBSCRIBE_PRO_CHANGE_CUSTOM_POSITION,
        payload: customPosition
    }
}

export const subscribeProChangeNetwork = network => {
    return { type: SUBSCRIBE_PRO_CHANGE_NETWORK, payload: network }
}

export const subscribeProChangeCustomNetwork = customNetwork => {
    return { type: SUBSCRIBE_PRO_CHANGE_CUSTOM_NETWORK, payload: customNetwork }
}

export const subscribeProChangeActivity = activity => {
    return { type: SUBSCRIBE_PRO_CHANGE_ACTIVITY, payload: activity }
}

export const subscribeProChangeAddress = address => {
    return { type: SUBSCRIBE_PRO_CHANGE_ADDRESS, payload: address }
}

export const subscribeProChangeEmail = email => {
    return { type: SUBSCRIBE_PRO_CHANGE_EMAIL, payload: email }
}

export const subscribeProChangeEmailConfirmation = emailConfirmation => {
    return {
        type: SUBSCRIBE_PRO_CHANGE_EMAIL_CONFIRMATION,
        payload: emailConfirmation
    }
}

export const subscribeProChangePhone = phone => {
    return { type: SUBSCRIBE_PRO_CHANGE_PHONE, payload: phone }
}

export const subscribeProQuitTerminatedForm = () => {
    return {
        type: SUBSCRIBE_PRO_QUIT_TERMINATED_FORM
    }
}

export const subscribeProSubmit = (
    firstname,
    lastname,
    activity,
    position,
    customPosition,
    network,
    customNetwork,
    location,
    locationsArea,
    address,
    email,
    phone,
    matchAllLocations
) => {
    return async dispatch => {
        dispatch({ type: SUBSCRIBE_PRO_SUBMIT })
        axios
            .post(
                getConfig().publicRuntimeConfig.externalAPIEndpoint +
                    "/api/subscribe_pro",
                {
                    lastname,
                    firstname,
                    activity,
                    position,
                    custom_position: customPosition,
                    network,
                    custom_network: customNetwork,
                    location,
                    locations_area: locationsArea,
                    address,
                    email,
                    phone,
                    siret_number: "",
                    matchAllLocations
                }
            )
            .then(() => {
                dispatch({ type: SUBSCRIBE_PRO_SUBMITTED })
            })
            .catch(error => {
                dispatch({
                    type: SUBSCRIBE_PRO_ERROR_SUBMITTED,
                    payload: error.response.data.error
                })
            })
    }
}

export const subscribeProSetMatchAllLocations = matchAllLocations => {
    return {
        type: SUBSCRIBE_PRO_SET_MATCH_ALL_LOCATIONS,
        payload: matchAllLocations
    }
}

export const subscribeProTogglePrivacyPolicy = privacyPolicy => {
    return {
        type: SUBSCRIBE_PRO_TOGGLE_PRIVACY_POLICY,
        payload: privacyPolicy
    }
}
