import React from "react"
import _ from "lodash"
import Prismic from "prismic-javascript"
import Link from "next/link"
import { Elements, RichText } from "prismic-reactjs"
import { Element } from "react-scroll"
import ReactDOMServer from "react-dom/server"

import removeDiacritics from "./Diacritics"

// Helper function to convert Prismic Rich Text links to Next/Link components
export const customLink = (type, element, content, children, index) => (
    <Link
        key={index}
        href={hrefResolver(element.data)}
        as={linkResolver(element.data)}
    >
        <a>{content}</a>
    </Link>
)

// Client method to query documents from the Prismic repo
export const Client = (apiEndpoint, accessToken, req = null) => (
    Prismic.client(apiEndpoint, createClientOptions(req, accessToken))
)

const createClientOptions = (req = null, prismicAccessToken = null) => {
    const reqOption = req ? { req } : {}
    const accessTokenOption = prismicAccessToken ? { accessToken: prismicAccessToken } : {}
    return {
        ...reqOption,
        ...accessTokenOption,
    }
}

// -- Link resolution rules
// Manages the url links to internal Prismic documents
const linkResolver = (entities, root = true) => {
    return entity => {
        try {
            let path = ''
            if (root) {
                let newEntity = entities.filter(e => e.id === entity.id)
                if (newEntity.length === 0) {
                    throw new Error("failed")
                }
                entity = newEntity[0]
            }
            // console.log('entities: ', entities)
            if (['category', 'subcategory', 'article'].indexOf(entity.type) !== -1) {
                path = `/${entity.uid}`
                if (entity.parent_id) {
                    const parentEntity = entities.filter(e => e.id === entity.parent_id)
                    if (parentEntity.length > 0) {
                        path = `${linkResolver(entities, false)(parentEntity[0])}${path}`
                    }
                    if (parentEntity.length === 0) {
                        throw new Error("failed")
                    }
                }
            }
            return ( root ? '/guide' : '' ) + path
        } catch (e) {
            return null
        }
    }
}

// Additional helper function for Next/Link components
const hrefResolver = (doc) => {
    if (doc.type === 'post') {
        return '/blog/[uid]'
    }
    return '/'
}

const getId = str => {
    return removeDiacritics(_.toLower(str))
    .replace(" - ", "-")
    .replace(/'/g, "-")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(", ", "-")
    .replace(/[^a-zA-Z0-9\- ]/g, "")
    .trim()
    .replace(/ /g, "-")
}

const htmlImageSerializer = (type, element, content, children, key) => {
    switch (type) {
        case Elements.image: 
            let alt = ''
            let title = ''
            let maxWidth = null
            if (element.alt) {
                const infos = element.alt.split('||', 3)
                alt = infos[0]
                title = [2, 3].indexOf(infos.length) !== -1 ? infos[1].trim() : ''
                maxWidth = [3].indexOf(infos.length) !== -1 ? parseInt(infos[2].trim()) : null
            }
            return React.createElement('img', {
                key,
                src: element.url,
                alt: alt || '',
                title: title !== '' ? title : undefined,
                style: maxWidth ? { maxWidth: `${maxWidth}px`, width: '100%' } : { width: '100%', margin: 0 }
            })
        default:
            return null
    }
}

const htmlTitleSerializer = (type, element, content, children, key) => {
    if (!children[0]) {
        return null
    }
    switch (type) {
        case Elements.heading1: // Heading 1
            return React.createElement('h1', { key }, children)
            // return `<h1>${children.join('')}</h1>`
        case Elements.heading2: // Heading 2
            return React.createElement('h2', { key }, children)
            // return `<h2>${children.join('')}</h2>`
        case Elements.heading3: // Heading 3
            return React.createElement('h3', { key }, children)
            // return `<h3>${children.join('')}</h3>`
        case Elements.heading4: // Heading 4
            return React.createElement('h4', { key }, children)
            // return `<h4>${children.join('')}</h4>`
        case Elements.heading5: // Heading 5
            return React.createElement('h5', { key }, children)
            // return `<h5>${children.join('')}</h5>`
        case Elements.heading6: // Heading 6
            return React.createElement('h6', { key }, children)
            // return `<h6>${children.join('')}</h6>`
        default:
            return null
    }
}

const renderTable = slice => {
    return (
        <table
            cellSpacing="0"
            cellPadding="0"
            summary=""
            style={{
                borderCollapse: 'collapse',
                border: `1px solid ${slice.primary.border_color}`,
                margin: 'auto'
            }}
        >
            {slice.items.map((row, i) => (
                <tr
                    key={`row-${i}`}
                >
                    {row.data.split('||').map((col, j) => (
                        <td
                            key={`col-${j}`}
                            dangerouslySetInnerHTML={{
                                __html: col
                            }}
                            style={{
                                padding: row.padding ? row.padding : 6,
                                fontWeight: row.font_weight ? row.font_weight : undefined,
                                border: `1px solid ${row.border_color ? row.border_color : slice.primary.border_color}`,
                                background: row.background ? row.background : undefined,
                                color: row.font_color ? row.font_color : undefined,
                                textAlign: row.text_align ? row.text_align : undefined,
                                //...(row.style ? row.style : {})
                            }}
                        />
                    ))}
                </tr>
            ))}
        </table>
    )
}

const renderIframe = slice => {
    const width = slice.primary.width || 600
    const height = slice.primary.height || 460
    const ratio = Math.round(((height / width * 100) + Number.EPSILON) * 100) / 100

    return (
        <div
            style={{
                maxWidth: width,
                margin: "auto",
                textAlign: "center"
            }}
        >
            <div
                style={{
                    position: "relative",
                    paddingBottom: `${ratio}%`,
                    height: 0
                }}
            >
                <iframe
                    src={slice.primary.url}
                    frameBorder={typeof slice.primary.frameborder === 'undefined' ? slice.primary.frameborder : 0}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                    }}
                    allowFullScreen={slice.primary.allowfullscreen ? true : false}
                />
            </div>
        </div>
    )
}

const renderNotice = (uidsPrismicEntities, slice) => {
    let color = "rgba(13, 179, 172, 0.1)"
    let color2 = "#0db3ac"

    if (slice.primary.notice_type === "warning") {
        color = "rgba(211, 47, 47, 0.1)"
        color2 = "#d32f2f"
    }

    return (
        <div
            className={slice.primary.notice_type === "note" ? "note-block" : "warning-block"}
            style={{
                background: color,
                padding: 5,
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                alignItems: "center"
            }}
        >
            {/* TO FIX: Using material-ui icons cause fabbutton bug display */}
            {slice.primary.notice_type === "note" &&
                <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24" 
                    role="presentation"
                    style={{
                        height: 25,
                        width: 25,
                        color: "#0db3ac",
                        marginRight: 10
                    }}
                >
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                </svg>
            }
            {slice.primary.notice_type === "warning" &&
                <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    role="presentation"
                    style={{
                        height: 25,
                        width: 25,
                        color: "#d32f2f",
                        marginRight: 10
                    }}
                >
                    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
                </svg>
            }
            <div
                style={{
                    fontFamily: "EB Garamond, Open Sans, Helvetica, Arial, sans-serif",
                    color: color2,
                    fontSize: 18,
                    lineHeight: 1.2,
                    textAlign: "justify",
                    marginRight: 10
                }}
            >
                <RichText
                    render={slice.primary.notice_text}
                    htmlSerializer={htmlTitleSerializer}
                    linkResolver={linkResolver(uidsPrismicEntities)}
                />
            </div>
        </div>
    )
}

const renderContent = (uidsPrismicEntities, content) => {
    const tables = content
        .map(slice => {
            if (slice.slice_type === "table" && slice.primary.id) {
                return { id: slice.primary.id, table: renderTable(slice) }
            }

            return null
        })
        .filter(e => e)
    const iframes = content
        .map(slice => {
            if (slice.slice_type === "iframe" && slice.primary.id) {
                return { id: slice.primary.id, iframe: renderIframe(slice) }
            }

            return null
        })
        .filter(e => e)
    const notices = content
        .map(slice => {
            if (slice.slice_type === "notice" && slice.primary.id) {
                return { id: slice.primary.id, notice: renderNotice(uidsPrismicEntities, slice) }
            }

            return null
        })
        .filter(e => e)

    return content
        .map((slice, index) => {
        // Render the right markup for the given slice type
        // Paragraph Slice
        if (slice.slice_type === "paragraph") {
            let newSlice = { ...slice }
            newSlice.items = newSlice.items.map(item => {
                let newItem = { ...item }
                for (let i = 0; i < newItem.custom_html.length; i++) {
                    if (
                        newItem.custom_html[i].type === 'paragraph' &&
                        typeof newItem.custom_html[i].text === 'string' &&
                        newItem.custom_html[i].text !== ''
                    ) {
                        const tableMarkup = newItem.custom_html[i].text.match(/\[table\:([a-zA-Z0-9_]+)\]/i)
                        if (tableMarkup && tableMarkup.length === 2) {
                            const table = tables.filter(t => t.id === tableMarkup[1])
                            if (table.length === 1) {
                                const html = ReactDOMServer.renderToString(table[0].table)
                                newItem = {
                                    ...newItem,
                                    custom_html: newItem.custom_html.map((el, pos) => {
                                        if (pos === i) {
                                            return {
                                                ...el,
                                                text: el.text.replace(`[table:${tableMarkup[1]}]`, html)
                                            }
                                        }
                                        return el
                                    })
                                }
                            }
                        }

                        const iframeMarkup = newItem.custom_html[i].text.match(/\[iframe\:([a-zA-Z0-9_]+)\]/i)
                        if (iframeMarkup && iframeMarkup.length === 2) {
                            const iframe = iframes.filter(t => t.id === iframeMarkup[1])
                            if (iframe.length === 1) {
                                const html = ReactDOMServer.renderToString(iframe[0].iframe)
                                newItem = {
                                    ...newItem,
                                    custom_html: newItem.custom_html.map((el, pos) => {
                                        if (pos === i) {
                                            return {
                                                ...el,
                                                text: el.text.replace(`[iframe:${iframeMarkup[1]}]`, html)
                                            }
                                        }
                                        return el
                                    })
                                }
                            }
                        }

                        const noticeMarkup = newItem.custom_html[i].text.match(/\[notice\:([a-zA-Z0-9_]+)\]/i)
                        if (noticeMarkup && noticeMarkup.length === 2) {
                            const notice = notices.filter(t => t.id === noticeMarkup[1])
                            if (notice.length === 1) {
                                const html = ReactDOMServer.renderToString(notice[0].notice)
                                newItem = {
                                    ...newItem,
                                    custom_html: newItem.custom_html.map((el, pos) => {
                                        if (pos === i) {
                                            return {
                                                ...el,
                                                text: el.text.replace(`[notice:${noticeMarkup[1]}]`, html)
                                            }
                                        }
                                        return el
                                    })
                                }
                            }
                        }
                    }
                }

                return newItem
            })
            const id = getId(RichText.asText(newSlice.primary.article_title))
            return (
                <Element
                    key={`${id}-element`}
                    name={id}
                    id={`${id}-element`}
                    style={{
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    {newSlice.primary.article_title && newSlice.primary.article_title.length > 0 && RichText.asText(newSlice.primary.article_title) !== "" &&
                        <RichText
                            render={newSlice.primary.article_title}
                            htmlSerializer={htmlTitleSerializer}
                            linkResolver={linkResolver(uidsPrismicEntities)}
                        />
                    }
                    {
                        newSlice.items.map((item, index) => {
                            const subId = getId(RichText.asText(item.article_subtitle))
                            return (
                                <Element
                                    key={`${subId}-element`}
                                    name={subId}
                                    id={`${subId}-element`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "justify"
                                    }}
                                >
                                    {item.article_subtitle && item.article_subtitle.length > 0 && RichText.asText(item.article_subtitle) !== "" &&
                                        <RichText
                                            key={`${slice.slice_type}_subtitle_${index}`}
                                            render={item.article_subtitle}
                                            htmlSerializer={htmlTitleSerializer}
                                            linkResolver={linkResolver(uidsPrismicEntities)}
                                        />
                                    }
                                    <span
                                        key={`${slice.slice_type}_paragraph_${index}`}
                                        className="article-text"
                                    >
                                        <RichText
                                            render={item.paragraph}
                                            htmlSerializer={htmlImageSerializer}
                                            linkResolver={linkResolver(uidsPrismicEntities)}
                                        />
                                    </span>
                                    {item.custom_html && 
                                        <span
                                            key={`${slice.slice_type}_custom_${index}`}
                                            className="article-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: RichText.asText(item.custom_html).trim()
                                            }}
                                        />
                                    }
                                </Element>
                            )
                        })
                    }
                </Element>
            )
        } else {
            return null
        }
    })
}

const extractEntities = (body, type) => {
    let entities = []
    for (let i = 0; i < body.length; i++) {
        for (let j = 0; j < body[i].items.length; j++) {
            if (body[i].items[j].paragraph && _.isArray(body[i].items[j].paragraph)) {
                for (let k = 0; k < body[i].items[j].paragraph.length; k++) {
                    if (body[i].items[j].paragraph[k].type === type) {
                        entities.push(body[i].items[j].paragraph[k])
                    }
                }
            }
        }
    }
    return entities
}

export {
    linkResolver,
    hrefResolver,
    getId,
    renderContent,
    htmlTitleSerializer,
    htmlImageSerializer,
    extractEntities
}

export default Client