export default [
   {
      "key":"[guide_1:Xp2MjREAACMAmAkI]",
      "value":"bail commercial",
      "nb":1,
      "id":"Xp2MjREAACMAmAkI"
   },
   {
      "key":"[guide_1:X6WTpBIAACEAHau1]",
      "value":"droit d'entrée ou pas-de-porte",
      "nb":1,
      "id":"X6WTpBIAACEAHau1"
   },
   {
      "key":"[guide_1:X6HrhxIAACEADWmQ]",
      "value":"dépôt de garantie",
      "nb":1,
      "id":"X6HrhxIAACEADWmQ"
   },
   {
      "key":"[guide_1:X6WjCxIAACEAHe9f]",
      "value":"cession de droit au bail",
      "nb":1,
      "id":"X6WjCxIAACEAHe9f"
   },
   {
      "key":"[guide_2:X6WjCxIAACEAHe9f]",
      "value":"cessions de droit au bail",
      "nb":2,
      "id":"X6WjCxIAACEAHe9f"
   },
   {
      "key":"[guide_1:X6C34RAAACEAJxf6]",
      "value":"clauses du bail commercial",
      "nb":1,
      "id":"X6C34RAAACEAJxf6"
   },
   {
      "key":"[guide_1:X4hXBhAAACAAu9aJ]",
      "value":"loyer du bail",
      "nb":1,
      "id":"X4hXBhAAACAAu9aJ"
   },
   {
      "key":"[guide_1:Xp2MjREAACMAmAkI]",
      "value":"bail commercial",
      "nb":1,
      "id":"Xp2MjREAACMAmAkI"
   },
   {
      "key":"[guide_1:YHBXtRMAACMAAvBv]",
      "value":"gestion locative",
      "nb":1,
      "id":"YHBXtRMAACMAAvBv"
   },
   {
      "key":"[guide_1:X6WkThIAACMAHfTk]",
      "value":"droit de préemption ou droit de préférence",
      "nb":1,
      "id":"X6WkThIAACMAHfTk"
   },
   {
      "key":"[guide_2:YKp6gxIAACMAI9xN]",
      "value":"investissement en immobilier d'entreprise",
      "nb":2,
      "id":"YKp6gxIAACMAI9xN"
   },
   {
      "key":"[guide_1:YD0sdREAACIAJFI0]",
      "value":"fonds de commerce est exploité",
      "nb":1,
      "id":"YD0sdREAACIAJFI0"
   },
   {
      "key":"[guide_1:X-DHQhMAACIA3h12]",
      "value":"bail professionnel",
      "nb":1,
      "id":"X-DHQhMAACIA3h12"
   },
   {
      "key":"[guide_1:YD0GXBEAAB8AI7k5]",
      "value":"cession de fonds de commerce",
      "nb":1,
      "id":"YD0GXBEAAB8AI7k5"
   },
   {
      "key":"[guide_1:YDz5BREAACAAI3tx]",
      "value":"fonds de commerce",
      "nb":1,
      "id":"YDz5BREAACAAI3tx"
   },
   {
      "key":"[guide:YDz5BREAACAAI3tx]",
      "value":"fonds de commerce",
      "nb":1,
      "id":"YDz5BREAACAAI3tx"
   },
   {
      "key":"[guide_1:X6WTpBIAACEAHau1]",
      "value":"droit d'entrée ou pas-de-porte",
      "nb":1,
      "id":"X6WTpBIAACEAHau1"
   },
   {
      "key":"[guide_1:YD0GXBEAAB8AI7k5]",
      "value":"cession de fonds de commerce",
      "nb":1,
      "id":"YD0GXBEAAB8AI7k5"
   },
   {
      "key":"[guide_1:X6WPQBIAACIAHZiU]",
      "value":"droit au bail",
      "nb":1,
      "id":"X6WPQBIAACIAHZiU"
   },
   {
      "key":"[guide_2:Xp2MjREAACMAmAkI]",
      "value":"bail commercial 3/6/9",
      "nb":2,
      "id":"Xp2MjREAACMAmAkI"
   },
   {
      "key":"[guide_1:YQGMBhEAACEA9xd2]",
      "value":"promesse unilatérale de vente (PUV)",
      "nb":1,
      "id":"YQGMBhEAACEA9xd2"
   },
   {
      "key":"[guide_1:Xp2MjREAACMAmAkI]",
      "value":"bail commercial",
      "nb":1,
      "id":"Xp2MjREAACMAmAkI"
   },
   {
      "key":"[guide_1:X-DHQhMAACIA3h12]",
      "value":"bail professionnel",
      "nb":1,
      "id":"X-DHQhMAACIA3h12"
   },
   {
      "key":"[guide_1:Xp2MjREAACMAmAkI]",
      "value":"bail commercial",
      "nb":1,
      "id":"Xp2MjREAACMAmAkI"
   },
   {
      "key":"[guide_1:X-DHQhMAACIA3h12]",
      "value":"bail professionnel",
      "nb":1,
      "id":"X-DHQhMAACIA3h12"
   },
   {
      "key":"[guide_1:YKp6gxIAACMAI9xN]",
      "value":"investir en immobilier d’entreprise",
      "nb":1,
      "id":"YKp6gxIAACMAI9xN"
   },
   {
      "key":"[guide_1:YKp8NBIAACMAI-PL]",
      "value":"société civile immobilière (SCI)",
      "nb":1,
      "id":"YKp8NBIAACMAI-PL"
   },
   {
      "key":"[guide_1:Xp2MjREAACMAmAkI]",
      "value":"bail commercial",
      "nb":1,
      "id":"Xp2MjREAACMAmAkI"
   },
   {
      "key":"[guide_1:X-DHQhMAACIA3h12]",
      "value":"bail professionnel",
      "nb":1,
      "id":"X-DHQhMAACIA3h12"
   },
   {
      "key":"[guide_1:Xp2MjREAACMAmAkI]",
      "value":"bail commercial",
      "nb":1,
      "id":"Xp2MjREAACMAmAkI"
   },
   {
      "key":"[guide_1:X-DHQhMAACIA3h12]",
      "value":"bail professionnel",
      "nb":1,
      "id":"X-DHQhMAACIA3h12"
   },
   {
      "key":"[guide_1:YHBXtRMAACMAAvBv]",
      "value":"gestion locative",
      "nb":1,
      "id":"YHBXtRMAACMAAvBv"
   },
   {
      "key":"[guide_1:X6C7JxAAAB8AJyaA]",
      "value":"indexation du loyer",
      "nb":1,
      "id":"X6C7JxAAAB8AJyaA"
   },
   {
      "key":"[guide_1:X6C_JxAAACEAJzgv]",
      "value":"indemnité d'éviction",
      "nb":1,
      "id":"X6C_JxAAACEAJzgv"
   },
   {
      "key":"[guide_2:YKp6gxIAACMAI9xN]",
      "value":"investissement en immobilier d'entreprise",
      "nb":2,
      "id":"YKp6gxIAACMAI9xN"
   },
   {
      "key":"[guide_2:X4hXBhAAACAAu9aJ]",
      "value":"loyer",
      "nb":2,
      "id":"X4hXBhAAACAAu9aJ"
   },
   {
      "key":"[guide_3:Xp2MjREAACMAmAkI]",
      "value":"baux commerciaux",
      "nb":3,
      "id":"Xp2MjREAACMAmAkI"
   }
]