export default [
    {
        key: 4,
        name: "Centre Ville",
        seo: "centre-ville",
        tag: "center",
        property: "center",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 6,
        name: "Emplacement n°1",
        seo: "emplacement-numero-1",
        tag: "numberOne",
        property: "number_one",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 7,
        name: "Emplacement n°1 bis",
        seo: "emplacement-numero-1-bis",
        tag: "numberOneBis",
        property: "number_one_bis",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 8,
        name: "Axe passant en voiture",
        seo: "axe-passant-en-voiture",
        tag: "axePassant",
        property: "axe_passant",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 11,
        name: "Périphérie",
        seo: "peripherie",
        tag: "suburb",
        property: "suburb",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 12,
        name: "ZAC / Retail Park",
        seo: "zac-retail-park",
        tag: "commercialArea",
        property: "commercial_area",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 13,
        name: "Zone Industrielle",
        seo: "zone-industrielle",
        tag: "industrialArea",
        property: "industrial_area",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 5,
        name: "Centre Commercial",
        seo: "centre-commercial",
        tag: "mall",
        property: "mall",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 9,
        name: "Galerie marchande",
        seo: "galerie-marchande",
        tag: "galerie",
        property: "galerie",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 10,
        name: "Gare",
        seo: "gare",
        tag: "transportation",
        property: "transportation",
        prospection_type: [ 1, 2, 3, 4, 9 ]
    },
    {
        key: 14,
        name: "Kiosque ou stand dans allée centrale",
        seo: "kiosque-ou-stand-dans-allee-centrale",
        tag: "kiosque",
        property: "kiosque",
        prospection_type: [ 3 ]
    }
]
