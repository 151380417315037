import {
    SET_CONFIG,
    SET_UA
} from './types'

export const setConfig = config => {
    return { type: SET_CONFIG, payload: config }
}

export const setUA = ua => {
    return { type: SET_UA, payload: ua }
}
