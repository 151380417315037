import _ from "lodash"
import axios from "../config/axios"

import {
    modelGetProfileSimpleWithIdentityList
} from "./Users"

export const modelAgencyFetch = async (
    state,
    locations,
    filters,
    page,
    hitsPerPage,
    runtimeConfig
) => {
    const location = locations && locations[0] ? locations[0] : null
    const type = filters && filters.type ? filters.type : null
    const transactionType = filters && filters.transaction_type ? filters.transaction_type : null
    const from = filters && filters.from ? filters.from : null

    const instance = axios.create({
        baseURL:
            `${runtimeConfig.externalAPIEndpoint}/api`,
        timeout: 30000
    })

    const result = await instance
        .post('/agencies/fetch', {
            state,
            location,
            type,
            transaction_type: transactionType,
            page,
            hits_per_page: hitsPerPage,
            from
        })

    return result.data
}

export const modelAgencyProfileGetByURLId = async (
    URLId,
    refreshCache = false,
    runtimeConfig = {},
    serverInstance = null
) => {
    const firebaseInst = serverInstance && serverInstance.firebaseSecond ? serverInstance.firebaseSecond : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsGet = redisTagsGetInst
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redisTags = redisTagsInst
    const fi = firebaseInst

    const redisKey = `modelAgencyProfileGetByURLId:${URLId}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }
  

    const querySnapshot = await fi
        .firestore()
        .collection('agencies')
        .where('deleted', '==', false)
        .where('url_id', '==', URLId)
        .limit(1)
        .get()

    let infos = null

    querySnapshot.forEach(doc => {
        infos = { id: doc.id, data: doc.data() }
    })

    let tags = []

    if (infos && infos.id) {
        tags.push(`agency_${infos.id}`)
    }

    redisTags.set(
        redisKey,
        infos,
        tags,
        {}
    )

    return infos
}

export const modelAgencyMemberFetchByURLId = async (
    URLId,
    refreshCache = false,
    runtimeConfig = {},
    serverInstance = null
) => {
    try {
        const agency = await modelAgencyProfileGetByURLId(
            URLId,
            refreshCache,
            runtimeConfig,
            serverInstance
        )

        if (!agency) {
            throw "agency_doesnt_exist"
        }

        let members = (await modelGetProfileSimpleWithIdentityList('realtime', agency.data.members, serverInstance, refreshCache))
            .filter(m => m.status !== 'fullfilled')
            .map(m => m.v)

        members = members.map(m => ({
            ...m,
            override:
                (agency.data.members_override || [])
                    .filter(o => o.uid === m.uid).length > 0 ?
                        _.omit((agency.data.members_override || [])
                            .filter(o => o.uid === m.uid)[0], ['uid']) : null,
            messages_access: agency.data.messages_access.indexOf(m.uid) !== -1,
            standalone: false
        }))

        if (
            agency.data.standalone_members &&
            _.isArray(agency.data.standalone_members) &&
            agency.data.standalone_members.length > 0
        ) {
            for (let i = 0; i < agency.data.standalone_members.length; i++) {
                members.push({ ...agency.data.standalone_members[i], standalone: true })
            }
        }

        return members
    } catch (error) {
        throw error
    }
}

export const modelAgencyStatsGetByURLId = async (
    URLId,
    refreshCache = false,
    runtimeConfig = {}
) => {
    const instance = axios.create({
        baseURL:
            `${runtimeConfig.externalAPIEndpoint}/api`,
        timeout: 30000
    })

    const result = await instance
        .get(
            '/agencies/stats',
            {
                params: {
                    url_id: URLId,
                    refresh_cache: refreshCache
                }
            }
        )

    return result.data
}

export const modelAgencyTransactionsFetchMapByURLId = async (
    URLId,
    refreshCache = false,
    runtimeConfig = {}
) => {
    const instance = axios.create({
        baseURL:
            `${runtimeConfig.externalAPIEndpoint}/api`,
        timeout: 30000
    })

    const result = await instance
        .get(
            '/transactions/fetch_map',
            {
                params: {
                    url_id: URLId,
                    refresh_cache: refreshCache
                }
            }
        )

    return result.data
}

export const modelAgencyTransactionsFetchByURLId = async (
    URLId,
    state = null,
    location = null,
    filters = {},
    previous = null,
    next = null,
    refreshCache = false,
    runtimeConfig = {},
    from = null
) => {
    const instance = axios.create({
        baseURL:
            `${runtimeConfig.externalAPIEndpoint}/api`,
        timeout: 30000
    })

    const result = await instance
        .post(
            '/transactions/fetch',
            {
                state,
                location,
                filters: { ...filters, url_id: URLId },
                previous,
                next,
                refresh_cache: refreshCache,
                from
            }
        )

    return result.data
}