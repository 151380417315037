import {
    SHOPPING_MALLS_FETCH,
    SHOPPING_MALLS_FETCHED,
    SHOPPING_MALLS_ERROR
} from "./types"

import {
    modelShoppingMallsFetch
} from "../models/ShoppingMalls"

export const shoppingMallsFetch = (state, location = null) => {
    return async dispatch => {
        dispatch({
            type: SHOPPING_MALLS_FETCH
        })

        try {
            const res = await modelShoppingMallsFetch(state, location)

            dispatch({
                type: SHOPPING_MALLS_FETCHED,
                payload: res
            })
        } catch (e) {
            dispatch({
                type: SHOPPING_MALLS_ERROR,
                payload: e
            })
        }
    }
}

export const asyncShoppingMallsFetch = async (
    state,
    location = null,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        const res = await modelShoppingMallsFetch(
            state,
            location,
            runtimeConfig,
            serverInstance,
            refreshCache
        )

        return {
            type: SHOPPING_MALLS_FETCHED,
            payload: res
        }
    } catch (e) {
        return {
            type: SHOPPING_MALLS_ERROR,
            payload: e
        }
    }
}