export default [
    {
        id: 1,
        label: "pizzéria",
        description_word_link: "de restaurant",
        title_linked_label: "pizzeria"
    },
    {
        id: 2,
        label: "restauration rapide",
        description_word_link: "de restaurant type snack /",
        title_linked_label: "restauration rapide"
    },
    {
        id: 3,
        label: "restaurant à thème, traditionnel",
        description_word_link: "de",
        title_linked_label: "restaurant traditionnel"
    },
    {
        id: 4,
        label: "bar à salade",
        description_word_link: "de",
        title_linked_label: "bar à salade"
    },
    {
        id: 5,
        label: "sandwicherie",
        description_word_link: "de",
        title_linked_label: "sandwicherie"
    },
    {
        id: 6,
        label: "librairie, loisirs créatifs",
        description_word_link: "de",
        title_linked_label: "librairie"
    },
    {
        id: 7,
        label: "bar, brasserie, débit de boisson",
        description_word_link: "de",
        title_linked_label: "bar / brasserie"
    },
    {
        id: 8,
        label: "coffee shop",
        description_word_link: "de",
        title_linked_label: "coffee shop"
    },
    {
        id: 9,
        label: "boucherie, rôtisserie",
        description_word_link: "de",
        title_linked_label: "boucherie"
    },
    {
        id: 10,
        label: "supermarché, supérette",
        description_word_link: "de",
        title_linked_label: "supermarché"
    },
    {
        id: 11,
        label: "produits surgelés",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de produits surgelés"
    },
    {
        id: 12,
        label: "epicerie fine",
        description_word_link: "d'",
        title_linked_label: "épicerie fine"
    },
    {
        id: 13,
        label: "boulangerie, pâtisserie",
        description_word_link: "de",
        title_linked_label: "boulangerie"
    },
    {
        id: 14,
        label: "chocolat, confiserie",
        description_word_link: "de boutique de",
        title_linked_label: "chocolatier"
    },
    {
        id: 15,
        label: "glaces",
        description_word_link: "de boutique de",
        title_linked_label: "glacier"
    },
    {
        id: 16,
        label: "vin et boisson",
        description_word_link: "de boutique de",
        title_linked_label: "caviste"
    },
    {
        id: 17,
        label: "produits bio et naturels",
        description_word_link: "de boutique de",
        title_linked_label: "magasin de produit bio"
    },
    {
        id: 18,
        label: "prêt à porter",
        description_word_link: "de boutique de",
        title_linked_label: "prêt à porter"
    },
    {
        id: 19,
        label: "chaussures",
        description_word_link: "de boutique de",
        title_linked_label: "boutique de chaussure"
    },
    {
        id: 20,
        label: "puériculture, mode enfantine",
        description_word_link: "de boutique de",
        title_linked_label: "boutique puericulture"
    },
    {
        id: 21,
        label: "bijouterie, horlogerie",
        description_word_link: "de",
        title_linked_label: "bijouterie"
    },
    {
        id: 22,
        label: "article de sport",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de sport"
    },
    {
        id: 23,
        label: "salon de coiffure",
        description_word_link: "de",
        title_linked_label: "salon de coiffure"
    },
    {
        id: 24,
        label: "parfumerie, cosmétique",
        description_word_link: "de boutique de",
        title_linked_label: "parfumerie"
    },
    {
        id: 25,
        label: "esthétique, soin du corps",
        description_word_link: "d'institut d'",
        title_linked_label: "institut esthétique"
    },
    {
        id: 26,
        label: "complément alimentaire, diététique",
        description_word_link: "de boutique de",
        title_linked_label: "boutique diététique"
    },
    {
        id: 27,
        label: "salle de sport",
        description_word_link: "de",
        title_linked_label: "salle de sport"
    },
    {
        id: 28,
        label: "optique, audition",
        description_word_link: "de magasin d'",
        title_linked_label: "magasin d'optique"
    },
    {
        id: 29,
        label: "solde, bazar, discount",
        description_word_link: "de magasin de",
        title_linked_label: "solderie"
    },
    {
        id: 30,
        label: "pharmacie, parapharmacie",
        description_word_link: "de",
        title_linked_label: "pharmacie"
    },
    {
        id: 31,
        label: "meubles, literie",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de meuble"
    },
    {
        id: 33,
        label: "décoration, art de la table, linge de maison",
        description_word_link: "de boutique de",
        title_linked_label: "magasin de décoration"
    },
    {
        id: 34,
        label: "bricolage, travaux",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de bricolage"
    },
    {
        id: 35,
        label: "jardinage",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de jardinage"
    },
    {
        id: 36,
        label: "cuisine, salle de bains",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de cuisine, salle de bain"
    },
    {
        id: 37,
        label: "entretien, réparation automobile",
        description_word_link: "de centre d'",
        title_linked_label: "garage"
    },
    {
        id: 38,
        label: "vélo, moto",
        description_word_link: "de magasin de",
        title_linked_label: "magasin vélo, moto"
    },
    {
        id: 39,
        label: "lavage automobile",
        description_word_link: "de centre de",
        title_linked_label: "centre lavage auto"
    },
    {
        id: 40,
        label: "achat, location automobile",
        description_word_link: "d'agence de",
        title_linked_label: "agence location voiture"
    },
    {
        id: 41,
        label: "cadeaux, jouets",
        description_word_link: "de magasin de",
        title_linked_label: "magasin jouet"
    },
    {
        id: 42,
        label: "informatique, fourniture de bureau",
        description_word_link: "de magasin d'",
        title_linked_label: "magasin d'informatique"
    },
    {
        id: 44,
        label: "electroménager",
        description_word_link: "de magasin d'",
        title_linked_label: "boutique d'électroménager"
    },
    {
        id: 45,
        label: "pressing, laverie",
        description_word_link: "de",
        title_linked_label: "pressing"
    },
    {
        id: 46,
        label: "fleuriste",
        description_word_link: "de boutique de",
        title_linked_label: "fleuriste"
    },
    {
        id: 47,
        label: "occasions, dépôt-vente",
        description_word_link: "de magasin d'",
        title_linked_label: "dépôt-vente"
    },
    {
        id: 48,
        label: "service funéraire",
        description_word_link: "d'agence de",
        title_linked_label: "pompe funebre"
    },
    {
        id: 49,
        label: "animalerie, chasse, pêche",
        description_word_link: "de magasin d'",
        title_linked_label: "animalerie"
    },
    {
        id: 51,
        label: "crèche, garde d'enfant",
        description_word_link: "de",
        title_linked_label: "crèche"
    },
    {
        id: 52,
        label: "produit financiers, assurance",
        description_word_link: "d'agence de",
        title_linked_label: "agence d'assurance/finance"
    },
    {
        id: 53,
        label: "service à la personne",
        description_word_link: "d'agence de",
        title_linked_label: "agence de service à la personne"
    },
    {
        id: 54,
        label: "travail temporaire, intérim",
        description_word_link: "d'agence de",
        title_linked_label: "agence d'interim"
    },
    {
        id: 55,
        label: "voyage, tourisme",
        description_word_link: "d'agence de",
        title_linked_label: "agence de voyage"
    },
    {
        id: 56,
        label: "stockage",
        description_word_link: "de centre de",
        title_linked_label: "centre de stokage"
    },
    {
        id: 57,
        label: "parc de loisirs indoor, outdoor",
        description_word_link: "de",
        title_linked_label: "centre de loisirs"
    },
    {
        id: 58,
        label: "cigarette électronique",
        description_word_link: "de boutique de",
        title_linked_label: "boutique de cigarette eletronique"
    },
    {
        id: 59,
        label: "crêperie",
        description_word_link: "de restaurant",
        title_linked_label: "restaurant crêperie"
    },
    {
        id: 60,
        label: "sushi",
        description_word_link: "de restaurant",
        title_linked_label: "restaurant sushi"
    },
    {
        id: 61,
        label: "loveshop",
        description_word_link: "de magasin de",
        title_linked_label: "sex shop"
    },
    {
        id: 62,
        label: "pisciniste",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de piscine"
    },
    {
        id: 63,
        label: "vente, réparation de téléphones",
        description_word_link: "de boutique de",
        title_linked_label: "boutique téléphonie"
    },
    {
        id: 64,
        label: "jeux-vidéo",
        description_word_link: "de magasin de",
        title_linked_label: "magasin de jeux vidéo"
    },
    {
        id: 65,
        label: "burger",
        description_word_link: "de restaurant",
        title_linked_label: "restaurant burger"
    },
    {
        id: 66,
        label: "multi-concept",
        description_word_link: "",
        title_linked_label: "multiconcept"
    }
]
