import {
    GUIDE_MARK_VALIDATE,
    GUIDE_MARK_VALIDATED,
    GUIDE_MARK_ERROR,
    GUIDE_MARK_FETCHED,
    GUIDE_MARK_CHANGED
} from "./types"

import {
    modelGuideMarkValidate,
    modelGuideMarkFetch
} from "../models/Guide"

export const guideMarkValidate = (id, mark) => {
    return async dispatch => {
        dispatch({
            type: GUIDE_MARK_VALIDATE
        })

        try {
            const res = await modelGuideMarkValidate(id, mark)

            dispatch({
                type: GUIDE_MARK_VALIDATED,
                payload: res
            })
        } catch (e) {
            dispatch({
                type: GUIDE_MARK_ERROR,
                payload: e
            })
        }
    }
}

export const asyncGuideMarkFetch = async (
    id,
    runtimeConfig = null,
    serverInstance = null
) => {
    const mark = await modelGuideMarkFetch(
        id,
        runtimeConfig,
        serverInstance
    )

    return { type: GUIDE_MARK_FETCHED, payload: mark }
}

export const guideMarkChanged = mark => {
    return {
        type: GUIDE_MARK_CHANGED,
        payload: mark
    }
}