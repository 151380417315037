const specialCaseGPS = {
    "75101": { lon: 2.334396, lat: 48.864614 },
    "75102": { lon: 2.341688, lat: 48.868743 },
    "75103": { lon: 2.360936, lat: 48.864212 },
    "75104": { lon: 2.355619, lat: 48.856202 },
    "75105": { lon: 2.34435, lat: 48.845973 },
    "75106": { lon: 2.332951, lat: 48.850433 },
    "75107": { lon: 2.320195, lat: 48.857028 },
    "75108": { lon: 2.31765, lat: 48.87748 },
    "75109": { lon: 2.339962, lat: 48.876019 },
    "75110": { lon: 2.359839, lat: 48.876126 },
    "75111": { lon: 2.379703, lat: 48.858416 },
    "75112": { lon: 2.395752, lat: 48.839615 },
    "75113": { lon: 2.355583, lat: 48.832397 },
    "75114": { lon: 2.326959, lat: 48.833059 },
    "75115": { lon: 2.300383, lat: 48.84137 },
    "75116": { lon: 2.275765, lat: 48.863171 },
    "75117": { lon: 2.322364, lat: 48.884224 },
    "75118": { lon: 2.346467, lat: 48.890012 },
    "75119": { lon: 2.38436, lat: 48.889343 },
    "75120": { lon: 2.398929, lat: 48.865042 },
    "13201": { lon: 5.382502, lat: 43.300067 },
    "13202": { lon: 5.359067, lat: 43.316216 },
    "13203": { lon: 5.378941, lat: 43.311385 },
    "13204": { lon: 5.40113, lat: 43.306983 },
    "13205": { lon: 5.397644, lat: 43.292212 },
    "13206": { lon: 5.380516, lat: 43.2868 },
    "13207": { lon: 5.362413, lat: 43.282997 },
    "13208": { lon: 5.378865, lat: 43.243032 },
    "13209": { lon: 5.449898, lat: 43.234073 },
    "13210": { lon: 5.425821, lat: 43.276838 },
    "13211": { lon: 5.49062, lat: 43.293256 },
    "13212": { lon: 5.439345, lat: 43.308457 },
    "13213": { lon: 5.432082, lat: 43.346731 },
    "13214": { lon: 5.390592, lat: 43.346361 },
    "13215": { lon: 5.364765, lat: 43.353156 },
    "13216": { lon: 5.316452, lat: 43.362571 },
    "69381": { lon: 4.83549, lat: 45.767699 },
    "69382": { lon: 4.827374, lat: 45.751112 },
    "69383": { lon: 4.849389, lat: 45.759934 },
    "69384": { lon: 4.825031, lat: 45.778046 },
    "69385": { lon: 4.828436, lat: 45.763571 },
    "69386": { lon: 4.849432, lat: 45.768309 },
    "69387": { lon: 4.84189, lat: 45.745755 },
    "69388": { lon: 4.87072, lat: 45.735123 },
    "69389": { lon: 4.806249, lat: 45.774 }
}

export default specialCaseGPS