import _ from "lodash"
import getConfig from "next/config"

import axios from "../config/axios"

import departments from "../datas/departments"

const instance = axios.create({
    baseURL:
        getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
    timeout: 30000
})

const modelBodaccPricesFetchProcess = async (
    insee,
    runtimeConfig = null,
    serverInstance = null
) => {
    const dbBodaccInst = serverInstance && serverInstance.dbBodacc ? serverInstance.dbBodacc : null

    const cursorBodacc = dbBodaccInst
        .collection('bodaccPriceAggregates')
        .find(
            {
                insee
            }
        )
        .sort({ price_avg: -1 })

    let prices = []
    for await (const doc of cursorBodacc) {
        const price = Math.round(doc.price_avg)
        if (price <= 2000000 || [39].indexOf(doc.activity_type) !== -1) { /* Hotels */
            prices.push({
                price,
                count: doc.count,
                activity_type: doc.activity_type,
                start_date: doc.start_date
            })
        }
    }

    prices = _.reverse(_.sortBy(prices, ['count']))

    return prices
}

export const modelBodaccPricesFetch = async (
    insee,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    if (!insee) {
        throw 'missing_params'
    }

    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelBodaccPricesFetch:${insee}`

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/bodacc/prices`,
        { params: { insee_code: insee } }
    )
    const prices = res.data
    /* const prices = await modelBodaccPricesFetchProcess(
        insee,
        runtimeConfig,
        serverInstance
    ) */

    redis.set(redisKey, JSON.stringify(prices), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return prices
}

const modelDepartmentsBodaccPricesProcess = async (
    deptCode,
    runtimeConfig = null,
    serverInstance = null
) => {
    const dbBodaccInst = serverInstance && serverInstance.dbBodacc ? serverInstance.dbBodacc : null

    const cursorBodacc = dbBodaccInst
        .collection('bodaccPriceAggregates')
        .aggregate([
            {
                $match: {
                    insee: new RegExp(`^${deptCode}`, 'gi')
                }
            },
            {
                $group: {
                    _id: "$activity_type",
                    numerator: { $sum: { $multiply: ["$price_avg", "$count"] } },
                    denominator: { $sum: "$count" },
                    start_date: { $min: "$start_date" }
                }
            },
            {
                $project: {
                    activity_type: "$_id",
                    price_avg: { $divide: ["$numerator", "$denominator"] },
                    count: "$denominator",
                    start_date: "$start_date"
                }
            },
            {
                $sort: { price_avg: -1 }
            }
        ])

    let prices = []
    for await (const doc of cursorBodacc) {
        const price = Math.round(doc.price_avg)
        if (price <= 2000000 || [39].indexOf(doc.activity_type) !== -1) { /* Hotels */
            prices.push({
                price,
                count: doc.count,
                activity_type: doc.activity_type,
                start_date: doc.start_date
            })
        }
    }

    prices = _.reverse(_.sortBy(prices, ['count']))

    return prices
}

export const modelDepartmentsBodaccPricesFetch = async (
    deptCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    if (!deptCode) {
        throw 'missing_params'
    }

    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelDepartmentsBodaccPricesFetch:${deptCode}`

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/bodacc/department_prices`,
        { params: { dept_code: deptCode } }
    )
    const prices = res.data
    /* const prices = await modelDepartmentsBodaccPricesFetchProcess(
        deptCode,
        runtimeConfig,
        serverInstance
    ) */

    redis.set(redisKey, JSON.stringify(prices), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return prices
}

const modelStatesBodaccPricesFetchProcess = async (
    stateCode,
    runtimeConfig = null,
    serverInstance = null
) => {
    const dbBodaccInst = serverInstance && serverInstance.dbBodacc ? serverInstance.dbBodacc : null

    const cursorBodacc = dbBodaccInst
        .collection('bodaccPriceAggregates')
        .aggregate([
            {
                $match: {
                    insee: new RegExp(`^(${departments.filter(o => o.codeRegion === stateCode).map(o => o.code).join('|')})`, 'gi')
                }
            },
            {
                $group: {
                    _id: "$activity_type",
                    numerator: { $sum: { $multiply: ["$price_avg", "$count"] } },
                    denominator: { $sum: "$count" },
                    start_date: { $min: "$start_date" }
                }
            },
            {
                $project: {
                    activity_type: "$_id",
                    price_avg: { $divide: ["$numerator", "$denominator"] },
                    count: "$denominator",
                    start_date: "$start_date"
                }
            },
            {
                $sort: { price_avg: -1 }
            }
        ])

    let prices = []
    for await (const doc of cursorBodacc) {
        const price = Math.round(doc.price_avg)
        if (price <= 2000000 || [39].indexOf(doc.activity_type) !== -1) { /* Hotels */
            prices.push({
                price,
                count: doc.count,
                activity_type: doc.activity_type,
                start_date: doc.start_date
            })
        }
    }

    prices = _.reverse(_.sortBy(prices, ['count']))

    return prices
}

export const modelStatesBodaccPricesFetch = async (
    stateCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    if (!stateCode) {
        throw 'missing_params'
    }

    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelStatesBodaccPricesFetch:${stateCode}`

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/bodacc/state_prices`,
        { params: { state_code: stateCode } }
    )
    const prices = res.data
    /* const prices = await modelStatesBodaccPricesFetchProcess(
        stateCode,
        runtimeConfig,
        serverInstance
    ) */

    redis.set(redisKey, JSON.stringify(prices), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return prices
}

const modelFranceBodaccPricesFetchProcess = async (
    runtimeConfig = null,
    serverInstance = null
) => {
    const dbBodaccInst = serverInstance && serverInstance.dbBodacc ? serverInstance.dbBodacc : null

    const cursorBodacc = dbBodaccInst
        .collection('bodaccPriceAggregates')
        .aggregate([
            {
                $group: {
                    _id: "$activity_type",
                    numerator: { $sum: { $multiply: ["$price_avg", "$count"] } },
                    denominator: { $sum: "$count" },
                    start_date: { $min: "$start_date" }
                }
            },
            {
                $project: {
                    activity_type: "$_id",
                    price_avg: { $divide: ["$numerator", "$denominator"] },
                    count: "$denominator",
                    start_date: "$start_date"
                }
            },
            {
                $sort: { price_avg: -1 }
            }
        ])

    let prices = []
    for await (const doc of cursorBodacc) {
        const price = Math.round(doc.price_avg)
        if (price <= 2000000 || [39].indexOf(doc.activity_type) !== -1) { /* Hotels */
            prices.push({
                price,
                count: doc.count,
                activity_type: doc.activity_type,
                start_date: doc.start_date
            })
        }
    }

    prices = _.reverse(_.sortBy(prices, ['count']))

    return prices
}

export const modelFranceBodaccPricesFetch = async (
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = 'modelFranceBodaccPricesFetch'

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/bodacc/france_prices`,
        { params: {} }
    )
    const prices = res.data
    /* const prices = await modelFranceBodaccPricesFetchProcess(
        runtimeConfig,
        serverInstance
    ) */

    redis.set(redisKey, JSON.stringify(prices), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return prices
}

export const modelBodaccPricesAggregateFetch = async (
    insee = null,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const dbBodaccInst = serverInstance && serverInstance.dbBodacc ? serverInstance.dbBodacc : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelBodaccPricesAggregateFetch:${insee !== null ? insee : 'NULL'}`

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }
    
    const cursorBodacc = dbBodaccInst
        .collection('bodaccPriceAggregates')
        .aggregate([
            insee !== null ? {
                $match: {
                    insee
                }
            } : null,
            {
                $group: {
                    _id: insee !== null ? "$insee" : null,
                    total:  { $sum: "$count" },
                    start_date: { $min: "$start_date" }
                }
            },
            {
                $sort: { price_avg: -1 }
            }
        ].filter(o => o))

    let results = []
    for await (const doc of cursorBodacc) {
        results.push(doc)
    }

    redis.set(redisKey, JSON.stringify(results.length > 0 ? results[0] : null), 'EX', 60 * 60 * 24).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return results.length > 0 ? results[0] : null
}

export const modelDepartmentsBodaccPricesAggregateFetch = async (
    deptCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const dbBodaccInst = serverInstance && serverInstance.dbBodacc ? serverInstance.dbBodacc : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelDepartmentsBodaccPricesAggregateFetch:${deptCode}`

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }
    
    const cursorBodacc = dbBodaccInst
        .collection('bodaccPriceAggregates')
        .aggregate([
            {
                $match: {
                    insee: new RegExp(`^${deptCode}`, 'gi')
                }
            },
            {
                $group: {
                    _id: null,
                    total:  { $sum: "$count" },
                    start_date: { $min: "$start_date" }
                }
            },
            {
                $sort: { price_avg: -1 }
            }
        ].filter(o => o))

    let results = []
    for await (const doc of cursorBodacc) {
        results.push(doc)
    }

    redis.set(redisKey, JSON.stringify(results.length > 0 ? results[0] : null), 'EX', 60 * 60 * 24).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return results.length > 0 ? results[0] : null
}

export const modelStatesBodaccPricesAggregateFetch = async (
    stateCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const dbBodaccInst = serverInstance && serverInstance.dbBodacc ? serverInstance.dbBodacc : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelStatesBodaccPricesAggregateFetch:${stateCode}`

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const cursorBodacc = dbBodaccInst
        .collection('bodaccPriceAggregates')
        .aggregate([
            {
                $match: {
                    insee: new RegExp(`^(${departments.filter(o => o.codeRegion === stateCode).map(o => o.code).join('|')})`, 'gi')
                }
            },
            {
                $group: {
                    _id: null,
                    total:  { $sum: "$count" },
                    start_date: { $min: "$start_date" }
                }
            },
            {
                $sort: { price_avg: -1 }
            }
        ].filter(o => o))

    let results = []
    for await (const doc of cursorBodacc) {
        results.push(doc)
    }

    redis.set(redisKey, JSON.stringify(results.length > 0 ? results[0] : null), 'EX', 60 * 60 * 24).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return results.length > 0 ? results[0] : null
}