import {
    SHOW_VIDEO_POPUP,
    HIDE_VIDEO_POPUP,
    LAST_PROSPECTIONS_FETCH,
    LAST_PROSPECTIONS_FETCHED,
    LAST_PROSPECTIONS_ERROR,
    SHOW_PRO_SUBSCRIBE_DIALOG,
    HIDE_PRO_SUBSCRIBE_DIALOG,
    SHOW_STATE_SUBSCRIBE_DIALOG,
    HIDE_STATE_SUBSCRIBE_DIALOG,
    SHOW_DEMO_DIALOG,
    HIDE_DEMO_DIALOG
} from "./types"

import { modelProspectionsStateFetch } from "../models/Prospections"

export const showProSubscribeDialog = () => {
    return { type: SHOW_PRO_SUBSCRIBE_DIALOG }
}

export const hideProSubscribeDialog = () => {
    return { type: HIDE_PRO_SUBSCRIBE_DIALOG }
}

export const showStateSubscribeDialog = () => {
    return { type: SHOW_STATE_SUBSCRIBE_DIALOG }
}

export const hideStateSubscribeDialog = () => {
    return { type: HIDE_STATE_SUBSCRIBE_DIALOG }
}

export const showDemoDialog = () => {
    return { type: SHOW_DEMO_DIALOG }
}

export const hideDemoDialog = () => {
    return { type: HIDE_DEMO_DIALOG }
}

export const showVideoPopup = () => {
    return { type: SHOW_VIDEO_POPUP }
}

export const hideVideoPopup = () => {
    return { type: HIDE_VIDEO_POPUP }
}