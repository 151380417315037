import axios from "../config/axios"
import getConfig from "next/config"

import {
    DISPLAY_AGENCY_PHONE_RESET,
    DISPLAY_AGENCY_PHONE_FETCH,
    DISPLAY_AGENCY_PHONE_FETCHED,
    DISPLAY_AGENCY_PHONE_ERROR
} from "./types"


export const displayAgencyPhoneReset = () => {
    return {
        type: DISPLAY_AGENCY_PHONE_RESET
    }
}

export const displayAgencyPhoneFetch = (agencyId, callback = null) => {
    return dispatch => {
        dispatch({ type: DISPLAY_AGENCY_PHONE_FETCH })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        instance
            .post("/agency/phone/fetch", {
                agency_id: agencyId
            })
            .then(res => {
                dispatch({ type: DISPLAY_AGENCY_PHONE_FETCHED, payload: res.data })
                if (callback) {
                    callback(res)
                }
            })
            .catch(error => {
                dispatch({ type: DISPLAY_AGENCY_PHONE_ERROR, payload: error })
            })
    }
}