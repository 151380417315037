import firebaseInstance from "../config/firebase"
import algoliasearch from "algoliasearch"
import _ from "lodash"
import getConfig from "next/config"

import codeRegions from "../datas/codeRegions"
import regionsURL from "../datas/regionsURL"
import regionsGPS from "../datas/regionsGPS"
import { translateLocationsToAlgoliaFilters } from "../helpers/Locations"

import Distance from "../helpers/Distance"

let client = null
let index = null

const modelGetMostPopulatedCitiesBase = async (
    type,
    state,
    locations = [],
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getMostPopulatedCities_${type}_${state}_${locations.reduce((a, l) => a + "_" + l.id, "")}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const db = await fi.firestore().collection("most_populated_cities_cache")
    const doc = await db
        .doc(
            type + "_" + state + locations.reduce((a, l) => a + "_" + l.id, "")
        )
        .get()

    let value = null
    if (doc.exists) {
        value = { locations: doc.data().list, counts: doc.data().counts }
    }

    redis.set(redisKey, JSON.stringify(value)).then(res => {
        // console.log('REDIS SET: ', res)
    }) 

    return value
}

export const modelGetMostPopulatedCities = async (
    type,
    state,
    locations = [],
    limit = 20,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let value = await modelGetMostPopulatedCitiesBase(
        type,
        state,
        locations,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    if (value) {
        return value
    }

    locations = await translateLocationsToAlgoliaFilters(locations)
    return new Promise((resolve, reject) => {
        let config = {
            query: "",
            page: 0,
            hitsPerPage: limit
        }

        let filtersList = []
        let invertRegionsURL = _.invert(regionsURL)
        let stateLibelle = invertRegionsURL[state]
        if (codeRegions[stateLibelle]) {
            filtersList.push(`codeRegion: ${codeRegions[stateLibelle]}`)
        }
        if (locations.length > 1) {
            filtersList.push("( " + locations.join(" OR ") + " )")
        } else if (locations.length === 1) {
            filtersList.push(locations[0])
        }
        config.filters = filtersList.join(" AND ")

        if (index === null) {
            let publicRuntimeConfig = {}
            if (getConfig()) {
                publicRuntimeConfig = getConfig().publicRuntimeConfig
            } else {
                publicRuntimeConfig = runtimeConfig
            }

            client = algoliasearch(
                publicRuntimeConfig.algoliaApplicationID,
                publicRuntimeConfig.algoliaSearchOnlyAPIKeyLocations
            )

            index = client.initIndex(publicRuntimeConfig.algoliaCommunesIndexName)
        }

        index.clearCache()

        index.search(config, (error, content) => {
            if (error) reject(error)

            let list = []
            for (var h in content.hits) {
                list.push(content.hits[h])
            }

            resolve({ locations: list, counts: [] })
        })
    })
}

export const modelGetMostPopulatedCitiesV2 = async (
    state,
    locations = [],
    limit = 20,
    excludeLigne5 = false,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getMostPopulatedCitiesV2:${excludeLigne5 ? 'true' : 'false'}:${limit}:${state}:${locations.length > 0 ? locations.reduce((a, l) => a + "_" + l.id, "") : "all"}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    locations = await translateLocationsToAlgoliaFilters(locations)
    return new Promise((resolve, reject) => {
        let config = {
            query: "",
            page: 0,
            hitsPerPage: limit
        }

        let filtersList = []
        let invertRegionsURL = _.invert(regionsURL)
        let stateLibelle = invertRegionsURL[state]
        if (codeRegions[stateLibelle]) {
            filtersList.push(`codeRegion: ${codeRegions[stateLibelle]}`)
        }
        if (locations.length > 1) {
            filtersList.push("( " + locations.join(" OR ") + " )")
        } else if (locations.length === 1) {
            filtersList.push(locations[0])
        }
        if (excludeLigne5) {
            filtersList.push("NOT _tags:locations_subtype_ligne_5")
        }
        config.filters = filtersList.join(" AND ")

        if (index === null) {
            let publicRuntimeConfig = {}
            if (getConfig()) {
                publicRuntimeConfig = getConfig().publicRuntimeConfig
            } else {
                publicRuntimeConfig = runtimeConfig
            }

            client = algoliasearch(
                publicRuntimeConfig.algoliaApplicationID,
                publicRuntimeConfig.algoliaSearchOnlyAPIKeyLocations
            )

            index = client.initIndex(publicRuntimeConfig.algoliaCommunesIndexName)
        }

        index.clearCache()

        index.search(config, (error, content) => {
            if (error) reject(error)

            let list = []
            if (
                content &&
                content.hits &&
                _.isArray(content.hits) &&
                content.hits.length > 0
            ) {
                for (let i = 0; i < content.hits.length; i++) {
                    list.push(content.hits[i])
                }
            }

            const value = { locations: list, counts: [] }

            redis.set(redisKey, JSON.stringify(value)).then(res => {
                // console.log('REDIS SET: ', res)
            })

            resolve(value)
        })
    })
}

export const modelGetAroundLocationCities = async (
    state,
    location,
    excludeLigne5 = false,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let lat = location.lat
    let long = location.long
    if (lat < long) {
        lat = location.long
        long = location.lat
    }
    if (location.id === 'country_fr') {
        lat = 46.227638
        long = 2.213749
    }
    if (location.type === 'region') {
        const region = regionsGPS.find(o => o.code === location.codeRegion)
        lat = region.coordinates[1]
        long = region.coordinates[0]
    }
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getAroundLocationCities:${excludeLigne5 ? 'true' : 'false'}:${state}:${location.code}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    return new Promise((resolve, reject) => {
        let config = {
            aroundLatLng: `${lat}, ${long}`,
            aroundRadius: 8 * 1000,
            query: "",
            page: 0,
            hitsPerPage: 100,
            filters: `NOT objectID:${location.code}${excludeLigne5 ? ' AND NOT _tags:locations_subtype_ligne_5' : ''}`
        }

        if (index === null) {
            let publicRuntimeConfig = {}
            if (getConfig()) {
                publicRuntimeConfig = getConfig().publicRuntimeConfig
            } else {
                publicRuntimeConfig = runtimeConfig
            }

            client = algoliasearch(
                publicRuntimeConfig.algoliaApplicationID,
                publicRuntimeConfig.algoliaSearchOnlyAPIKeyLocations
            )

            index = client.initIndex(publicRuntimeConfig.algoliaCommunesIndexName)
        }

        index.clearCache()

        index.search(config, (error, content) => {
            if (error) reject(error)
            let list = []
            let dist = []

            if (
                content &&
                content.hits &&
                _.isArray(content.hits) &&
                content.hits.length > 0
            ) {
                for (let i = 0; i < content.hits.length; i++) {
                    try {
                        dist.push({
                            name: content.hits[i].nom,
                            lat: content.hits[i]._geoloc.lat,
                            lng: content.hits[i]._geoloc.lng,
                            distance: Distance(lat, long, content.hits[i]._geoloc.lat, content.hits[i]._geoloc.lng),
                            object: content.hits[i]
                        })
                        list.push(content.hits[i])
                    } catch (e) {
                        console.log('ERROR modelGetAroundLocationCities : ', i, content.hits[i], e, serverInstance.originalUrl)
                    }
                }
            }

            dist = _.sortBy(_.take(_.sortBy(dist, ['distance']), 20), ['name']).map(o => ({ ...o.object, distance: o.distance }))

            const value = { locations: dist, counts: [] }

            redis.set(redisKey, JSON.stringify(value)).then(res => {
                // console.log('REDIS SET: ', res)
            })

            resolve(value)
        })
    })
}