import balanced from "node-balanced"
import getConfig from "next/config"
import _ from "lodash"
import moment from "moment"
import "moment/locale/fr"
import algoliasearch from "algoliasearch"

import axios from "../config/axios"
import firebaseInstance from "../config/firebase"
import { generateObfuscatedLinkString } from "../helpers/SEO"
import guideMapping from "../datas/guideMapping"
import typesURLMatching from "../datas/typesURLMatching"
import departments from "../datas/departments"
import departmentsWordLink from "../datas/departmentsWordLink"
import statesWordLink from "../datas/statesWordLink"
import reverseCodeRegions from "../datas/reverseCodeRegions"
import specialCase from "../datas/specialCase"
import reverseSpecialCase from "../datas/reverseSpecialCase"
import codeRegions from "../datas/codeRegions"
import regionsURL from "../datas/regionsURL"
import { linkResolver } from "../helpers/prismic"
import zerofill from "../helpers/zerofill"
import numberWithSpaces from "../helpers/Numbers"
import {
    translateAlgoliaHitsToLocations,
    getPageURLFromLocation
} from "../helpers/Locations"
  
import {
    getProfilesCount,
    getProfilesCountByState,
    getProfilesCountByDepartment
} from "./Users"
import {
    modelBodaccPricesAggregateFetch,
    modelDepartmentsBodaccPricesAggregateFetch,
    modelStatesBodaccPricesAggregateFetch
} from "./Bodacc"

let client = null
let index = null
const instance = axios.create({
    baseURL:
        getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
    timeout: 30000
})

const order = [
    "titre_1",
    "section_1_paragraphe_1",
    "section_1_paragraphe_2",
    "section_1_paragraphe_3",
    "section_1_paragraphe_4",
    "section_1_paragraphe_5",
    "section_1_paragraphe_6",
    "titre_2",
    "section_2_paragraphe_1",
    "section_2_paragraphe_2",
    "section_2_paragraphe_3",
    "section_2_paragraphe_4",
    "section_2_paragraphe_5",
    "section_2_paragraphe_6",
    "titre_3",
    "section_3_paragraphe_1",
    "section_3_paragraphe_2",
    "section_3_paragraphe_3",
    "section_3_paragraphe_4",
    "section_3_paragraphe_5",
    "section_3_paragraphe_6",
    "titre_4",
    "section_4_paragraphe_1",
    "section_4_paragraphe_2",
    "section_4_paragraphe_3",
    "section_4_paragraphe_4",
    "section_4_paragraphe_5",
    "section_4_paragraphe_6",
    "titre_5",
    "section_5_paragraphe_1",
    "section_5_paragraphe_2",
    "section_5_paragraphe_3",
    "section_5_paragraphe_4",
    "section_5_paragraphe_5",
    "section_5_paragraphe_6"
]

const orderDepartment = [
    "intro_titre_1",
    "intro_paragraphe_1",
    "intro_paragraphe_2",
    "chalandise_titre_1",
    "chalandise_paragraphe_1",
    "chalandise_titre_2",
    "chalandise_paragraphe_2",
    "chalandise_paragraphe_3",
    "chalandise_paragraphe_4",
    "chalandise_paragraphe_5",
    "chalandise_titre_3",
    "chalandise_paragraphe_6",
    "chalandise_paragraphe_7",
    "commerce_titre_1",
    "commerce_paragraphe_1",
    "commerce_paragraphe_2",
    "commerce_paragraphe_3",
    "commerce_paragraphe_4",
    "commerce_paragraphe_5",
    "conseil_titre_1",
    "conseil_paragraphe_1",
    "conseil_paragraphe_2",
    "conseil_paragraphe_3",
    "conseil_paragraphe_4",
    "footnote_paragraphe_1"
]

const orderState = [
    "intro_titre_1",
    "intro_paragraphe_1",
    "intro_paragraphe_2",
    "chalandise_titre_1",
    "chalandise_paragraphe_1",
    "chalandise_titre_2",
    "chalandise_paragraphe_2",
    "chalandise_paragraphe_3",
    "commerce_titre_1",
    "commerce_paragraphe_1",
    "commerce_paragraphe_2",
    "commerce_paragraphe_3",
    "commerce_paragraphe_4",
    "commerce_paragraphe_5",
    "conseil_titre_1",
    "conseil_paragraphe_1",
    "conseil_paragraphe_2",
    "conseil_paragraphe_3",
    "conseil_paragraphe_4",
    "footnote_paragraphe_1"
]

const cleanStructuredInformations = structuredInformations => {
    let newStructuredInformations = structuredInformations
    let newSection = newStructuredInformations.section
    let newTitre = newStructuredInformations
        .titre
        .map(o => {
            const infos = /^titre_([0-9]+)$/i.exec(o.key)
            const nb = parseInt(infos[1])

            if (newSection.filter(o => (new RegExp(`^section_${nb}`, 'i')).test(o.key) && o.content.trim() !== '').length === 0) {
                return { key: o.key, content: '' }
            }

            return o
        })

    newStructuredInformations = {
        ...newStructuredInformations,
        titre: newTitre,
        section: newSection
    }

    return newStructuredInformations
}

const getPageURLAndAnchorFromAlgoliaHit = async (hit, disabled = false) => {
    const toSplit = ['Paris', 'Marseille', 'Lyon']
    const stateNames = _.invert(codeRegions)
  
    const locations = translateAlgoliaHitsToLocations(
        [hit],
        toSplit,
        disabled,
        stateNames,
        0,
        '',
        false,
        disabled
    )
    if (locations.length === 0) {
        throw 'doesnt_exist'
    }
  
    return { url: getPageURLFromLocation(locations[0]), anchor: locations[0].libelle }
}
  
const getURLAnchor = async (
    insee,
    disabled = false,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    if (!insee) {
        throw 'insee_missing'
    }
    insee = zerofill(insee, 5)

    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst

    const redisKey = `getURLAnchor:${insee}:${disabled ? '1' : '0'}`

    if (refreshCache) {
        await redis.del(redisKey)
    }
  
    try {
        const infos = await redis.get(redisKey)

        if (infos === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(infos)
    } catch (error) {}

    if (reverseSpecialCase[insee]) {
        let commune = communes[`${reverseSpecialCase[insee].insee}_${reverseSpecialCase[insee].cp}`]

        if (disabled) {
        insee = commune.code
        }
        if (!disabled) {
            try {
                let infos = await getPageURLAndAnchorFromAlgoliaHit(commune)

                infos.anchor = infos.anchor
                    .replace('Paris (Tout arrondissement)', 'Paris (75000)')
                    .replace('Lyon (Tout arrondissement)', 'Lyon (69000)')
                    .replace('Marseille (Tout arrondissement)', 'Marseille (13000)')
                if (
                    serverInstance.postalCodeInseeMatching &&
                    typeof serverInstance.postalCodeInseeMatching[zerofill(insee, 5)] !== 'undefined'
                ) {
                    infos.anchor = infos.anchor.replace(/\(.+\)/i, `(${serverInstance.postalCodeInseeMatching[zerofill(insee, 5)]})`)
                }

                redis.set(redisKey, JSON.stringify(infos))

                return infos
            } catch (e) {
                throw 'doesnt_exist'
            }
        }
    }

    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }

    if (index === null) {
        client = algoliasearch(
            publicRuntimeConfig.algoliaApplicationID,
            publicRuntimeConfig.algoliaSearchOnlyAPIKey
        )
        index = client.initIndex(
            publicRuntimeConfig.algoliaPrefix + publicRuntimeConfig.algoliaCommunesIndexName
        )
    }

    index.clearCache()

    const p = new Promise((resolve, reject) => {
        index.getObject(insee, (error, content) => {
            if (error) reject(error)

            resolve(content)
        })
    })

    let result = null
    try {
        result = await p
    } catch (e) {
        throw 'doesnt_exist'
    }

    try {
        let infos = await getPageURLAndAnchorFromAlgoliaHit(result, specialCase[insee] ? true : disabled)

        infos.anchor = infos.anchor
            .replace('Paris (Tout arrondissement)', 'Paris (75000)')
            .replace('Lyon (Tout arrondissement)', 'Lyon (69000)')
            .replace('Marseille (Tout arrondissement)', 'Marseille (13000)')
        if (
            serverInstance.postalCodeInseeMatching &&
            typeof serverInstance.postalCodeInseeMatching[zerofill(insee, 5)] !== 'undefined'
        ) {
            infos.anchor = infos.anchor.replace(/\(.+\)/i, `(${serverInstance.postalCodeInseeMatching[zerofill(insee, 5)]})`)
        }

        redis.set(redisKey, JSON.stringify(infos))

        return infos
    } catch (e) {
        throw 'doesnt_exist'
    }
}

const modelCitiesPublicPageBaseFetch = async (
    type,
    transactionType,
    inseeCode,
    postalCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const contentPrunning = serverInstance && serverInstance.content_prunning ? serverInstance.content_prunning : null
    const locationURLsMatching = serverInstance && serverInstance.location_urls_matching ? serverInstance.location_urls_matching : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getCitiesPublicPage_${inseeCode}_${postalCode}_${type}_${transactionType}_${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let content = JSON.parse(res)

        const typesMapping = {
            'local-commercial': 'local_commercial',
            'achat-fonds-commerce': 'fonds_de_commerce',
            'terrain': 'terrain'
        }

        if (locationURLsMatching) {
            const pattern = new RegExp('<a href="(\/(local-commercial|terrain)\/([^>\/]+)\/([^>\/]+)(\/achat\-fonds\-commerce|))">([^<>]+)</a>', 'gi')
            for (const key in content) {
                let result
                let locations = []
                while ((result = pattern.exec(content[key])) !== null) {
                    const code = locationURLsMatching[result[4]].code
                    const html = result[0]
                    const url = result[1]
                    const type = result[2] === 'local-commercial' ? result[5] === '' ? 'local-commercial' : 'achat-fonds-commerce' : result[2]
                    const anchor = result[6]
                    const prunningInfos = contentPrunning[code] ? contentPrunning[code] : null 
                    let prunning = false
                    if (prunningInfos) {
                        prunning = prunningInfos[typesMapping[type]] === 0 ? true : false
                    }
                    locations.push({ code, html, url, type, prunning, anchor })
                }
                for (let i = 0; i < locations.length; i++) {
                    if (locations[i].prunning) {
                        // Remove link (keep anchor text)
                        content[key] = content[key].replace(locations[i].html, locations[i].anchor)
                    }
                }
            }
        }

        return content
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const snapshot = await fi
        .database()
        .ref(
            `/public_pages_cities_contents/${inseeCode}_${postalCode}_${type}_${transactionType}_${version}`
        )
        .once("value")

    let content = null
    if (snapshot.exists()) {
        content = snapshot.val()
    }

    redis.set(redisKey, JSON.stringify(content)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return content
}

const modelCitiesPublicPageBaseFetchV2 = async (
    type,
    inseeCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getCitiesPublicPageV2:${inseeCode}:${type}:${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let content = JSON.parse(res)

        return content
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const content = await dbPublicPagesInst
        .collection('cities_public_pages')
        .findOne({ type, code_insee: inseeCode, version })

    redis.set(redisKey, JSON.stringify(content)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return content
}

const modelDepartmentsPublicPageBaseFetchV2 = async (
    type,
    deptCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getDepartmentsPublicPageV2:${deptCode}:${type}:${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let content = JSON.parse(res)

        return content
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const content = await dbPublicPagesInst
        .collection('departments_public_pages')
        .findOne({ type, code_dept: deptCode, version })

    redis.set(redisKey, JSON.stringify(content)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return content
}

const modelStatesPublicPageBaseFetchV2 = async (
    type,
    stateCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getStatesPublicPageV2:${stateCode}:${type}:${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let content = JSON.parse(res)

        return content
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const content = await dbPublicPagesInst
        .collection('states_public_pages')
        .findOne({ type, code_region: stateCode, version })

    redis.set(redisKey, JSON.stringify(content)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return content
}

const modelCountriesPublicPageBaseFetchV2 = async (
    type,
    countryLabel,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getCountriesPublicPageV2:${countryLabel}:${type}:${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let content = JSON.parse(res)

        return content
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const content = await dbPublicPagesInst
        .collection('countries_public_pages')
        .findOne({ type, libelle: countryLabel, version })

    redis.set(redisKey, JSON.stringify(content)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return content
}

const modelAdsDatasPublicPageFetch = async (
    type,
    inseeCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getAdsDatasPublicPage:${inseeCode}:${type}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let data = JSON.parse(res)

        return data
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const cursor = await dbPublicPagesInst
        .collection('ads_datas')
        .find({ type, insee: inseeCode })
        .sort({ created_at: -1 })
        .limit(1)

    let data = null
    for await (const doc of cursor) {
        data = _.omit(doc, '_id')
    }

    redis.set(redisKey, JSON.stringify(data)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return data
}

const modelFranceAdsDatasPublicPageFetch = async (
    type,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getFranceAdsDatasPublicPage:${type}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let data = JSON.parse(res)

        return data
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const cursor = await dbPublicPagesInst
        .collection('ads_datas')
        .find({ type })
        .sort({ created_at: -1 })
        .limit(1)

    let data = null
    for await (const doc of cursor) {
        data = _.mapKeys(
            _.pickBy(
                _.omit(doc, '_id'),
                (value, key) => key.indexOf('france_') !== -1
            ),
            (value, key) => key.replace('france_', '')
        )
    }

    redis.set(redisKey, JSON.stringify(data)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return data
}

const modelDepartmentsAdsDatasPublicPageFetch = async (
    type,
    deptCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getDepartmentsAdsDatasPublicPage:${deptCode}:${type}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let data = JSON.parse(res)

        return data
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const cursor = await dbPublicPagesInst
        .collection('ads_datas')
        .find({ type, dept_code: deptCode })
        .sort({ created_at: -1 })
        .limit(1)

    let data = null
    for await (const doc of cursor) {
        data = _.mapKeys(
            _.pickBy(
                _.omit(doc, '_id'),
                (value, key) => key.indexOf('dept_') !== -1
            ),
            (value, key) => key.replace('dept_', '')
        )
    }

    redis.set(redisKey, JSON.stringify(data)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return data
}

const modelStatesAdsDatasPublicPageFetch = async (
    type,
    stateCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const dbPublicPagesInst = serverInstance && serverInstance.dbPublicPages ? serverInstance.dbPublicPages : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getStatesAdsDatasPublicPage:${stateCode}:${type}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let data = JSON.parse(res)

        return data
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const cursor = await dbPublicPagesInst
        .collection('ads_datas')
        .find({ type, state_code: stateCode })
        .sort({ created_at: -1 })
        .limit(1)

    let data = null
    for await (const doc of cursor) {
        data = _.mapKeys(
            _.pickBy(
                _.omit(doc, '_id'),
                (value, key) => key.indexOf('state_') !== -1
            ),
            (value, key) => key.replace('state_', '')
        )
    }

    redis.set(redisKey, JSON.stringify(data)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return data
}

export const modelCitiesPublicPageFetch = async (
    type,
    transactionType,
    inseeCode,
    postalCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const content = await modelCitiesPublicPageBaseFetch(
        type,
        transactionType,
        inseeCode,
        postalCode,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    // console.log('content: ', content)

    if (!content) {
        return null
    }

    let structuredInformations = {
        bien: content.bien,
        code_insee: content.code_insee,
        code_postal: content.code_postal,
        libelle_ville: content.libelle_ville,
        transaction: content.transaction,
        updated_at_ts: content.updated_at_ts,
        version: content.version
    }

    // console.log("content: ", content)

    for (let i = 0; i < order.length; i++) {
        // console.log("typeof: ", typeof content[order[i]])
        if (typeof content[order[i]] !== "undefined") {
            const keyInformations = order[i].split("_")
            const category = keyInformations[0]
            const type = keyInformations[1]
            const number = keyInformations[2]
            const value = content[order[i]]

            // console.log("category: ", category, type, number)
            // console.log("content: ", value)

            if (typeof structuredInformations[category] === "undefined") {
                structuredInformations[category] = []
                // console.log("created")
            }

            let formattedContent = {
                content: replaceNoDataConditions(value, category + "_" + type + "_" + number, type, adsDatas, bodaccAggregates),
                type,
                key: category + "_" + type + "_" + number
            }

            structuredInformations[category].push(formattedContent)
        } else {
            // console.log("missing key: ", order[i])
        }
    }

    // console.log("structuredInformations :", structuredInformations)

    return structuredInformations
}

String.prototype.replaceBetween = function (start, length, what) {
    return this.substring(0, start) + what + this.substring(start + length)
}

const getPublicPageURLAndAnchor = async (
    insee,
    disabled = false,
    wordLink = false,
    runtimeConfig = null,
    serverInstance = null
) => {
    if (insee === 'fra') {
        return { anchor: 'France', url: '' }
    }
    let infos
    if ((infos = /^reg\_([0-9AB]+)$/i.exec(insee)) !== null) {
        const stateCode = infos[1]
        if (wordLink) {
            const stateWordLink = statesWordLink.filter(o => o.state_code === zerofill(stateCode, 2))[0]
            return {
                anchor: stateWordLink.name,
                url: `/${regionsURL[reverseCodeRegions[stateCode]]}`.replace(/paris\-75$/gi, 'paris-75000')
            }
        }
        if (!wordLink) {
            return {
                anchor: reverseCodeRegions[stateCode],
                url: `/${regionsURL[reverseCodeRegions[stateCode]]}`.replace(/paris\-75$/gi, 'paris-75000')
            }
        }
    }
    if ((infos = /^dept\_([0-9AB]+)$/i.exec(insee)) !== null) {
        const deptCode = infos[1]
        const departmentInfos = departments.filter(o => o.code === zerofill(deptCode, 2))[0]
        if (wordLink) {
            const departmentWordLink = departmentsWordLink.filter(o => o.dept_code === zerofill(deptCode, 2))[0]
            return {
                anchor: `${departmentWordLink.name} (${departmentInfos.code})`,
                url: `/${regionsURL[reverseCodeRegions[departmentInfos.codeRegion]]}/${departmentInfos.seo}`.replace(/paris\-75$/gi, 'paris-75000')
            }
        }
        if (!wordLink) {
            return {
                anchor: `${departmentInfos.name} (${departmentInfos.code})`,
                url: `/${regionsURL[reverseCodeRegions[departmentInfos.codeRegion]]}/${departmentInfos.seo}`.replace(/paris\-75$/gi, 'paris-75000')
            }
        }
    }

    try {
        return await getURLAnchor(insee, disabled, runtimeConfig, serverInstance)
    } catch(e) {}

    return { anchor: insee, url: '' }
}

const generateLink = async (
    type,
    linkType,
    location,
    url,
    anchor,
    passed = {},
    serverInstance = null,
    origin = null,
    refreshCache = false
) => {
    let infosReg
    let locationInfos = null

    if (location === 'fra') {
        if (!origin || (origin && (origin.type !== type || origin.location_type !== 'country' || origin.code !== 'France'))) {
            locationInfos = await modelCountriesPublicPageBaseFetchV2(type, 'France', 1, null, serverInstance, refreshCache)
        }
    }
    if ((infosReg = /^reg\_([0-9AB]+)$/i.exec(location)) !== null) {
        const stateCode = zerofill(infosReg[1], 2)
        if (!origin || (origin && (origin.type !== type || origin.location_type !== 'state' || origin.code !== stateCode))) {
            locationInfos = await modelStatesPublicPageBaseFetchV2(type, stateCode, 1, null, serverInstance, refreshCache)
        }
    }
    if ((infosReg = /^dept\_([0-9AB]+)$/i.exec(location)) !== null) {
        const departmentCode = zerofill(infosReg[1], 2)
        if (!origin || (origin && (origin.type !== type || origin.location_type !== 'department' || origin.code !== departmentCode))) {
            locationInfos = await modelDepartmentsPublicPageBaseFetchV2(type, departmentCode, 1, null, serverInstance, refreshCache)
        }
    }
    if (!locationInfos) {
        const inseeCode = zerofill(location, 5)
        if (!origin || (origin && (origin.type !== type || origin.location_type !== 'city' || origin.code !== inseeCode))) {
            locationInfos = await modelCitiesPublicPageBaseFetchV2(type, inseeCode, 1, null, serverInstance, refreshCache)
        }
    }

    let link = anchor
    if (locationInfos) {
        link = `<a class="green-link-v2" href="${url}">${anchor}</a>`
        if (typeof passed[`${type}-${linkType}`] !== 'undefined') {
            link = generateObfuscatedLinkString(url, anchor, "green-olink-v2")
        }
    }

    return link
}

const replaceAllURLs = async (
    content,
    passed = {},
    runtimeConfig = null,
    serverInstance = null,
    origin = null,
    refreshCache = false
) => {
    let matches = null
    while ((matches = /\[retail\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-${matches[0]}`] = true
    }
    while ((matches = /\[retail\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial-vente']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-vente-${matches[0]}`] = true
    }
    while ((matches = /\[goodwill\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'fonds-commerce',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['fonds-commerce']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`fonds-commerce-${matches[0]}`] = true
    }
    while ((matches = /\[office\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-${matches[0]}`] = true
    }
    while ((matches = /\[office\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau-vente']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-vente-${matches[0]}`] = true
    }
    while ((matches = /\[coworking\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'coworking',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['coworking']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`coworking-${matches[0]}`] = true
    }
    while ((matches = /\[land\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'terrain',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['terrain']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`terrain-${matches[0]}`] = true
    }
    while ((matches = /\[land\_selling\_to\_warehouse\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `vendre des entrepôts de stockage ou des locaux d’activités à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `vendre un entrepôt ou local d’activité`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `vendre un entrepôt ou local d’activité ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `vendre des entrepôts ou locaux d'activités dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'entrepot-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['entrepot-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`entrepot-vente-${matches[0]}`] = true
    }
    while ((matches = /\[land\_selling\_to\_office\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `vendre des bureaux à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `vente de bureaux`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `vendre des plateaux de bureaux ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `vendre des plateaux de bureaux dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-vente-${matches[0]}`] = true
    }
    while ((matches = /\[land\_selling\_to\_retail\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `vendre des locaux commerciaux à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `vendre des locaux commerciaux`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `vente de locaux commerciaux ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `vendre des locaux commerciaux dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-vente-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'entrepot',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['entrepot']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`entrepot-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'entrepot-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['entrepot-vente']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`entrepot-vente-${matches[0]}`] = true
    }
    while ((matches = /\[popup\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'popup-store',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['popup-store']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`popup-store-${matches[0]}`] = true
    }
    while ((matches = /\[coworking\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'coworking',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['coworking']}${infos.url}`,
                    infos.anchor,
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`coworking-${matches[0]}`] = true
    }
    while ((matches = /\[retail\_leasing\_to\_retail\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `prix de vente d’un local commercial à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `prix des locaux commerciaux en vente`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `prix des locaux commerciaux en vente ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `vente de murs commerciaux dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-vente-${matches[0]}`] = true
    }
    while ((matches = /\[retail\_selling\_to\_retail\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `location d’un local commercial à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `loyers des locaux commerciaux en location`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `loyer moyen d’un local commercial ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `loyers des locaux commerciaux en location dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-${matches[0]}`] = true
    }
    while ((matches = /\[retail\_selling\_to\_goodwill\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `valeur des fonds de commerce à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `valeurs des fonds de commerce en vente`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `valeurs des fonds de commerce en vente ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `valeurs des fonds de commerce à vendre dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'fonds-commerce',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['fonds-commerce']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`fonds-commerce-${matches[0]}`] = true
    }
    while ((matches = /\[retail\_leasing\_to\_office\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `loyer moyen des bureaux en location à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `loyer moyen des bureaux en location`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `loyer moyen des bureaux en location ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `loyer moyen des bureaux en location dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-${matches[0]}`] = true
    }
    while ((matches = /\[retail\_leasing\_to\_goodwill\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `prix des fonds de commerce en vente à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `prix des fonds de commerce à vendre`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `prix des fonds de commerce à vendre ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `prix des fonds de commerce à vendre dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'fonds-commerce',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['fonds-commerce']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`fonds-commerce-${matches[0]}`] = true
    }
    while ((matches = /\[goodwill\_selling\_to\_(local|retail)\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[2], false, true, runtimeConfig, serverInstance)
        let anchor = `loyer moyen d’un local commercial à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `loyers des locaux commerciaux en location`
        }
        if (/^reg_/gi.test(matches[2])) {
            anchor = `loyer réel d’un local commercial en ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[2])) {
            anchor = `loyer d’un local commercial en location dans ${infos.anchor}`
        }
        //console.log('infos: ', infos)
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial',
                    matches[0],
                    matches[2],
                    `/${typesURLMatching['local-commercial']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-${matches[0]}`] = true
    }
    while ((matches = /\[office\_selling\_to\_office\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `les loyers réels de bureaux en location à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `loyers des bureaux en location`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `valeurs locatives des bureaux ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `loyer de bureaux en location dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-${matches[0]}`] = true
    }
    while ((matches = /\[office\_leasing\_to\_office\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        //console.log('infos: ', infos)
        let anchor = `bureaux à vendre à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `prix des bureaux en vente`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `prix des bureaux à vendre ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `murs de bureaux en vente dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-vente-${matches[0]}`] = true
    }
    while ((matches = /\[office\_leasing\_to\_retail\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `louer un local commercial à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `locaux commerciaux à la location`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `loyers des locaux commerciaux en location ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `locaux commerciaux en location dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-${matches[0]}`] = true
    }
    while ((matches = /\[office\_leasing\_to\_coworking\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `espaces de coworking à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `espace de coworking`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `espace de coworking ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `espace de coworking dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'coworking',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['coworking']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`coworking-${matches[0]}`] = true
    }
    while ((matches = /\[office\_selling\_to\_coworking\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `espace de coworking à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `espace de coworking`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `espace de coworking ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `espace de coworking dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'coworking',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['coworking']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`coworking-${matches[0]}`] = true
    }
    while ((matches = /\[office\_selling\_to\_retail\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `locaux commerciaux en vente à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `locaux commerciaux en vente`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `l’achat de murs commerciaux ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `local commercial à vendre dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-vente-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_leasing\_to\_retail\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `locaux commerciaux en location à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `louer des locaux commerciaux`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `louer des locaux commerciaux ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `local commercial à vendre dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_leasing\_to\_office\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `loyer des bureaux en location à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `loyers des bureaux à la location`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `location de bureaux ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `bureaux en location dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_leasing\_to\_land\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `prix des terrains industriels en vente à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `achat d’un terrain industriel`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `l'acquisition de terrains industriels ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `prix des terrains industriels à vendre dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'terrain',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['terrain']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`terrain-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_leasing\_to\_warehouse\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `prix des entrepôts ou locaux d’activités en vente à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `achat d’un entrepôt ou local d’activité`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `l’achat de murs d’entrepôt ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `prix des entrepôts à vendre dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'entrepot-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['entrepot-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`entrepot-vente-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_selling\_to\_warehouse\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `loyer moyen d’un entrepôt ou local d’activité en location à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `loyers des entrepôts ou locaux d’activités en location`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `loyers réels d’entrepôts ou locaux d’activités en location ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `valeurs locatives des entrepôts et locaux d’activités en location dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'entrepot',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['entrepot']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`entrepot-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_selling\_to\_office\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `loyer moyen de bureaux en location à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `loyers des bureaux en location`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `loyers des bureaux en location ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `loyers des bureaux en location dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-${matches[1]}`] = true
    }
    while ((matches = /\[warehouse\_selling\_to\_retail\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `murs de locaux commerciaux à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `prix des locaux commerciaux en vente`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `prix des locaux commerciaux en vente ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `achat de locaux commerciaux dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-vente-${matches[1]}`] = true
    }
    while ((matches = /\[warehouse\_selling\_dept\_to\_retail\_selling\_location\_([0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        let anchor = `achat de locaux commerciaux à ${infos.anchor}`
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-vente-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_selling\_reg\_to\_retail\_selling\_location\_([0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        let anchor = `achat de locaux commerciaux à ${infos.anchor}`
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-vente-${matches[0]}`] = true
    }
    while ((matches = /\[warehouse\_selling\_to\_land\_selling\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `prix des terrains industriels à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `terrains industriels à vendre`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `l’achat de terrains industriels ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `terrain industriel dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'terrain',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['terrain']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`terrain-${matches[0]}`] = true
    }
    while ((matches = /\[office\_leasing\_district\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        const anchor = infos.anchor.replace(/(Paris|Marseille|Lyon) ([0-9]+)(er|ème) \([0-9\,\s]+\)/gi, "$2$3")
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-${matches[0]}`] = true
    }
    while ((matches = /\[office\_selling\_district\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        const anchor = infos.anchor.replace(/(Paris|Marseille|Lyon) ([0-9]+)(er|ème) \([0-9\,\s]+\)/gi, "$2$3")
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau-vente',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau-vente']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-vente-${matches[0]}`] = true
    }
    while ((matches = /\[coworking\_district\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, false, runtimeConfig, serverInstance)
        const anchor = infos.anchor.replace(/(Paris|Marseille|Lyon) ([0-9]+)(er|ème) \([0-9\,\s]+\)/gi, "$2$3")
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'coworking',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['coworking']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`coworking-${matches[0]}`] = true
    }
    while ((matches = /\[popup\_to\_retail\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `louer un local commercial à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `louer un local commercial`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `louer un local commercial ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `louer un local commercial dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'local-commercial',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['local-commercial']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`local-commercial-${matches[0]}`] = true
    }
    while ((matches = /\[coworking\_to\_office\_leasing\_location\_([0-9AB]+|fra|reg\_[0-9AB]+|dept\_[0-9AB]+)\]/gi.exec(content)) !== null) {
        const infos = await getPublicPageURLAndAnchor(matches[1], false, true, runtimeConfig, serverInstance)
        let anchor = `location de bureaux à ${infos.anchor}`
        if (infos.url === '') {
            anchor = `location de bureaux`
        }
        if (/^reg_/gi.test(matches[1])) {
            anchor = `location de bureaux ${infos.anchor}`
        }
        if (/^dept_/gi.test(matches[1])) {
            anchor = `location de bureaux dans ${infos.anchor}`
        }
        content = content
            .replaceBetween(
                matches.index,
                matches[0].length,
                await generateLink(
                    'bureau',
                    matches[0],
                    matches[1],
                    `/${typesURLMatching['bureau']}${infos.url}`,
                    anchor.trim(),
                    passed,
                    serverInstance,
                    origin,
                    refreshCache
                )
            )
        passed[`bureau-${matches[0]}`] = true
    }

    const linkResolverInst = linkResolver(serverInstance.prismicEntities)
    while ((matches = /\[guide(\_([0-9]+)|)\:([a-zA-Z0-9\-\_]+)\]/gi.exec(content)) !== null) {
        // console.log('matches: ', matches)
        const nb = matches[2]
        const id = matches[3]
        const url = linkResolverInst({ id })
        const infos = guideMapping.filter(o => o.id === id && o.nb === (nb ? parseInt(nb) : ''))
        // console.log('infos: ', infos)
        // console.log('url: ', url)
        if (infos.length > 0 && url !== null) {
            content = content
                .replaceBetween(
                    matches.index,
                    matches[0].length,
                    typeof passed[id] === 'undefined' ? 
                        `<a class="green-link-v2" href="${url}">${infos[0].value}</a>` :
                        generateObfuscatedLinkString(url, infos[0].value, "green-olink-v2")
                )
            passed[id] = true
        } else {
            content = content
                .replaceBetween(
                    matches.index,
                    matches[0].length,
                    ''
                )
        }
    }

    return content
}

const replaceDatas = async (content, serverInstance = null, passed = {}) => {
    content = content
        .replace('[first_day_current_month]',
            `<span style="font-weight:600;">${moment()
                .startOf('month')
                .locale("fr")
                .format("Do MMMM YYYY")}</span>`
        )

    return Promise.resolve(content)
}

const replaceNoDataConditions = (content, key, type, adsDatas, bodaccAggregates = null) => {
    let count = 0
    if (adsDatas) {
        if (['local-commercial', 'bureau', 'entrepot', 'coworking', 'popup-store'].indexOf(type) !== -1) {
            count = adsDatas.lease_ads_count
        }
        if (['local-commercial-vente', 'bureau-vente', 'entrepot-vente', 'terrain'].indexOf(type) !== -1) {
            count = adsDatas.sell_ads_count
        }
    }
    if (bodaccAggregates && ['fonds-commerce'].indexOf(type) !== -1) {
        count = bodaccAggregates.total
    }

    if (content.trim() === '') {
        return content.trim()
    }

    if (type === 'coworking' && count === 0 && /^section_2/gi.test(key)) {
        return ''
    }

    if (type === 'popup-store' && count === 0 && key === 'section_2_paragraphe_1') {
        return ''
    }

    try {
        let newContent = balanced.replacements({
            source: content,
            open: '[IFNODATA]',
            close: '[ENDIF]',
            balance: true,
            replace: (source, head, tail) => {
                if (source.indexOf('[ELSE]') === -1) {
                    if (count <= 5) {
                        return source
                    } else {
                        return ''
                    }
                }
                if (source.indexOf('[ELSE]') !== -1) {
                    let [ifSource, elseSource] = source.split('[ELSE]', 2)
                    if (count <= 5) {
                        return ifSource
                    } else {
                        return elseSource
                    }
                }
            }
        })

        if (
            ['local-commercial', 'bureau', 'entrepot'].indexOf(type) !== -1 &&
            /\[.*leasing\_average.*\]/gi.test(newContent) &&
            count <= 5
        ) {
            return ''
        }
        if (
            ['local-commercial-vente', 'bureau-vente', 'entrepot-vente', 'terrain'].indexOf(type) !== -1 &&
            /\[.*selling\_average.*\]/gi.test(newContent) &&
            count <= 5
        ) {
            return ''
        }

        return newContent
    } catch (e) {}

    return content
}

const modelCitiesPublicPageFetchV2Process = async (
    type,
    inseeCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const content = await modelCitiesPublicPageBaseFetchV2(
        type,
        inseeCode,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    if (!content) {
        return null
    }

    const adsDatas = await modelAdsDatasPublicPageFetch(
        type,
        inseeCode,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    let adsDatasSecondary = null
    if (type === 'bureau-vente') {
        adsDatasSecondary = await modelAdsDatasPublicPageFetch(
            'bureau',
            inseeCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    const stateRealEstateAgentsCount = await getProfilesCountByState(
        [1],
        departments.filter(d => d.code === inseeCode.substr(0, 2))[0].codeRegion,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    const departmentRealEstateAgentsCount = await getProfilesCountByDepartment(
        [1],
        inseeCode.substr(0, 2),
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    let bodaccAggregates = null
    if (type === 'fonds-commerce') {
        bodaccAggregates = await modelBodaccPricesAggregateFetch(
            inseeCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    let structuredInformations = {
        code_insee: content.code_insee,
        updated_at_ts: content.updated_at_ts,
        version: content.version
    }

    let passed = {}
    for (let i = 0; i < order.length; i++) {
        if (typeof content[order[i]] !== "undefined") {
            const keyInformations = order[i].split("_")
            const category = keyInformations[0]
            const value = content[order[i]]

            if (typeof structuredInformations[category] === "undefined") {
                structuredInformations[category] = []
            }

            let formattedContent = {
                content: replaceNoDataConditions(value, order[i], type, adsDatas, bodaccAggregates),
                key: order[i]
            }

            if (['local-commercial', 'local-commercial-vente'].indexOf(type) !== -1) {
                // [retail_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[retail_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [retail_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[retail_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['bureau', 'bureau-vente'].indexOf(type) !== -1) {
                // [office_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[office_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [office_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[office_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['entrepot', 'entrepot-vente'].indexOf(type) !== -1) {
                // [warehouse_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[warehouse_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [warehouse_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[warehouse_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['terrain'].indexOf(type) !== -1) {
                // [land_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[land_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [land_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[land_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                // [goodwill_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[goodwill_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [goodwill_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[goodwill_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }

            formattedContent.content = await replaceAllURLs(
                formattedContent.content,
                runtimeConfig,
                serverInstance,
                passed,
                { type, location_type: 'city', code: inseeCode },
                refreshCache
            )
            // formattedContent.content = await replaceDatas(formattedContent.content, serverInstance, passed)
            if (adsDatas !== null) {
                // [start_month_publication]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_publication\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date_base * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                // [start_month_analysis]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_analysis\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                formattedContent.content = formattedContent.content.replace(
                    /\[first_day_current_month\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.created_at)
                        .locale("fr")
                        .format("Do MMMM YYYY")}</span>`
                )

                if (['local-commercial'].indexOf(type) !== -1) {
                    // [retail_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [retail_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [retail_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [retail_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['local-commercial-vente'].indexOf(type) !== -1) {
                    // [retail_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [retail_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [retail_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [retail_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [retail_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['bureau'].indexOf(type) !== -1) {
                    // [office_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [office_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [office_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )            
                    // [office_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                    // [office_leasing_average_space_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_min))}</span>`
                    )
                    // [office_leasing_average_space_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_max))}</span>`
                    )
                    // [office_leasing_average_rent_year_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_min) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                    // [office_leasing_average_rent_year_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_max) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                }
                if (['bureau-vente'].indexOf(type) !== -1) {
                    // [office_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [office_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [office_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [office_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [office_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    if (adsDatasSecondary) {
                        // [office_leasing_ad_rolling_trim]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_ad_rolling_trim\]/g,
                            `<span style="font-weight:500;">${numberWithSpaces(adsDatasSecondary.lease_ads_count)}</span>`
                        )
                        // [office_leasing_average_rent_sqm]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_average_rent_sqm\]/g,
                            `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatasSecondary.lease_price_median))}</span>`
                        )
                    }
                }
                if (['coworking'].indexOf(type) !== -1) {
                    // [coworking_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [coworking_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [unit_price_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[unit_price_coworking\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [duree_engagement_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[duree_engagement_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_engagement_median !== null ? numberWithSpaces(adsDatas.duree_engagement_median) : 'N/A'}</span>`
                    )
                    // [depot_garantie_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[depot_garantie_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.depot_de_garantie_median !== null ? numberWithSpaces(adsDatas.depot_de_garantie_median) : 'N/A'}</span>`
                    )
                    // [frais_entree_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[frais_entree_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.frais_entree_median !== null ? numberWithSpaces(Math.round(adsDatas.frais_entree_median)) : 'N/A'}</span>`
                    )
                    // [preavis_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[preavis_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_preavis_median !== null ? numberWithSpaces(adsDatas.duree_preavis_median) : 'N/A'}</span>`
                    )
                }
                if (['entrepot'].indexOf(type) !== -1) {
                    // [warehouse_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [warehouse_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [warehouse_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['entrepot-vente'].indexOf(type) !== -1) {
                    // [warehouse_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [warehouse_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [warehouse_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [warehouse_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [warehouse_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['terrain'].indexOf(type) !== -1) {
                    // [land_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [land_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [land_selling_average_price_sqm_town]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm_town\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [land_selling_average_price_sqm_province]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm_province\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.dept_sell_price_median))}</span>`
                    )
                    // [land_selling_average_price_sqm_state]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm_state\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.state_sell_price_median))}</span>`
                    )
                    // [land_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [land_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                if (bodaccAggregates !== null) {
                    formattedContent.content = formattedContent.content.replace(
                        /\[goodwill_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(bodaccAggregates.total)}</span>`
                    )
                    formattedContent.content = formattedContent.content.replace(
                        /\[start_month_publication\]/g,
                        `<span style="font-weight:500;">${moment(bodaccAggregates.start_date)
                            .startOf('month')
                            .locale("fr")
                            .format("MMMM YYYY")}</span>`
                    )
                }
            }

            if (/\[\!|\{\*/gi.test(formattedContent.content)) {
                const matches = balanced.matches({
                    source: formattedContent.content,
                    open: ['[!', '{*', '['],
                    close: [']', '}', ']'],
                    balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                })
                const realMatches = matches.filter(o => o.head === '[!' && o.tail === ']')
                if (realMatches && realMatches.length) {
                    if (realMatches.length > 0) {
                        for (let i = 0; i < realMatches.length; i++) {
                            if (realMatches[i].head == '[!' && realMatches[i].tail === ']') {
                                let extract = formattedContent
                                    .content
                                    .substr(realMatches[i].index, realMatches[i].length)
                                    .trim()
                                    .substr(realMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - realMatches[i].tail.length)
                                let listFromExtract = extract
                                    .split('!')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                if (listFromExtract.length > 0) {
                                    for (let j = 0; j < listFromExtract.length; j++) {
                                        const extractMatches = balanced.matches({
                                            source: listFromExtract[j],
                                            open: ['{*', '['],
                                            close: ['}', ']'],
                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                        })
                                        for (let k = 0; k < extractMatches.length; k++) {
                                            if (extractMatches[k].head == '{*' && extractMatches[k].tail === '}') {
                                                let extract2 = listFromExtract[j]
                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                    .trim()
                                                    .substr(extractMatches[k].head.length - 1)
                                                extract2 = extract2
                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                const listFromExtract2 = extract2
                                                    .split('*')
                                                    .map(o => o.trim())
                                                    .filter(o => o)
                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                listFromExtract[j] = listFromExtract[j].replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                            }
                                        }
                                    }
                                    const htmlList = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                    formattedContent.content = formattedContent.content.replaceBetween(realMatches[i].index, realMatches[i].length, htmlList)
                                }
                            }
                        }
                    }
                    if (realMatches.length === 0) {
                        const extractMatches = balanced.matches({
                            source: formattedContent.content,
                            open: ['{*', '['],
                            close: ['}', ']'],
                            balance: true, // optional (default: false) when set to true it will return `null` when there is an error
                        })
                        for (let i = 0; i < extractMatches.length; i++) {
                            if (extractMatches[i].head == '{*' && extractMatches[i].tail === '}') {
                                let extract = formattedContent
                                    .content
                                    .substr(extractMatches[i].index, extractMatches[i].length)
                                    .trim()
                                    .substr(extractMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - extractMatches[i].tail.length)
                                const listFromExtract = extract
                                    .split('*')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                const htmlListFinal = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`
                                formattedContent.content = formattedContent.content.replaceBetween(extractMatches[i].index, extractMatches[i].length, htmlListFinal)
                            }
                        }
                    }
                }
            }
            // console.log('formattedContent.content: ', formattedContent.content)

            structuredInformations[category].push(formattedContent)
        } else {
            // console.log("missing key: ", order[i])
        }
    }

    const finalStructuredInformations = cleanStructuredInformations(structuredInformations)

    return finalStructuredInformations
}

export const modelCitiesPublicPageFetchV2 = async (
    type,
    inseeCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false,
    refreshCacheSecond = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelCitiesPublicPageFetchV2:${type}:${inseeCode}:${version}`

    if (refreshCacheSecond) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/public_pages/cities`,
        {
            params: {
                type,
                insee_code: inseeCode
            }
        }
    )
    let finalStructuredInformations = res.data
    /* 
    const finalStructuredInformations = await modelCitiesPublicPageFetchV2Process(
        type,
        inseeCode,
        runtimeConfig,
        serverInstance,
        refreshCache
    ) */

    redis.set(redisKey, JSON.stringify(finalStructuredInformations), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return finalStructuredInformations
}

const modelDepartmentsPublicPageFetchV2Process = async (
    type,
    deptCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const content = await modelDepartmentsPublicPageBaseFetchV2(
        type,
        deptCode,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    // console.log('content 2: ', content)

    if (!content) {
        return null
    }

    const adsDatas = await modelDepartmentsAdsDatasPublicPageFetch(
        type,
        deptCode,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    let adsDatasSecondary = null
    if (type === 'bureau-vente') {
        adsDatasSecondary = await modelDepartmentsAdsDatasPublicPageFetch(
            'bureau',
            deptCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    let bodaccAggregates = null
    if (type === 'fonds-commerce') {
        bodaccAggregates = await modelDepartmentsBodaccPricesAggregateFetch(
            deptCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
        // console.log('bodaccAggregates: ', bodaccAggregates)
    }
    const stateRealEstateAgentsCount = await getProfilesCountByState(
        [1],
        departments.filter(d => d.code === deptCode)[0].codeRegion,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    const departmentRealEstateAgentsCount = await getProfilesCountByDepartment(
        [1],
        deptCode,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    let structuredInformations = {
        dept_code: content.code_dept,
        updated_at_ts: content.updated_at_ts,
        version: content.version
    }

    let passed = {}
    // console.log("content: ", content)

    for (let i = 0; i < order.length; i++) {
        // console.log("typeof: ", typeof content[order[i]])
        if (typeof content[order[i]] !== "undefined") {
            const keyInformations = order[i].split("_")
            const category = keyInformations[0]
            const value = content[order[i]]

            // console.log("category: ", category, type, number)
            // console.log("content: ", value)

            if (typeof structuredInformations[category] === "undefined") {
                structuredInformations[category] = []
                // console.log("created")
            }

            let formattedContent = {
                content: replaceNoDataConditions(value, order[i], type, adsDatas, bodaccAggregates),
                key: order[i]
            }

            if (['local-commercial', 'local-commercial-vente'].indexOf(type) !== -1) {
                // [retail_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[retail_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
                // [retail_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[retail_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['bureau', 'bureau-vente'].indexOf(type) !== -1) {
                // [office_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[office_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [office_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[office_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['entrepot', 'entrepot-vente'].indexOf(type) !== -1) {
                // [warehouse_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[warehouse_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [warehouse_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[warehouse_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['terrain'].indexOf(type) !== -1) {
                // [land_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[land_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [land_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[land_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                // [goodwill_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[goodwill_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
                // [goodwill_pro_province]
                formattedContent.content = formattedContent.content.replace(
                    /\[goodwill_pro_province\]/g,
                    departmentRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${departmentRealEstateAgentsCount}</span>` : ''
                )
            }

            formattedContent.content = await replaceAllURLs(
                formattedContent.content,
                runtimeConfig,
                serverInstance,
                passed,
                { type, location_type: 'department', code: deptCode },
                refreshCache
            )
            // formattedContent.content = await replaceDatas(formattedContent.content, serverInstance, passed)
            // console.log('formattedContent.content: ', formattedContent.content)

            if (adsDatas !== null) {
                // [start_month_publication]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_publication\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date_base * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                // [start_month_analysis]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_analysis\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                formattedContent.content = formattedContent.content.replace(
                    /\[first_day_current_month\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.created_at)
                        .locale("fr")
                        .format("Do MMMM YYYY")}</span>`
                )

                if (['local-commercial'].indexOf(type) !== -1) {
                    // [retail_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [retail_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [retail_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [retail_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['local-commercial-vente'].indexOf(type) !== -1) {
                    // [retail_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [retail_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [retail_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [retail_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [retail_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['bureau'].indexOf(type) !== -1) {
                    // [office_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [office_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [office_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )            
                    // [office_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                    // [office_leasing_average_space_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_min))}</span>`
                    )
                    // [office_leasing_average_space_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_max))}</span>`
                    )
                    // [office_leasing_average_rent_year_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_min) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                    // [office_leasing_average_rent_year_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_max) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                }
                if (['bureau-vente'].indexOf(type) !== -1) {
                    // [office_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [office_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [office_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [office_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [office_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    if (adsDatasSecondary) {
                        // [office_leasing_ad_rolling_trim]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_ad_rolling_trim\]/g,
                            `<span style="font-weight:500;">${numberWithSpaces(adsDatasSecondary.lease_ads_count)}</span>`
                        )
                        // [office_leasing_average_rent_sqm]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_average_rent_sqm\]/g,
                            `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatasSecondary.lease_price_median))}</span>`
                        )
                    }
                }
                if (['coworking'].indexOf(type) !== -1) {
                    // [coworking_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [coworking_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [unit_price_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[unit_price_coworking\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [duree_engagement_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[duree_engagement_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_engagement_median !== null ? numberWithSpaces(adsDatas.duree_engagement_median) : 'N/A'}</span>`
                    )
                    // [depot_garantie_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[depot_garantie_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.depot_de_garantie_median !== null ? numberWithSpaces(adsDatas.depot_de_garantie_median) : 'N/A'}</span>`
                    )
                    // [frais_entree_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[frais_entree_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.frais_entree_median !== null ? numberWithSpaces(Math.round(adsDatas.frais_entree_median)) : 'N/A'}</span>`
                    )
                    // [preavis_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[preavis_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_preavis_median !== null ? numberWithSpaces(adsDatas.duree_preavis_median) : 'N/A'}</span>`
                    )
                }
                if (['entrepot'].indexOf(type) !== -1) {
                    // [warehouse_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [warehouse_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [warehouse_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['entrepot-vente'].indexOf(type) !== -1) {
                    // [warehouse_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [warehouse_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [warehouse_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [warehouse_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [warehouse_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['terrain'].indexOf(type) !== -1) {
                    // [land_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [land_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [land_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [land_selling_average_price_sqm_state]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm_state\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [land_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [land_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                if (bodaccAggregates !== null) {
                    formattedContent.content = formattedContent.content.replace(
                        /\[goodwill_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(bodaccAggregates.total)}</span>`
                    )
                    formattedContent.content = formattedContent.content.replace(
                        /\[start_month_publication\]/g,
                        `<span style="font-weight:500;">${moment(bodaccAggregates.start_date)
                            .startOf('month')
                            .locale("fr")
                            .format("MMMM YYYY")}</span>`
                    )
                }
            }

            if (/\[\!|\{\*/gi.test(formattedContent.content)) {
                /*const result = /\[\!([^\]]+)\]/gi.exec(formattedContent.content)
                console.log('result: ', result)*/
                // console.log('formattedContent: ', formattedContent.content)
                const matches = balanced.matches({
                    source: formattedContent.content,
                    open: ['[!', '{*', '['],
                    close: [']', '}', ']'],
                    balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                })

                const realMatches = matches.filter(o => o.head === '[!' && o.tail === ']')
                if (realMatches && realMatches.length) {
                    if (realMatches.length > 0) {
                        for (let i = 0; i < realMatches.length; i++) {
                            if (realMatches[i].head == '[!' && realMatches[i].tail === ']') {
                                let extract = formattedContent
                                    .content
                                    .substr(realMatches[i].index, realMatches[i].length)
                                    .trim()
                                    .substr(realMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - realMatches[i].tail.length)
                                /*console.log(
                                    'extract: ',
                                    extract
                                )*/
                                let listFromExtract = extract
                                    .split('!')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                // console.log('listFromExtract: ', listFromExtract)
                                if (listFromExtract.length > 0) {
                                    for (let j = 0; j < listFromExtract.length; j++) {
                                        const extractMatches = balanced.matches({
                                            source: listFromExtract[j],
                                            open: ['{*', '['],
                                            close: ['}', ']'],
                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                        })
                                        for (let k = 0; k < extractMatches.length; k++) {
                                            if (extractMatches[k].head == '{*' && extractMatches[k].tail === '}') {
                                                let extract2 = listFromExtract[j]
                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                    .trim()
                                                    .substr(extractMatches[k].head.length - 1)
                                                extract2 = extract2
                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                const listFromExtract2 = extract2
                                                    .split('*')
                                                    .map(o => o.trim())
                                                    .filter(o => o)
                                                    .map(o => {
                                                        const extractMatches = balanced.matches({
                                                            source: o,
                                                            open: ['#$'],
                                                            close: ['#'],
                                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                                        })

                                                        for (let k = 0; k < extractMatches.length; k++) {
                                                            if (extractMatches[k].head == '#$' && extractMatches[k].tail === '#') {
                                                                let extract2 = o
                                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                                    .trim()
                                                                    .substr(extractMatches[k].head.length - 1)
                                                                extract2 = extract2
                                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                                const listFromExtract2 = extract2
                                                                    .split('$')
                                                                    .map(o => o.trim())
                                                                    .filter(o => o)

                                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                                o = o.replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                                            }
                                                        }

                                                        return o
                                                    })
                                                // console.log('listFromExtract2: ', listFromExtract2)
                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                listFromExtract[j] = listFromExtract[j].replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                            }
                                        }
                                    }
                                    const htmlList = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`
                                    // console.log('htmlList: ', htmlList)

                                    formattedContent.content = formattedContent.content.replaceBetween(realMatches[i].index, realMatches[i].length, htmlList)
                                }
                            }
                        }
                    }
                    if (realMatches.length === 0) {
                        const extractMatches = balanced.matches({
                            source: formattedContent.content,
                            open: ['{*'],
                            close: ['}'],
                            balance: true, // optional (default: false) when set to true it will return `null` when there is an error
                        })
                        for (let i = 0; i < extractMatches.length; i++) {
                            if (extractMatches[i].head == '{*' && extractMatches[i].tail === '}') {
                                let extract = formattedContent
                                    .content
                                    .substr(extractMatches[i].index, extractMatches[i].length)
                                    .trim()
                                    .substr(extractMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - extractMatches[i].tail.length)
                                const listFromExtract = extract
                                    .split('*')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                    .map(o => {
                                        const extractMatches = balanced.matches({
                                            source: o,
                                            open: ['#$'],
                                            close: ['#'],
                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                        })

                                        for (let k = 0; k < extractMatches.length; k++) {
                                            if (extractMatches[k].head == '#$' && extractMatches[k].tail === '#') {
                                                let extract2 = o
                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                    .trim()
                                                    .substr(extractMatches[k].head.length - 1)
                                                extract2 = extract2
                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                const listFromExtract2 = extract2
                                                    .split('$')
                                                    .map(o => o.trim())
                                                    .filter(o => o)

                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                o = o.replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                            }
                                        }

                                        return o
                                    })
                                const htmlListFinal = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`
                                formattedContent.content = formattedContent.content.replaceBetween(extractMatches[i].index, extractMatches[i].length, htmlListFinal)
                            }
                        }
                    }
                }
            }

            structuredInformations[category].push(formattedContent)
        } else {
            // console.log("missing key: ", order[i])
        }
    }

    // console.log("structuredInformations :", structuredInformations)
    const finalStructuredInformations = cleanStructuredInformations(structuredInformations)

    return finalStructuredInformations
}

export const modelDepartmentsPublicPageFetchV2 = async (
    type,
    deptCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false,
    refreshCacheSecond = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelDepartmentsPublicPageFetchV2:${type}:${deptCode}:${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCacheSecond) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/public_pages/departments`,
        {
            params: {
                type,
                dept_code: deptCode
            }
        }
    )
    let finalStructuredInformations = res.data
    /*
    const finalStructuredInformations = await modelDepartmentsPublicPageFetchV2Process(
        type,
        deptCode,
        version,
        runtimeConfig,
        refreshCache
    ) */

    redis.set(redisKey, JSON.stringify(finalStructuredInformations), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return finalStructuredInformations
}

const modelStatesPublicPageFetchV2Process = async (
    type,
    stateCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const content = await modelStatesPublicPageBaseFetchV2(
        type,
        stateCode,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    // console.log('content 2: ', content)

    if (!content) {
        return null
    }

    const adsDatas = await modelStatesAdsDatasPublicPageFetch(
        type,
        stateCode,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    let adsDatasSecondary = null
    if (type === 'bureau-vente') {
        adsDatasSecondary = await modelStatesAdsDatasPublicPageFetch(
            'bureau',
            stateCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    let bodaccAggregates = null
    if (type === 'fonds-commerce') {
        bodaccAggregates = await modelStatesBodaccPricesAggregateFetch(
            stateCode,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
        // console.log('bodaccAggregates: ', bodaccAggregates)
    }
    const stateRealEstateAgentsCount = await getProfilesCountByState(
        [1],
        stateCode,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    let structuredInformations = {
        country_label: content.libelle,
        updated_at_ts: content.updated_at_ts,
        version: content.version
    }

    let passed = {}
    // console.log("content: ", content)

    for (let i = 0; i < order.length; i++) {
        // console.log("typeof: ", typeof content[order[i]])
        if (typeof content[order[i]] !== "undefined") {
            const keyInformations = order[i].split("_")
            const category = keyInformations[0]
            const value = content[order[i]]

            // console.log("category: ", category, type, number)
            // console.log("content: ", value)

            if (typeof structuredInformations[category] === "undefined") {
                structuredInformations[category] = []
                // console.log("created")
            }

            let formattedContent = {
                content: replaceNoDataConditions(value, order[i], type, adsDatas, bodaccAggregates),
                key: order[i]
            }

            if (['local-commercial', 'local-commercial-vente'].indexOf(type) !== -1) {
                // [retail_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[retail_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['bureau', 'bureau-vente'].indexOf(type) !== -1) {
                // [office_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[office_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['entrepot', 'entrepot-vente'].indexOf(type) !== -1) {
                // [warehouse_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[warehouse_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['terrain'].indexOf(type) !== -1) {
                // [land_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[land_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                // [goodwill_pro_state]
                formattedContent.content = formattedContent.content.replace(
                    /\[goodwill_pro_state\]/g,
                    stateRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${stateRealEstateAgentsCount}</span>` : ''
                )
            }

            formattedContent.content = await replaceAllURLs(
                formattedContent.content,
                runtimeConfig,
                serverInstance,
                passed,
                { type, location_type: 'state', code: stateCode },
                refreshCache
            )
            // formattedContent.content = await replaceDatas(formattedContent.content, serverInstance, passed)
            // console.log('formattedContent.content: ', formattedContent.content)

            if (adsDatas !== null) {
                // [start_month_publication]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_publication\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date_base * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                // [start_month_analysis]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_analysis\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                formattedContent.content = formattedContent.content.replace(
                    /\[first_day_current_month\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.created_at)
                        .locale("fr")
                        .format("Do MMMM YYYY")}</span>`
                )

                if (['local-commercial'].indexOf(type) !== -1) {
                    // [retail_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [retail_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [retail_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [retail_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['local-commercial-vente'].indexOf(type) !== -1) {
                    // [retail_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [retail_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [retail_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [retail_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [retail_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['bureau'].indexOf(type) !== -1) {
                    // [office_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [office_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [office_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )            
                    // [office_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                    // [office_leasing_average_space_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_min))}</span>`
                    )
                    // [office_leasing_average_space_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_max))}</span>`
                    )
                    // [office_leasing_average_rent_year_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_min) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                    // [office_leasing_average_rent_year_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_max) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                }
                if (['bureau-vente'].indexOf(type) !== -1) {
                    // [office_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [office_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [office_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [office_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [office_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    if (adsDatasSecondary) {
                        // [office_leasing_ad_rolling_trim]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_ad_rolling_trim\]/g,
                            `<span style="font-weight:500;">${numberWithSpaces(adsDatasSecondary.lease_ads_count)}</span>`
                        )
                        // [office_leasing_average_rent_sqm]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_average_rent_sqm\]/g,
                            `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatasSecondary.lease_price_median))}</span>`
                        )
                    }
                }
                if (['coworking'].indexOf(type) !== -1) {
                    // [coworking_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [coworking_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [unit_price_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[unit_price_coworking\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [duree_engagement_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[duree_engagement_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_engagement_median !== null ? numberWithSpaces(adsDatas.duree_engagement_median) : 'N/A'}</span>`
                    )
                    // [depot_garantie_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[depot_garantie_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.depot_de_garantie_median !== null ? numberWithSpaces(adsDatas.depot_de_garantie_median) : 'N/A'}</span>`
                    )
                    // [frais_entree_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[frais_entree_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.frais_entree_median !== null ? numberWithSpaces(Math.round(adsDatas.frais_entree_median)) : 'N/A'}</span>`
                    )
                    // [preavis_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[preavis_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_preavis_median !== null ? numberWithSpaces(adsDatas.duree_preavis_median) : 'N/A'}</span>`
                    )
                }
                if (['entrepot'].indexOf(type) !== -1) {
                    // [warehouse_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [warehouse_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [warehouse_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['entrepot-vente'].indexOf(type) !== -1) {
                    // [warehouse_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [warehouse_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [warehouse_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [warehouse_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [warehouse_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['terrain'].indexOf(type) !== -1) {
                    // [land_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [land_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [land_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [land_selling_average_price_sqm_state]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm_state\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [land_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [land_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                if (bodaccAggregates !== null) {
                    formattedContent.content = formattedContent.content.replace(
                        /\[goodwill_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(bodaccAggregates.total)}</span>`
                    )
                    formattedContent.content = formattedContent.content.replace(
                        /\[start_month_publication\]/g,
                        `<span style="font-weight:500;">${moment(bodaccAggregates.start_date)
                            .startOf('month')
                            .locale("fr")
                            .format("MMMM YYYY")}</span>`
                    )
                }
            }

            if (/\[\!|\{\*/gi.test(formattedContent.content)) {
                /*const result = /\[\!([^\]]+)\]/gi.exec(formattedContent.content)
                console.log('result: ', result)*/
                // console.log('formattedContent: ', formattedContent.content)
                const matches = balanced.matches({
                    source: formattedContent.content,
                    open: ['[!', '{*', '['],
                    close: [']', '}', ']'],
                    balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                })

                const realMatches = matches.filter(o => o.head === '[!' && o.tail === ']')
                if (realMatches && realMatches.length) {
                    if (realMatches.length > 0) {
                        for (let i = 0; i < realMatches.length; i++) {
                            if (realMatches[i].head == '[!' && realMatches[i].tail === ']') {
                                let extract = formattedContent
                                    .content
                                    .substr(realMatches[i].index, realMatches[i].length)
                                    .trim()
                                    .substr(realMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - realMatches[i].tail.length)
                                /*console.log(
                                    'extract: ',
                                    extract
                                )*/
                                let listFromExtract = extract
                                    .split('!')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                // console.log('listFromExtract: ', listFromExtract)
                                if (listFromExtract.length > 0) {
                                    for (let j = 0; j < listFromExtract.length; j++) {
                                        const extractMatches = balanced.matches({
                                            source: listFromExtract[j],
                                            open: ['{*', '['],
                                            close: ['}', ']'],
                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                        })
                                        for (let k = 0; k < extractMatches.length; k++) {
                                            if (extractMatches[k].head == '{*' && extractMatches[k].tail === '}') {
                                                let extract2 = listFromExtract[j]
                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                    .trim()
                                                    .substr(extractMatches[k].head.length - 1)
                                                extract2 = extract2
                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                /* console.log(
                                                    'extract2: ',
                                                    extract2
                                                ) */
                                                const listFromExtract2 = extract2
                                                    .split('*')
                                                    .map(o => o.trim())
                                                    .filter(o => o)
                                                    .map(o => {
                                                        const extractMatches = balanced.matches({
                                                            source: o,
                                                            open: ['#$'],
                                                            close: ['#'],
                                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                                        })

                                                        for (let k = 0; k < extractMatches.length; k++) {
                                                            if (extractMatches[k].head == '#$' && extractMatches[k].tail === '#') {
                                                                let extract2 = o
                                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                                    .trim()
                                                                    .substr(extractMatches[k].head.length - 1)
                                                                extract2 = extract2
                                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                                const listFromExtract2 = extract2
                                                                    .split('$')
                                                                    .map(o => o.trim())
                                                                    .filter(o => o)

                                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                                o = o.replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                                            }
                                                        }

                                                        return o
                                                    })
                                                // console.log('listFromExtract2: ', listFromExtract2)
                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                listFromExtract[j] = listFromExtract[j].replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                            }
                                        }
                                    }
                                    const htmlList = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`
                                    // console.log('htmlList: ', htmlList)

                                    formattedContent.content = formattedContent.content.replaceBetween(realMatches[i].index, realMatches[i].length, htmlList)
                                }
                            }
                        }
                    }
                    if (realMatches.length === 0) {
                        const extractMatches = balanced.matches({
                            source: formattedContent.content,
                            open: ['{*', '['],
                            close: ['}', ']'],
                            balance: true, // optional (default: false) when set to true it will return `null` when there is an error
                        })
                        for (let i = 0; i < extractMatches.length; i++) {
                            if (extractMatches[i].head == '{*' && extractMatches[i].tail === '}') {
                                let extract = formattedContent
                                    .content
                                    .substr(extractMatches[i].index, extractMatches[i].length)
                                    .trim()
                                    .substr(extractMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - extractMatches[i].tail.length)
                                const listFromExtract = extract
                                    .split('*')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                    .map(o => {
                                        const extractMatches = balanced.matches({
                                            source: o,
                                            open: ['#$'],
                                            close: ['#'],
                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                        })

                                        for (let k = 0; k < extractMatches.length; k++) {
                                            if (extractMatches[k].head == '#$' && extractMatches[k].tail === '#') {
                                                let extract2 = o
                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                    .trim()
                                                    .substr(extractMatches[k].head.length - 1)
                                                extract2 = extract2
                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                const listFromExtract2 = extract2
                                                    .split('$')
                                                    .map(o => o.trim())
                                                    .filter(o => o)

                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                o = o.replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                            }
                                        }

                                        return o
                                    })
                                const htmlListFinal = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`
                                formattedContent.content = formattedContent.content.replaceBetween(extractMatches[i].index, extractMatches[i].length, htmlListFinal)
                            }
                        }
                    }
                }
            }

            structuredInformations[category].push(formattedContent)
        } else {
            // console.log("missing key: ", order[i])
        }
    }

    // console.log("structuredInformations :", structuredInformations)
    const finalStructuredInformations = cleanStructuredInformations(structuredInformations)

    return finalStructuredInformations
}

export const modelStatesPublicPageFetchV2 = async (
    type,
    stateCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false,
    refreshCacheSecond = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelStatesPublicPageFetchV2:${type}:${stateCode}:${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCacheSecond) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/public_pages/states`,
        {
            params: {
                type,
                state_code: stateCode
            }
        }
    )
    let finalStructuredInformations = res.data
    /* 
    const finalStructuredInformations = await modelStatesPublicPageFetchV2Process(
        type,
        stateCode,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    ) */

    redis.set(redisKey, JSON.stringify(finalStructuredInformations), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return finalStructuredInformations
}

const modelCountriesPublicPageFetchV2Process = async (
    type,
    countryLabel,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const content = await modelCountriesPublicPageBaseFetchV2(
        type,
        countryLabel,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    // console.log('content 2: ', content)

    if (!content) {
        return null
    }

    const adsDatas = await modelFranceAdsDatasPublicPageFetch(
        type,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    let adsDatasSecondary = null
    if (type === 'bureau-vente') {
        adsDatasSecondary = await modelFranceAdsDatasPublicPageFetch(
            'bureau',
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    let bodaccAggregates = null
    if (type === 'fonds-commerce') {
        bodaccAggregates = await modelBodaccPricesAggregateFetch(
            null,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
        // console.log('bodaccAggregates: ', bodaccAggregates)
    }
    const totalRealEstateAgentsCount = await getProfilesCount(
        [1],
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    let structuredInformations = {
        country_label: content.libelle,
        updated_at_ts: content.updated_at_ts,
        version: content.version
    }

    let passed = {}
    // console.log("content: ", content)

    for (let i = 0; i < order.length; i++) {
        // console.log("typeof: ", typeof content[order[i]])
        if (typeof content[order[i]] !== "undefined") {
            const keyInformations = order[i].split("_")
            const category = keyInformations[0]
            const value = content[order[i]]

            // console.log("category: ", category, type, number)
            // console.log("content: ", value)

            if (typeof structuredInformations[category] === "undefined") {
                structuredInformations[category] = []
                // console.log("created")
            }

            let formattedContent = {
                content: replaceNoDataConditions(value, order[i], type, adsDatas, bodaccAggregates),
                key: order[i]
            }

            if (/\[\!|\{\*/gi.test(formattedContent.content)) {
                /*const result = /\[\!([^\]]+)\]/gi.exec(formattedContent.content)
                console.log('result: ', result)*/
                // console.log('formattedContent: ', formattedContent.content)
                const matches = balanced.matches({
                    source: formattedContent.content,
                    open: ['[!', '{*', '['],
                    close: [']', '}', ']'],
                    balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                })

                const realMatches = matches.filter(o => o.head === '[!' && o.tail === ']')
                if (realMatches && realMatches.length) {
                    if (realMatches.length > 0) {
                        for (let i = 0; i < realMatches.length; i++) {
                            if (realMatches[i].head == '[!' && realMatches[i].tail === ']') {
                                let extract = formattedContent
                                    .content
                                    .substr(realMatches[i].index, realMatches[i].length)
                                    .trim()
                                    .substr(realMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - realMatches[i].tail.length)
                                /*console.log(
                                    'extract: ',
                                    extract
                                )*/
                                let listFromExtract = extract
                                    .split('!')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                // console.log('listFromExtract: ', listFromExtract)
                                if (listFromExtract.length > 0) {
                                    for (let j = 0; j < listFromExtract.length; j++) {
                                        const extractMatches = balanced.matches({
                                            source: listFromExtract[j],
                                            open: ['{*', '['],
                                            close: ['}', ']'],
                                            balance: false, // optional (default: false) when set to true it will return `null` when there is an error
                                        })
                                        for (let k = 0; k < extractMatches.length; k++) {
                                            if (extractMatches[k].head == '{*' && extractMatches[k].tail === '}') {
                                                let extract2 = listFromExtract[j]
                                                    .substr(extractMatches[k].index, extractMatches[k].length)
                                                    .trim()
                                                    .substr(extractMatches[k].head.length - 1)
                                                extract2 = extract2
                                                    .substr(0, extract2.length - extractMatches[k].tail.length)
                                                /* console.log(
                                                    'extract2: ',
                                                    extract2
                                                ) */
                                                const listFromExtract2 = extract2
                                                    .split('*')
                                                    .map(o => o.trim())
                                                    .filter(o => o)
                                                // console.log('listFromExtract2: ', listFromExtract2)
                                                const htmlListFinal = `<ul>${listFromExtract2.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`

                                                listFromExtract[j] = listFromExtract[j].replaceBetween(extractMatches[k].index, extractMatches[k].length, htmlListFinal)
                                            }
                                        }
                                    }
                                    const htmlList = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`
                                    // console.log('htmlList: ', htmlList)

                                    formattedContent.content = formattedContent.content.replaceBetween(realMatches[i].index, realMatches[i].length, htmlList)
                                }
                            }
                        }
                    }
                    if (realMatches.length === 0) {
                        const extractMatches = balanced.matches({
                            source: formattedContent.content,
                            open: ['{*', '['],
                            close: ['}', ']'],
                            balance: true, // optional (default: false) when set to true it will return `null` when there is an error
                        })
                        for (let i = 0; i < extractMatches.length; i++) {
                            if (extractMatches[i].head == '{*' && extractMatches[i].tail === '}') {
                                let extract = formattedContent
                                    .content
                                    .substr(extractMatches[i].index, extractMatches[i].length)
                                    .trim()
                                    .substr(extractMatches[i].head.length - 1)
                                extract = extract
                                    .substr(0, extract.length - extractMatches[i].tail.length)
                                const listFromExtract = extract
                                    .split('*')
                                    .map(o => o.trim())
                                    .filter(o => o)
                                const htmlListFinal = `<ul>${listFromExtract.map(o => `<li><span>${o}</span></li>`).join('')}</ul>`
                                formattedContent.content = formattedContent.content.replaceBetween(extractMatches[i].index, extractMatches[i].length, htmlListFinal)
                            }
                        }
                    }
                }
            }

            if (['local-commercial', 'local-commercial-vente'].indexOf(type) !== -1) {
                // [retail_pro_fra]
                formattedContent.content = formattedContent.content.replace(
                    /\[retail_pro_fra\]/g,
                    totalRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${totalRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['bureau', 'bureau-vente'].indexOf(type) !== -1) {
                // [office_pro_fra]
                formattedContent.content = formattedContent.content.replace(
                    /\[office_pro_fra\]/g,
                    totalRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${totalRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['entrepot', 'entrepot-vente'].indexOf(type) !== -1) {
                // [warehouse_pro_fra]
                formattedContent.content = formattedContent.content.replace(
                    /\[warehouse_pro_fra\]/g,
                    totalRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${totalRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['terrain'].indexOf(type) !== -1) {
                // [land_pro_fra]
                formattedContent.content = formattedContent.content.replace(
                    /\[land_pro_fra\]/g,
                    totalRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${totalRealEstateAgentsCount}</span>` : ''
                )
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                // [goodwill_pro_fra]
                formattedContent.content = formattedContent.content.replace(
                    /\[goodwill_pro_fra\]/g,
                    totalRealEstateAgentsCount > 0 ? `<span style="font-weight:500;">${totalRealEstateAgentsCount}</span>` : ''
                )
            }

            formattedContent.content = await replaceAllURLs(
                formattedContent.content,
                runtimeConfig,
                serverInstance,
                passed,
                { type, location_type: 'country', code: countryLabel },
                refreshCache
            )
            // formattedContent.content = await replaceDatas(formattedContent.content, serverInstance, passed)
            // console.log('formattedContent.content: ', formattedContent.content)

            if (adsDatas !== null) {
                // [start_month_publication]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_publication\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date_base * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                // [start_month_analysis]
                formattedContent.content = formattedContent.content.replace(
                    /\[start_month_analysis\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.start_date * 1000)
                        .locale("fr")
                        .format("MMMM YYYY")}</span>`
                )
                formattedContent.content = formattedContent.content.replace(
                    /\[first_day_current_month\]/g,
                    `<span style="font-weight:500;">${moment(adsDatas.created_at)
                        .locale("fr")
                        .format("Do MMMM YYYY")}</span>`
                )

                if (['local-commercial'].indexOf(type) !== -1) {
                    // [retail_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [retail_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [retail_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [retail_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [retail_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['local-commercial-vente'].indexOf(type) !== -1) {
                    // [retail_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [retail_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [retail_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [retail_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [retail_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[retail_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['bureau'].indexOf(type) !== -1) {
                    // [office_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [office_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [office_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )            
                    // [office_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [office_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                    // [office_leasing_average_space_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_min))}</span>`
                    )
                    // [office_leasing_average_space_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_space_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median_max))}</span>`
                    )
                    // [office_leasing_average_rent_year_minimum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_minimum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_min) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                    // [office_leasing_average_rent_year_maximum]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_leasing_average_rent_year_maximum\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median_max) * Math.round(adsDatas.lease_price_median) )))}</span>`
                    )
                }
                if (['bureau-vente'].indexOf(type) !== -1) {
                    // [office_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [office_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [office_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [office_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [office_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[office_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    if (adsDatasSecondary) {
                        // [office_leasing_ad_rolling_trim]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_ad_rolling_trim\]/g,
                            `<span style="font-weight:500;">${numberWithSpaces(adsDatasSecondary.lease_ads_count)}</span>`
                        )
                        // [office_leasing_average_rent_sqm]
                        formattedContent.content = formattedContent.content.replace(
                            /\[office_leasing_average_rent_sqm\]/g,
                            `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatasSecondary.lease_price_median))}</span>`
                        )
                    }
                }
                if (['coworking'].indexOf(type) !== -1) {
                    // [coworking_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [coworking_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[coworking_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [unit_price_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[unit_price_coworking\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [duree_engagement_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[duree_engagement_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_engagement_median !== null ? numberWithSpaces(adsDatas.duree_engagement_median) : 'N/A'}</span>`
                    )
                    // [depot_garantie_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[depot_garantie_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.depot_de_garantie_median !== null ? numberWithSpaces(adsDatas.depot_de_garantie_median) : 'N/A'}</span>`
                    )
                    // [frais_entree_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[frais_entree_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.frais_entree_median !== null ? numberWithSpaces(Math.round(adsDatas.frais_entree_median)) : 'N/A'}</span>`
                    )
                    // [preavis_coworking]
                    formattedContent.content = formattedContent.content.replace(
                        /\[preavis_coworking\]/g,
                        `<span style="font-weight:900;">${adsDatas.duree_preavis_median !== null ? numberWithSpaces(adsDatas.duree_preavis_median) : 'N/A'}</span>`
                    )
                }
                if (['entrepot'].indexOf(type) !== -1) {
                    // [warehouse_leasing_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count_base)}</span>`
                    )
                    // [warehouse_leasing_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.lease_ads_count)}</span>`
                    )
                    // [warehouse_leasing_average_rent_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_year]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_year\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median))}</span>`
                    )
                    // [warehouse_leasing_average_rent_month]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_leasing_average_rent_month\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(( Math.round(adsDatas.lease_surface_median) * Math.round(adsDatas.lease_price_median) ) / 12))}</span>`
                    )
                }
                if (['entrepot-vente'].indexOf(type) !== -1) {
                    // [warehouse_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [warehouse_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [warehouse_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [warehouse_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [warehouse_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[warehouse_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
                if (['terrain'].indexOf(type) !== -1) {
                    // [land_selling_ad_rolling_not_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count_base)}</span>`
                    )
                    // [land_selling_ad_rolling_trim]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_ad_rolling_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(adsDatas.sell_ads_count)}</span>`
                    )
                    // [land_selling_average_price_sqm]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price_sqm\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_price_median))}</span>`
                    )
                    // [land_selling_average_space]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_space\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median))}</span>`
                    )
                    // [land_selling_average_price]
                    formattedContent.content = formattedContent.content.replace(
                        /\[land_selling_average_price\]/g,
                        `<span style="font-weight:900;">${numberWithSpaces(Math.round(adsDatas.sell_surface_median) * Math.round(adsDatas.sell_price_median))}</span>`
                    )
                }
            }
            if (['fonds-commerce'].indexOf(type) !== -1) {
                if (bodaccAggregates !== null) {
                    formattedContent.content = formattedContent.content.replace(
                        /\[goodwill_selling_ad_rolling_not_trim\]/g,
                        `<span style="font-weight:500;">${numberWithSpaces(bodaccAggregates.total)}</span>`
                    )

                    formattedContent.content = formattedContent.content.replace(
                        /\[start_month_publication\]/g,
                        `<span style="font-weight:500;">${moment(bodaccAggregates.start_date)
                            .startOf('month')
                            .locale("fr")
                            .format("MMMM YYYY")}</span>`
                    )
                }
            }

            structuredInformations[category].push(formattedContent)
        } else {
            // console.log("missing key: ", order[i])
        }
    }

    // console.log("structuredInformations :", structuredInformations)
    const finalStructuredInformations = cleanStructuredInformations(structuredInformations)

    return finalStructuredInformations
}

export const modelCountriesPublicPageFetchV2 = async (
    type,
    countryLabel,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false,
    refreshCacheSecond = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelCountriesPublicPageFetchV2:${type}:${countryLabel}:${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCacheSecond) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const res = await instance.get(
        `${getConfig().publicRuntimeConfig.externalAPIEndpoint}/api/public_pages/countries`,
        {
            params: {
                type,
                country_label: countryLabel
            }
        }
    )
    let finalStructuredInformations = res.data
    /*
    const finalStructuredInformations = await modelCountriesPublicPageFetchV2Process(
        type,
        countryLabel,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    ) */

    redis.set(redisKey, JSON.stringify(finalStructuredInformations), 'EX', 60 * 60 * 24 * 2).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return finalStructuredInformations
}

const modelDepartmentsPublicPageBaseFetch = async (
    type,
    transactionType,
    departmentCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const contentPrunning = serverInstance && serverInstance.content_prunning ? serverInstance.content_prunning : null
    const locationURLsMatching = serverInstance && serverInstance.location_urls_matching ? serverInstance.location_urls_matching : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getDepartmentsPublicPage_${departmentCode}_${type}_${transactionType}_${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let content = JSON.parse(res)

        const typesMapping = {
            'local-commercial': 'local_commercial',
            'achat-fonds-commerce': 'fonds_de_commerce',
            'terrain': 'terrain'
        }

        if (locationURLsMatching) {
            const pattern = new RegExp('<a href="(\/(local-commercial|terrain)\/([^>\/]+)\/([^>\/]+)(\/achat\-fonds\-commerce|))">([^<>]+)</a>', 'gi')
            for (const key in content) {
                let result
                let locations = []
                while ((result = pattern.exec(content[key])) !== null) {
                    const code = locationURLsMatching[result[4]].code
                    const html = result[0]
                    const url = result[1]
                    const type = result[2] === 'local-commercial' ? result[5] === '' ? 'local-commercial' : 'achat-fonds-commerce' : result[2]
                    const anchor = result[6]
                    const prunningInfos = contentPrunning[code] ? contentPrunning[code] : null 
                    let prunning = false
                    if (prunningInfos) {
                        prunning = prunningInfos[typesMapping[type]] === 0 ? true : false
                    }
                    locations.push({ code, html, url, type, prunning, anchor })
                }
                for (let i = 0; i < locations.length; i++) {
                    if (locations[i].prunning) {
                        // Remove link (keep anchor text)
                        content[key] = content[key].replace(locations[i].html, locations[i].anchor)
                    }
                }
            }
        }

        return content
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const snapshot = await fi
        .database()
        .ref(
        `/public_pages_departments_contents/${departmentCode}_${type}_${transactionType}_${version}`
        )
        .once("value")

    let content = null
    if (snapshot.exists()) {
        content = snapshot.val()
    }

    redis.set(redisKey, JSON.stringify(content)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return content
}

export const modelDepartmentsPublicPageFetch = async (
    type,
    transactionType,
    departmentCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const content = await modelDepartmentsPublicPageBaseFetch(
        type,
        transactionType,
        departmentCode,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    if (!content) {
        return null
    }

    let structuredInformations = {
        bien: content.bien,
        code_departement: content.code_departement,
        transaction: content.transaction,
        updated_at_ts: content.updated_at_ts,
        version: content.version
    }

    // console.log("content: ", content)

    for (let i = 0; i < orderDepartment.length; i++) {
        // console.log("typeof: ", typeof content[orderDepartment[i]])
        if (typeof content[orderDepartment[i]] !== "undefined") {
            const keyInformations = orderDepartment[i].split("_")
            const category = keyInformations[0]
            const type = keyInformations[1]
            const number = keyInformations[2]
            const value = content[orderDepartment[i]]

            // console.log("category: ", category, type, number)
            // console.log("content: ", value)

            if (typeof structuredInformations[category] === "undefined") {
                structuredInformations[category] = []
                // console.log("created")
            }

            let formattedContent = {
                content: replaceNoDataConditions(value, category + "_" + type + "_" + number, type, adsDatas, bodaccAggregates),
                type,
                key: category + "_" + type + "_" + number
            }

            structuredInformations[category].push(formattedContent)
        } else {
            // console.log("missing key: ", orderDepartment[i])
        }
    }

    // console.log("structuredInformations :", structuredInformations)

    return structuredInformations
}

const modelStatesPublicPageBaseFetch = async (
    type,
    transactionType,
    stateCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const contentPrunning = serverInstance && serverInstance.content_prunning ? serverInstance.content_prunning : null
    const locationURLsMatching = serverInstance && serverInstance.location_urls_matching ? serverInstance.location_urls_matching : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getStatesPublicPage_${stateCode}_${type}_${transactionType}_${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        let content = JSON.parse(res)

        const typesMapping = {
            'local-commercial': 'local_commercial',
            'achat-fonds-commerce': 'fonds_de_commerce',
            'terrain': 'terrain'
        }

        if (locationURLsMatching) {
            const pattern = new RegExp('<a href="(\/(local-commercial|terrain)\/([^>\/]+)\/([^>\/]+)(\/achat\-fonds\-commerce|))">([^<>]+)</a>', 'gi')
            for (const key in content) {
                let result
                let locations = []
                while ((result = pattern.exec(content[key])) !== null) {
                    const code = locationURLsMatching[result[4]].code
                    const html = result[0]
                    const url = result[1]
                    const type = result[2] === 'local-commercial' ? result[5] === '' ? 'local-commercial' : 'achat-fonds-commerce' : result[2]
                    const anchor = result[6]
                    const prunningInfos = contentPrunning[code] ? contentPrunning[code] : null 
                    let prunning = false
                    if (prunningInfos) {
                        prunning = prunningInfos[typesMapping[type]] === 0 ? true : false
                    }
                    locations.push({ code, html, url, type, prunning, anchor })
                }
                for (let i = 0; i < locations.length; i++) {
                    if (locations[i].prunning) {
                        // Remove link (keep anchor text)
                        content[key] = content[key].replace(locations[i].html, locations[i].anchor)
                    }
                }
            }
        }

        return content
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const snapshot = await fi
        .database()
        .ref(
        `/public_pages_states_contents/${stateCode}_${type}_${transactionType}_${version}`
        )
        .once("value")

    let content = null
    if (snapshot.exists()) {
        content = snapshot.val()
    }

    redis.set(redisKey, JSON.stringify(content)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return content
}

export const modelStatesPublicPageFetch = async (
    type,
    transactionType,
    stateCode,
    version,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const content = await modelStatesPublicPageBaseFetch(
        type,
        transactionType,
        stateCode,
        version,
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    if (!content) {
        return null
    }

    let structuredInformations = {
        bien: content.bien,
        code_region: content.code_region,
        transaction: content.transaction,
        updated_at_ts: content.updated_at_ts,
        version: content.version
    }

    // console.log("content: ", content)

    for (let i = 0; i < orderState.length; i++) {
        // console.log("typeof: ", typeof content[orderDepartment[i]])
        if (typeof content[orderState[i]] !== "undefined") {
            const keyInformations = orderState[i].split("_")
            const category = keyInformations[0]
            const type = keyInformations[1]
            const number = keyInformations[2]
            const value = content[orderState[i]]

            // console.log("category: ", category, type, number)
            // console.log("content: ", value)

            if (typeof structuredInformations[category] === "undefined") {
                structuredInformations[category] = []
                // console.log("created")
            }

            let formattedContent = {
                content: replaceNoDataConditions(value, category + "_" + type + "_" + number, type, adsDatas, bodaccAggregates),
                type,
                key: category + "_" + type + "_" + number
            }

            structuredInformations[category].push(formattedContent)
        } else {
            // console.log("missing key: ", orderState[i])
        }
    }

    // console.log("structuredInformations :", structuredInformations)

    return structuredInformations
}

export const modelCitiesPublicPageInsees = async (
    type,
    version = 1,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const dbPublicPages = serverInstance ? serverInstance.dbPublicPages : null
    if (!dbPublicPages) return []
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `modelCitiesPublicPageInsees_${type}_${version}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const cursorPublicPages = await dbPublicPages
        .collection('cities_public_pages')
        .find({ type, version })

    let insees = []
    for await (const doc of cursorPublicPages) {
        insees.push(doc.code_insee)
    }

    redis.set(redisKey, JSON.stringify(insees)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return insees
}

export { getURLAnchor }