import firebaseInstance from "../config/firebase"
import _ from "lodash"
import hash from "object-hash"
import getConfig from "next/config"

import { modelProspectionPublicFetch } from "./Prospections"
import { modelGetProfileSimple } from "./Users"

import activityWordLink from "../datas/activityWordLink"
import departmentWordLink from "../datas/departmentWordLink"
import departments from "../datas/departments"
import diameters from "../datas/diameters"
import cos from "../datas/cos"

const modelGetTitleTemplate = async (
    id,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getTitleTemplate_${id}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const db = fi.firestore()
    const doc = await db
        .collection("titles_templates")
        .doc(id)
        .get()

    let value = null
    if (doc.exists) {
        value = doc.data()
    }

    redis.set(redisKey, JSON.stringify(value)).then(res => {
        // console.log('REDIS SET: ', res)
    }) 

    return value
}

const modelGetMessageTemplate = async (
    id,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getMessageTemplate_${id}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const db = fi.firestore()
    let doc = await db
        .collection("messages_templates")
        .doc(id)
        .get()

    let value = null
    if (doc.exists) {
        value = doc.data()
    }

    redis.set(redisKey, JSON.stringify(value)).then(res => {
        // console.log('REDIS SET: ', res)
    }) 

    return value
}

const modelGetTitlesTemplates = async (
    conditions = {},
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const objectHash = hash(conditions)
    const redisKey = `getTitlesTemplates_${objectHash}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const db = fi.firestore()

    let query = db.collection("titles_templates")
    for (let key in conditions) {
        query = query.where(key, "==", conditions[key])
    }

    let titlesTemplates = []

    const querySnapshot = await query.get()
    querySnapshot.forEach(doc => {
        titlesTemplates.push({ id: doc.id, data: doc.data() })
    })

    redis.set(redisKey, JSON.stringify(titlesTemplates)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return titlesTemplates
}

const modelGetMessagesTemplates = async (
    conditions = {},
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const objectHash = hash(conditions)
    const redisKey = `getMessagesTemplates_${objectHash}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const db = fi.firestore()
    let query = db.collection("messages_templates")
    for (let key in conditions) {
        query = query.where(key, "==", conditions[key])
    }

    let messagesTemplates = []

    const querySnapshot = await query.get()
    querySnapshot.forEach(doc => {
        messagesTemplates.push({ id: doc.id, data: doc.data() })
    })

    redis.set(redisKey, JSON.stringify(messagesTemplates)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return messagesTemplates
}

const modelGeneratePublicProspectionDescription = async (
    prospectionId,
    prospectionDatas = null,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let prospection = null
    let userinfo = null

    if (prospectionDatas === null) {
        prospection = await modelProspectionPublicFetch(
            "firestore",
            prospectionId,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
        prospection = { id: prospectionId, data: prospection[0] }
    } else {
        prospection = { id: prospectionId, data: prospectionDatas }
    }

    if (typeof prospection.data.userinfo !== "undefined") {
        userinfo = prospection.data.userinfo
    } else {
        userinfo = await modelGetProfileSimple(
            "realtime",
            prospection.data.user,
            serverInstance,
            refreshCache
        )
    }

    // console.log("prospection: ", prospection)
    // console.log("userinfo: ", userinfo)

    const leaseMatching = {
        unknown: 0,
        m2: 1,
        month: 1,
        year: 1
    }
    const priceMatching = {
        unknown: 2,
        max: 1,
        m2: 1,
        lease_only: 0
    }
    /*
        1 (porteur de projet) / 2 (indépendant) / 3 (enseigne)
        { label: "Porteur de projet", value: "3" },
        { label: "Indépendant", value: "1" },
        { label: "Enseigne", value: "2" }
    */
    const statusMatching = {
        1: 2,
        2: 3,
        3: 1
    }

    /* console.log("reference_id: ", prospection.data.reference_id)
    console.log("lease_attributes: ", prospection.data.lease_attributes)

    console.log("criterias:", {
        status: statusMatching[userinfo.status],
        is_commercial_space: !prospection.data.is_field ? 1 : 0,
        only_groundfloor:
            !prospection.data.is_field &&
            prospection.data.surface_attributes === "only_groundfloor"
                ? 1
                : 0,
        min_groundfloor_surface:
            prospection.data.surface_attributes !== "only_groundfloor" ? 1 : 0,
        rest_surface_access: prospection.data.rest_surface_access ? 1 : 0,
        max_lease: leaseMatching[prospection.data.lease_attributes],
        max_price: priceMatching[prospection.data.price_attributes],
        have_funding:
            userinfo.status === 2 ? 0 : prospection.data.funding ? 1 : 0,
        dont_have_funding:
            userinfo.status === 2 ? 0 : !prospection.data.funding ? 1 : 0,
        display_number: prospection.data.display_number ? 1 : 0
    }) */

    let messagesTemplates = []
    let transaction = null
  
    /*
      export default {
        1: 'local_commercial',
        2: 'fonds_de_commerce',
        3: 'popup_store',
        4: 'terrain',
        5: 'bureau',
        6: 'coworking',
        7: 'entrepot'
      }
    */
    if (
      prospection &&
      prospection.data &&
      prospection.data.type &&
      [ 1, 2, 4 ].indexOf(prospection.data.type) !== -1
    ) {
        messagesTemplates = await modelGetMessagesTemplates(
            {
                status: statusMatching[userinfo.status],
                is_commercial_space: !prospection.data.is_field ? 1 : 0,
                only_groundfloor:
                    !prospection.data.is_field &&
                    prospection.data.surface_attributes === "only_groundfloor"
                        ? 1
                        : 0,
                min_groundfloor_surface:
                    prospection.data.surface_attributes !== "only_groundfloor"
                        ? 1
                        : 0,
                rest_surface_access: prospection.data.rest_surface_access ? 1 : 0,
                max_lease: leaseMatching[prospection.data.lease_attributes],
                max_price: priceMatching[prospection.data.price_attributes],
                have_funding:
                    userinfo.status === 2 ? 0 : prospection.data.funding ? 1 : 0,
                dont_have_funding:
                    userinfo.status === 2 ? 0 : !prospection.data.funding ? 1 : 0,
                display_number: prospection.data.display_number ? 1 : 0
            },
            runtimeConfig,
            serverInstance,
            refreshCache
        )

        if (
            !prospection.data.is_field &&
            prospection.data.price_attributes === "lease_only"
        ) {
            transaction = 1
        }
        if (
            !prospection.data.is_field &&
            prospection.data.price_attributes !== "lease_only"
        ) {
            transaction = 2
        }
        if (prospection.data.is_field) {
            transaction = 3
        }
    }

    const titlesTemplates = await modelGetTitlesTemplates(
        { transaction },
        runtimeConfig,
        serverInstance,
        refreshCache
    )

    let titleTemplate = null
    if (titlesTemplates.length > 0) {
        const shuffledTitleList = _.shuffle(titlesTemplates)
        titleTemplate = shuffledTitleList[0]
    }

    let messageTemplate = null
    if (messagesTemplates.length > 0) {
        const shuffledList = _.shuffle(messagesTemplates)
        messageTemplate = shuffledList[0]
    }

    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    if (messageTemplate !== null || titleTemplate !== null) {
        await fi
            .firestore()
            .collection("prospection_public_descriptions")
            .doc(prospectionId)
            .set({
                template_id: messageTemplate ? messageTemplate.id : null,
                title_template_id: titleTemplate ? titleTemplate.id : null,
                updated_at_ts: Date.now()
            })
        await redis.del(`getProspectionDescription_${prospectionId}`)
    }

    return { title: titleTemplate, message: messageTemplate }
}

const fillTitleTemplate = (
    template,
    selectedLocation,
    prospectionDatas,
    userinfoDatas
) => {
    /*
    {activity} = <liaison activite titre>
    */
    let activityId =
        userinfoDatas.activityBrands || prospectionDatas.activity_type
    let activityLink = activityWordLink.filter(l => l.id === activityId)
    let activity = ""
    if (activityLink.length === 1) {
        activity = `${activityLink[0].title_linked_label}`
    }

    /*
    {location} = <à> <ville> <dans le> <numéro departement>
    */
    let location = ""
    if (selectedLocation.type === "departement") {
        location = `dans le ${selectedLocation.codeDepartement}`
    }
    if (
        selectedLocation.type === "commune" ||
        selectedLocation.type === "commune_all"
    ) {
        let name = ""
        if (selectedLocation.type === "commune_all") {
            name = selectedLocation.nom
        }
        if (selectedLocation.type === "commune") {
            if (
                ["Paris", "Marseille", "Lyon"].indexOf(selectedLocation.nom) !==
                -1
            ) {
                name = selectedLocation.libelle
                    .replace(/\(.+\)/g, "")
                    .replace(/er|ème/gi, "e")
            } else {
                name = selectedLocation.nom
            }
        }
        location = `à ${name}`
    }

    /*
    {location_type} = <emplacement ?>
    */
    let locationType = ""
    let locationList = []

    if (
        prospectionDatas.center ||
        prospectionDatas.number_one ||
        prospectionDatas.number_one_bis ||
        prospectionDatas.axe_passant
    ) {
        locationList.push("centre-ville")
    }
    if (prospectionDatas.suburb) {
        locationList.push("périphérie")
    }
    if (prospectionDatas.commercial_area) {
        locationList.push("retail park")
    }
    if (prospectionDatas.industrial_area) {
        locationList.push("ZI")
    }
    if (prospectionDatas.mall) {
        locationList.push("centre commercial")
    }
    if (prospectionDatas.galerie) {
        locationList.push("galerie marchande")
    }
    if (prospectionDatas.transportation) {
        locationList.push("gare")
    }

    if (locationList.length > 0) {
        locationType = locationList[0]
    }

    /*
    {surface} = <moyenne surface min et max>
    */
    let surface =
        prospectionDatas.rest_surface &&
        typeof prospectionDatas.rest_surface.max !== 'undefined' ?
            prospectionDatas.rest_surface.max : null

    template = template.replace(/\{activity\}/g, activity)
    template = template.replace(/\{location\}/g, location)
    template = template.replace(/\{location_type\}/g, locationType)
    if (surface) {
        template = template.replace(/\{surface\}/g, surface)
    }

    return template
}

const fillMessageTemplate = (
    template,
    selectedLocation,
    prospectionDatas,
    userinfoDatas
) => {
    /*
    {activity} = <liaison activité> <activité>
    */
    let activityId =
        userinfoDatas.activityBrands || prospectionDatas.activity_type
    let activityLink = activityWordLink.filter(l => l.id === activityId)
    let activity = ""
    if (activityLink.length === 1) {
        activity = `${activityLink[0].description_word_link} ${
            activityLink[0].label
        }`
    }

    /*
    {location} = <à ville> <<prefixe departement> <department>>
    */
    let location = ""
    if (selectedLocation.type === "departement") {
        location = departmentWordLink.filter(
            l =>
                l.department_code === parseInt(selectedLocation.codeDepartement)
        )
        if (location.length === 1) {
            let department = departments.filter(
                d => parseInt(d.code) === location[0].department_code
            )
            if (department.length === 1) {
                location = `${location[0].word_link} ${department[0].name}`
            }
        }
    }
    if (
        selectedLocation.type === "commune" ||
        selectedLocation.type === "commune_all"
    ) {
        let name = ""
        if (selectedLocation.type === "commune_all") {
            name = selectedLocation.nom
        }
        if (selectedLocation.type === "commune") {
            if (
                ["Paris", "Marseille", "Lyon"].indexOf(selectedLocation.nom) !==
                -1
            ) {
                name = selectedLocation.libelle
                    .replace(/\(.+\)/g, "")
                    .replace(/er|ème/gi, "e")
            } else {
                name = selectedLocation.nom
            }
        }
        location = `à ${name}`
    }

    /*
    {lease} = <loyer maximum de <loyer max> € par <mois> hors charges et hors taxes>, <{lease}> <je n’ai pas de limite de budget pour le loyer tout dépendra du taux d'effort/rentabilité>
    */
    let lease = ""
    if (prospectionDatas.lease_attributes !== "unknown") {
        lease = `Loyer maximum de ${prospectionDatas.lease} €`
        if (prospectionDatas.lease_attributes === "m2") {
            lease += " le m² par an"
        }
        if (prospectionDatas.lease_attributes === "month") {
            lease += " par mois"
        }
        if (prospectionDatas.lease_attributes === "year") {
            lease += " par an"
        }
        lease += " hors charges et hors taxes"
    }
    if (prospectionDatas.lease_attributes === "unknown") {
        lease =
            "je n’ai pas de limite de budget pour le loyer tout dépendra du taux d'effort/rentabilité"
    }

    /*
    {price} = <prix maximum de <prix max> € par <mois>>, {price} <le prix n'est pas un frein et sera étudié en fonction du potentiel de la zone de chalandise>
    */
    let price = ""
    if (
        prospectionDatas.price_attributes !== "unknown" &&
        prospectionDatas.price_attributes !== "lease_only"
    ) {
        price += `prix maximum de ${prospectionDatas.price} €`
    }
    if (prospectionDatas.price_attributes === "m2") {
        price += " le m²"
    }
    if (prospectionDatas.price_attributes === "unknown") {
        price =
            "le prix n'est pas un frein et sera étudié en fonction du potentiel de la zone de chalandise"
    }

    /*
    {location_type} = <centre-ville> <(<emplacement n 1>, <emplacement n 1 bis>, <axe passant voiture>)>, <périphérie>, <zone d'activité commerciale / retail park>, <zone industrielle>, <centre commercial>, <galerie marchande> <ou> <gare>
    */
    let locationType = ""
    let locationList = []

    let centerDetails = []
    if (prospectionDatas.number_one) {
        centerDetails.push("N°1")
    }
    if (prospectionDatas.number_one_bis) {
        centerDetails.push("N°1 bis")
    }
    if (prospectionDatas.axe_passant) {
        centerDetails.push("axe passant voiture")
    }

    if (prospectionDatas.center) {
        let locationCenter = "centre-ville"
        if (centerDetails.length > 0) {
            locationCenter += ` (${centerDetails.join(", ")})`
        }
        locationList.push(locationCenter)
    }

    if (prospectionDatas.suburb) {
        locationList.push("périphérie")
    }
    if (prospectionDatas.commercial_area) {
        locationList.push("zone d'activité commerciale / retail park")
    }
    if (prospectionDatas.industrial_area) {
        locationList.push("zone industrielle")
    }
    if (prospectionDatas.mall) {
        locationList.push("centre commercial")
    }
    if (prospectionDatas.galerie) {
        locationList.push("galerie marchande")
    }
    if (prospectionDatas.transportation) {
        locationList.push("gare")
    }

    if (locationList.length > 0) {
        for (let i = 0; i < locationList.length; i++) {
            locationType +=
                (i > 0 ? (i === locationList.length - 1 ? " ou " : ", ") : "") +
                locationList[i]
        }
    }

    /*
    {min_rest_surface} = <surface total minimum>
    */
    let minRestSurface =
        prospectionDatas.rest_surface &&
        typeof prospectionDatas.rest_surface.min !== 'undefined' ?
            prospectionDatas.rest_surface.min : null

    /*
    {max_rest_surface} = <surface total maximum>
    */
    let maxRestSurface =
        prospectionDatas.rest_surface &&
        typeof prospectionDatas.rest_surface.max !== 'undefined' ?
            prospectionDatas.rest_surface.max : null

    /*
    {min_groundfloor_surface} = <surface minimum rez-de-chaussée>
    */
    let minGroundfloorSurface =
        prospectionDatas.groundfloor_surface &&
        typeof prospectionDatas.groundfloor_surface.min !== 'undefined' ?
            prospectionDatas.groundfloor_surface.min : null

    /*
    {criterias} = <Les critères obligatoires pour le fonctionnement du concept sont :> <la licence 4 (ou possibilité d'en installer une)>, <un espace extérieur ou terrasse>, <une extraction présente (ou possible) d'un diamètre minimum de <diamètre de l'extraction> millimètres pour évacuer du local les odeurs et fumées liées à mon activité>, <un accès pour personnes à mobilité réduite (PMR)>, <un parking à proximité ou privatif> <.> 
    */
    let criterias = ""
    let criteriaList = []
    if (prospectionDatas.required_license_4) {
        criteriaList.push("la licence 4 (ou possibilité d'en installer une)")
    }
    if (prospectionDatas.parking) {
        criteriaList.push("un espace extérieur ou terrasse")
    }
    if (prospectionDatas.allowed_catering_industry) {
        criteriaList.push(
            `une extraction présente (ou possible) d'un diamètre minimum de ${
                diameters[prospectionDatas.diameter]
            } millimètres pour évacuer du local les odeurs et fumées liées à mon activité`
        )
    }
    if (prospectionDatas.pmr) {
        criteriaList.push("un accès pour personnes à mobilité réduite (PMR)")
    }
    if (prospectionDatas.close_parking) {
        criteriaList.push("un parking à proximité ou privatif")
    }

    if (criteriaList.length > 0) {
        criterias =
            criteriaList.length > 1
                ? "Les critères obligatoires pour le fonctionnement du concept sont : "
                : "Le critère obligatoire pour le fonctionnement du concept est : "
        for (let i = 0; i < criteriaList.length; i++) {
            criterias +=
                (i > 0 ? (i === criteriaList.length - 1 ? " et " : ", ") : "") +
                criteriaList[i]
        }
        criterias += "."
    }

    /*
    {facade_length} = <longeur de facade>
    */
    let facadeLength =
        prospectionDatas.facade_length >= 100
            ? "100+"
            : prospectionDatas.facade_length

    /*
    {height_length} = <hauteur sous plafond>
    */
    let heightLength = prospectionDatas.height_length

    /*
    {cos} = <cos>
    */
    let cosValue = cos[prospectionDatas.cos]

    /*
    {min_building_height} =  <<La hauteur minimale du futur bâtiment sera de <hauteur futur batiment> mètre.>
    */
    let minBuildingHeight = ""
    if (_.isNumber(prospectionDatas.min_building_height)) {
        minBuildingHeight = `La hauteur minimale du futur bâtiment sera de ${
            prospectionDatas.min_building_height
        } mètre.`
    }

    template = template.replace(/\{activity\}/g, activity)
    template = template.replace(/\{location\}/g, location)
    template = template.replace(/\{lease\}/g, lease)
    template = template.replace(/\{price\}/g, price)
    template = template.replace(/\{location_type\}/g, locationType)
    if (minRestSurface) {
        template = template.replace(/\{min_rest_surface\}/g, minRestSurface)
    }
    if (maxRestSurface) {
        template = template.replace(/\{max_rest_surface\}/g, maxRestSurface)
    }
    if (minGroundfloorSurface) {
        template = template.replace(
            /\{min_groundfloor_surface\}/g,
            minGroundfloorSurface
        )
    }
    template = template.replace(/\{criterias\}/g, criterias)
    template = template.replace(/\{facade_length\}/g, facadeLength)
    template = template.replace(/\{height_length\}/g, heightLength)
    template = template.replace(/\{cos\}/g, cosValue)
    template = template.replace(/\{min_building_height\}/g, minBuildingHeight)

    template = template.replace(
        "La longueur de façade devra être minimum de 0 mètres et une hauteur",
        "Une hauteur"
    )
    template = template.replace(
        " avec un linéaire de vitrine/façade de minimum 0 m de long",
        ""
    )
    template = template.replace(
        ", concernant la longueur de façade nous avons besoin d'un minimum de 0 m",
        ""
    )
    template = template.replace(
        " La hauteur minimale du futur bâtiment sera de 0 mètre.",
        ""
    )
    template = template.replace(
        " La hauteur sous plafond minimum recherchée est de 0 m, concernant la longeur de façade nous avons besoin d'un minimum de 0 m.",
        ""
    )
    template = template.replace(
        "La hauteur sous plafond minimum recherchée est de 0 m, ",
        ""
    )
    template = template.replace(
        ", concernant la longeur de façade nous avons besoin d'un minimum de 0 m",
        ""
    )
    template = template.replace(
        " La hauteur sous plafond idéale devra être de 0 mètres minimum avec un linéaire de vitrine/façade de minimum 0 m de long.",
        ""
    )
    template = template.replace(
        " avec un linéaire de vitrine/façade de minimum 0 m de long",
        ""
    )
    template = template.replace(
        "La hauteur sous plafond idéale devra être de 0 mètres minimum avec ",
        ""
    )

    return template
}

const modelGetProspectionDescriptionBase = async (
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getProspectionDescription_${prospectionId}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)
        
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR: ', error)
    }

    const db = fi.firestore()
    let doc = await db
        .collection("prospection_public_descriptions")
        .doc(prospectionId)
        .get()

    let template = null
    if (doc.exists) {
        template = doc.data()
    }

    redis.set(redisKey, JSON.stringify(template)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return template
}

const modelGetProspectionDescription = async (
    prospectionId,
    selectedLocationId,
    prospectionDatas = null,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let prospection = null
    let userinfo = null
    if (prospectionDatas === null) {
        try {
            prospection = await modelProspectionPublicFetch(
                "firestore",
                prospectionId,
                runtimeConfig,
                serverInstance,
                refreshCache
            )
            prospection = { id: prospectionId, data: prospection[0] }
        } catch (e) {
            return false
        }
    } else {
        prospection = { id: prospectionId, data: prospectionDatas }
    }

    if (typeof prospection.data.userinfo !== "undefined") {
        userinfo = prospection.data.userinfo
    } else {
        userinfo = await modelGetProfileSimple(
            "realtime",
            prospection.data.user,
            serverInstance,
            refreshCache
        )
        prospection.data.userinfo = userinfo
    }

    let selectedLocation = prospection.data.locations.filter(
        l => l.id === selectedLocationId
    )
    if (selectedLocation.length === 0) {
        return false
    }
    selectedLocation = selectedLocation[0]

    let tplInfos = await modelGetProspectionDescriptionBase(
        prospectionId,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    let template = null
    if (tplInfos) {
        const informations = await Promise.all([
            tplInfos.template_id
                ? modelGetMessageTemplate(
                      tplInfos.template_id,
                      runtimeConfig,
                      serverInstance,
                      refreshCache
                  )
                : Promise.resolve(null),
            tplInfos.title_template_id
                ? modelGetTitleTemplate(
                      tplInfos.title_template_id,
                      runtimeConfig,
                      serverInstance,
                      refreshCache
                  )
                : Promise.resolve(null)
        ])
        template = {
            message: {
                id: tplInfos.template_id,
                data: informations[0]
            },
            title: {
                id: tplInfos.title_template_id,
                data: informations[1]
            }
        }
    } else {
        template = await modelGeneratePublicProspectionDescription(
            prospection.id,
            prospection.data,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }

    const title = fillTitleTemplate(
        template.title !== null &&
        template.title.data !== null &&
        template.title.data.title !== null
            ? template.title.data.title
            : "N/A",
        selectedLocation,
        prospection.data,
        userinfo
    )
    const message = fillMessageTemplate(
        template.message !== null &&
        template.message.data !== null &&
        template.message.data.message !== null
            ? template.message.data.message
            : "N/A",
        selectedLocation,
        prospection.data,
        userinfo
    )

    return { title, message }
}

export {
    fillTitleTemplate,
    fillMessageTemplate,
    modelGetTitleTemplate,
    modelGetTitlesTemplates,
    modelGetMessageTemplate,
    modelGetMessagesTemplates,
    modelGeneratePublicProspectionDescription,
    modelGetProspectionDescription
}