import _ from "lodash"

import priceMapping from "../datas/PriceMapping"
import activities from "../datas/Activities"
import PlaceTypes from "../datas/PlaceTypes"

const buildFilters = props => {
    let filters = {}

    // let minPrice = priceMapping[props.price.min]
    // let maxPrice = priceMapping[props.price.max]

    // let minLease = props.lease.min
    // let maxLease = props.lease.max === 21000 ? "+" : props.lease.max

    // filters.minPrice = minPrice
    // filters.maxPrice = maxPrice

    // filters.minLease = minLease
    // filters.maxLease = maxLease

    // filters.minRDC = props.rdc.min
    // filters.maxRDC = props.rdc.max

    filters.minTotal = props.total.min
    filters.maxTotal = props.total.max

    if (props.reference.trim().length === 4) {
        filters.reference = props.reference.trim()
    }

    if (props.transaction) {
        filters.transaction = props.transaction
    }

    if (props.activity) {
        let activity = activities.filter(o => o.seo === props.activity)
        if (activity.length === 1) {
            filters.activity = activity[0].key
        }
    }

    let typeFilter = [...props.type_filter]
    if (props.place) {
        let place = PlaceTypes.filter(o => o.seo === props.place)
        if (place.length === 1) {
            typeFilter.push(place[0].key)
        }
    }
    if (props.type) {
        filters.type = props.type
        if (props.type === "terrain") {
            typeFilter.push(2)
        }
        if (props.type === "local-commercial") {
            typeFilter.push(27)
        }
    }
    filters.typeFilter = typeFilter

    return filters
}

const buildFiltersFirestore = props => {
    let filters = []

    if (props.type) {
        /*
            1: 'local_commercial',
            2: 'fonds_de_commerce',
            3: 'popup_store',
            4: 'terrain',
            5: 'bureau',
            6: 'coworking',
            7: 'entrepot',
            8: 'investissement',
            9: 'fonds_de_commerce_v2'
        */
        let types = _.isArray(props.type) ? props.type : [ props.type ]
        types = types
            .map(t => {
                if (t === 'local-commercial') {
                    return 1
                }
                if (t === 'local-commercial-vente') {
                    return 11
                }
                if (t === 'fonds-commerce') {
                    return 2
                }
                if (t === 'popup-store') {
                    return 31
                }
                if (t === 'terrain') {
                    return 4
                }
                if (t === 'bureau') {
                    return 51
                }
                if (t === 'bureau-vente') {
                    return 51
                }
                if (t === 'coworking') {
                    return 61
                }
                if (t === 'entrepot') {
                    return 71
                }
                if (t === 'entrepot-vente') {
                    return 71
                }
                if (t === 'investissement') {
                    return 81
                }
                return null
            })
            .filter(o => o)

        if (types.length > 0) {
            filters.push(
                {
                    key: 'search_tags.prospection_type',
                    operator: 'in',
                    value: types
                }
            )
        }
    }

    if (props.place) {
        let place = PlaceTypes.filter(o => o.seo === props.place)
        if (place.length === 1) {
            filters.push({ key: `search_tags.places.${place[0].key}`, operator: '==', value: true }) 
        }
    }

    if (props.activity) {
        let activity = activities.filter(o => o.seo === props.activity)
        if (activity.length === 1) {
            filters.push({ key: `search_tags.activity_type`, operator: '==', value: activity[0].key }) 
        }
    }

    if (props.reference && props.reference.trim().length === 4) {
        filters.push({ key: 'search_tags.reference_id', operator: '==', value: props.reference.trim() })
    }

    return filters
}

export { buildFilters, buildFiltersFirestore }
