const specialCaseMapping = {
    "75056_75001": "75101",
    "75056_75002": "75102",
    "75056_75003": "75103",
    "75056_75004": "75104",
    "75056_75005": "75105",
    "75056_75006": "75106",
    "75056_75007": "75107",
    "75056_75008": "75108",
    "75056_75009": "75109",
    "75056_75010": "75110",
    "75056_75011": "75111",
    "75056_75012": "75112",
    "75056_75013": "75113",
    "75056_75014": "75114",
    "75056_75015": "75115",
    "75056_75016": "75116",
    "75056_75017": "75117",
    "75056_75018": "75118",
    "75056_75019": "75119",
    "75056_75020": "75120",
    "13055_13001": "13201",
    "13055_13002": "13202",
    "13055_13003": "13203",
    "13055_13004": "13204",
    "13055_13005": "13205",
    "13055_13006": "13206",
    "13055_13007": "13207",
    "13055_13008": "13208",
    "13055_13009": "13209",
    "13055_13010": "13210",
    "13055_13011": "13211",
    "13055_13012": "13212",
    "13055_13013": "13213",
    "13055_13014": "13214",
    "13055_13015": "13215",
    "13055_13016": "13216",
    "69123_69001": "69381",
    "69123_69002": "69382",
    "69123_69003": "69383",
    "69123_69004": "69384",
    "69123_69005": "69385",
    "69123_69006": "69386",
    "69123_69007": "69387",
    "69123_69008": "69388",
    "69123_69009": "69389"
}

export default specialCaseMapping