import axios from "../config/axios"
import getConfig from "next/config"

import {
    AGENCY_SEND,
    AGENCY_SENT,
    AGENCY_SEND_ERROR,
    AGENCY_IDENTITY_CHANGED,
    AGENCY_EMAIL_CHANGED,
    AGENCY_PHONE_CHANGED,
    AGENCY_MESSAGE_CHANGED,
    AGENCY_PROJECT_CHANGED,
    AGENCY_LOCATION_CHANGED,
    SEND_AGENCY_FORM_RESET,
    SEND_AGENCY_FORM_FIELDS_RESET,
    SEND_AGENCY_FORM_SENT_RESET,
    SEND_AGENCY_FORM_RESULT_POPUP_OPEN,
    SEND_AGENCY_FORM_RESULT_POPUP_CLOSE,
    AGENCY_PRIVACY_POLICY_TOGGLED
} from "./types"

export const sendAgencyFormReset = () => {
    return {
        type: SEND_AGENCY_FORM_RESET
    }
}

export const sendAgencyFormFieldsReset = () => {
    return {
        type: SEND_AGENCY_FORM_FIELDS_RESET
    }
}

export const sendAgencyFormSentReset = () => {
    return {
        type: SEND_AGENCY_FORM_SENT_RESET
    }
}

export const sendAgencyFormOpen = () => {
    return {
        type: SEND_AGENCY_FORM_RESULT_POPUP_OPEN
    }
}

export const sendAgencyFormClose = () => {
    return {
        type: SEND_AGENCY_FORM_RESULT_POPUP_CLOSE
    }
}

export const sendEmailAgency = (agencyId, identity, email, phone, message, project, location, pageData = {}, callback = null) => {
    return dispatch => {
        dispatch({ type: AGENCY_SEND })

        const instance = axios.create({
            baseURL:
                getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
            timeout: 30000
        })

        instance
            .post("/send_agency_message", {
                agency_id: agencyId,
                identity,
                email,
                phone,
                message,
                project,
                location,
                data: pageData
            })
            .then(res => {
                dispatch({ type: AGENCY_SENT })
                if (callback) {
                    callback(res)
                }
            })
            .catch(error => {
                dispatch({ type: AGENCY_SEND_ERROR, payload: error })
            })
    }
}

export const onIdentityChange = value => {
    return {
        type: AGENCY_IDENTITY_CHANGED,
        payload: value
    }
}

export const onEmailChange = value => {
    return {
        type: AGENCY_EMAIL_CHANGED,
        payload: value
    }
}

export const onPhoneChange = value => {
    return {
        type: AGENCY_PHONE_CHANGED,
        payload: value
    }
}

export const onMessageChange = value => {
    return {
        type: AGENCY_MESSAGE_CHANGED,
        payload: value
    }
}

export const onProjectChange = value => {
    return {
        type: AGENCY_PROJECT_CHANGED,
        payload: value
    }
}

export const onLocationChange = value => {
    return {
        type: AGENCY_LOCATION_CHANGED,
        payload: value
    }
}

export const onPrivacyPolicyToggle = () => {
    return {
        type: AGENCY_PRIVACY_POLICY_TOGGLED
    }
}