import _ from "lodash"
import hash from "object-hash"
import Prismic from "prismic-javascript"
import getConfig from "next/config"

import { Client } from "../helpers/prismic"
import axios from "../config/axios"
import firebaseInstance from "../config/firebase"

const instance = axios.create({
    baseURL: "/api",
    timeout: 30000
})

const modelGetGuideMasterRef = async (
    req,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const api = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).getApi()

    const masterRef = api.master()

    return masterRef
}

const modelGetCurrentRef = async req => {
    const previewRef = req.cookies[Prismic.previewCookie]
    const masterRef = await modelGetGuideMasterRef(
        req,
        req.config.serverRuntimeConfig,
        { redis: req.redis, redisTagsGet: req.redisTagsGet, redisTags: req.redisTags }
    )
    const ref = previewRef || masterRef

    return Buffer.from(ref).toString('base64')
}

const modelGetGuide = async (
    req,
    guideName,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetGuide_${guideName}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const doc = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).getSingle(guideName)

    redisTags.set(
        redisKey,
        doc,
        [`guide_${doc.id}`, 'prismic'],
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return doc
}

const modelGetGuideCategories = async (
    req,
    guideId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetGuideCategories_${guideId}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const firstCategories = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).query(
        [
            Prismic.Predicates.at("document.type", "category"),
            Prismic.Predicates.at("my.category.guide", guideId)
        ],
        { orderings: "[my.category.order]", pageSize: 100 }
    )
    let totalCategories = [ ...firstCategories.results ]
    if (firstCategories.total_pages > 1) { 
        for (let i = 2; i <= firstCategories.total_pages; i++) {
            const res = await Client(
                runtimeConfig.prismicApiEndpoint,
                runtimeConfig.prismicAccessToken,
                req
            ).query(
                [
                    Prismic.Predicates.at("document.type", "category"),
                    Prismic.Predicates.at("my.category.guide", guideId)
                ],
                { page: i, orderings: "[my.category.order]", pageSize: 100 }
            )
            totalCategories = [ ...totalCategories, ...res.results ]
        }
    }

    const tags = [
        ...totalCategories.map(category => {
            return `category_${category.id}`
        }),
        'prismic'
    ]

    redisTags.set(
        redisKey,
        totalCategories,
        tags,
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return totalCategories
}

const modelGetSubcategoriesByIds = async (
    req,
    ids,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    let sortedIds = [ ...ids ]
    sortedIds.sort()
    const objectHash = hash(sortedIds)
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetSubcategoriesByIds_${objectHash}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const firstSubcategories = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).query(
        [
            Prismic.Predicates.at("document.type", "subcategory"),
            Prismic.Predicates.any("my.subcategory.category", ids)
        ],
        { orderings: "[my.subcategory.category, my.subcategory.order]", pageSize: 100 }
    )
    let totalSubcategories = [ ...firstSubcategories.results ]
    if (firstSubcategories.total_pages > 1) { 
        for (let i = 2; i <= firstSubcategories.total_pages; i++) {
            const res = await Client(
                runtimeConfig.prismicApiEndpoint,
                runtimeConfig.prismicAccessToken,
                req
            ).query(
                [
                    Prismic.Predicates.at("document.type", "subcategory"),
                    Prismic.Predicates.any("my.subcategory.category", ids)
                ],
                { page: i, orderings: "[my.subcategory.category, my.subcategory.order]", pageSize: 100 }
            )
            totalSubcategories = [ ...totalSubcategories, ...res.results ]
        }
    }

    const tags = [
        ...totalSubcategories.map(subcategory => {
            return `subcategory_${subcategory.id}`
        }),
        'prismic'
    ]

    redisTags.set(
        redisKey,
        totalSubcategories,
        tags,
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return totalSubcategories
}

const modelGetSubcategoriesByCategoryId = async (
    req,
    categoryId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetSubcategoriesByCategoryId_${categoryId}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const firstSubcategories = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).query(
        [
            Prismic.Predicates.at("document.type", "subcategory"),
            Prismic.Predicates.at("my.subcategory.category", categoryId)
        ],
        { orderings: "[my.subcategory.category, my.subcategory.order]", pageSize: 100 }
    )
    let totalSubcategories = [ ...firstSubcategories.results ]
    if (firstSubcategories.total_pages > 1) { 
        for (let i = 2; i <= firstSubcategories.total_pages; i++) {
            const res = await Client(
                runtimeConfig.prismicApiEndpoint,
                runtimeConfig.prismicAccessToken,
                req
            ).query(
                [
                    Prismic.Predicates.at("document.type", "subcategory"),
                    Prismic.Predicates.at("my.subcategory.category", categoryId)
                ],
                { page: i, orderings: "[my.subcategory.category, my.subcategory.order]", pageSize: 100 }
            )
            totalSubcategories = [ ...totalSubcategories, ...res.results ]
        }
    }

    const tags = [
        ...totalSubcategories.map(subcategory => {
            return `subcategory_${subcategory.id}`
        }),
        'prismic'
    ]

    redisTags.set(
        redisKey,
        totalSubcategories,
        tags,
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return totalSubcategories
}

const modelGetArticlesByCategoriesIds = async (
    req,
    categoriesIds,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst

    let sortedIds = [ ...categoriesIds ]
    sortedIds.sort()
    const currentRef = await modelGetCurrentRef(req)
    const objectHash = hash(sortedIds)
    const redisKey = `prismic:modelGetArticlesByCategoriesIds_${objectHash}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const firstArticles = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).query(
        [
            Prismic.Predicates.at("document.type", "article"),
            Prismic.Predicates.any("my.article.category", categoriesIds)
        ],
        { orderings: "[my.article.category, my.article.order]", pageSize: 100 }
    )
    let totalArticles = [ ...firstArticles.results ]
    if (firstArticles.total_pages > 1) { 
        for (let i = 2; i <= firstArticles.total_pages; i++) {
            const res = await Client(
                runtimeConfig.prismicApiEndpoint,
                runtimeConfig.prismicAccessToken,
                req
            ).query(
                [
                    Prismic.Predicates.at("document.type", "article"),
                    Prismic.Predicates.any("my.article.category", categoriesIds)
                ],
                { page: i, orderings: "[my.article.category, my.article.order]", pageSize: 100 }
            )
            totalArticles = [ ...totalArticles, ...res.results ]
        }
    }

    const tags = [
        ...totalArticles.map(article => {
            return `article_${article.id}`
        }),
        'prismic'
    ]

    redisTags.set(
        redisKey,
        totalArticles,
        tags,
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return totalArticles
}

const modelGetArticlesByCategoryId = async (
    req,
    categoryId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetArticlesByCategoryId_${categoryId}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const firstArticles = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).query(
        [
            Prismic.Predicates.at("document.type", "article"),
            Prismic.Predicates.at("my.article.category", categoryId)
        ],
        { orderings: "[my.article.order]", pageSize: 100 }
    )
    let totalArticles = [ ...firstArticles.results ]
    if (firstArticles.total_pages > 1) { 
        for (let i = 2; i <= firstArticles.total_pages; i++) {
            const res = await Client(
                runtimeConfig.prismicApiEndpoint,
                runtimeConfig.prismicAccessToken,
                req
            ).query(
                [
                    Prismic.Predicates.at("document.type", "article"),
                    Prismic.Predicates.at("my.article.category", categoryId)
                ],
                { page: i, orderings: "[my.article.order]", pageSize: 100 }
            )
            totalArticles = [ ...totalArticles, ...res.results ]
        }
    }

    const tags = [
        ...totalArticles.map(article => {
            return `article_${article.id}`
        }),
        'prismic'
    ]

    redisTags.set(
        redisKey,
        totalArticles,
        tags,
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return totalArticles
}

const modelGetCategoryByUID = async (
    req,
    categoryUID,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetCategoryByUID_${categoryUID}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const category = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).getByUID(
        "category",
        categoryUID
    )

    redisTags.set(
        redisKey,
        category,
        [`category_${category.id}`, 'prismic'],
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return category
}

const modelGetSubcategoryByUID = async (
    req,
    subcategoryUID,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetSubcategoryByUID_${subcategoryUID}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const subcategory = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).getByUID(
        "subcategory",
        subcategoryUID
    )

    redisTags.set(
        redisKey,
        subcategory,
        [`subcategory_${subcategory.id}`, 'prismic'],
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return subcategory
}

const modelGetArticleByUID = async (
    req,
    articleUID,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetArticleByUID_${articleUID}_${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const article = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).getByUID(
        "article",
        articleUID
    )

    redisTags.set(
        redisKey,
        article,
        [`article_${article.id}`, 'prismic'],
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return article
}

const modelGuideMarkValidate = async (id, mark) => {
    const res = await instance.post("/gm", {
        id,
        mark
    })

    return res.data
}

const modelGuideMarkFetch = async (
    id,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseSecondInst = serverInstance && serverInstance.firebaseSecond ? serverInstance.firebaseSecond : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi = firebaseSecondInst
    const redis = redisInst
    const redisKey = `getGuideMark_${id}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    let doc = await fi
        .firestore()
        .collection("guide_aggregate_mark")
        .doc(id)
        .get()

    let mark = null
    if (doc.exists) {
        mark = doc.data()
    }

    redis.set(redisKey, JSON.stringify(mark)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return mark
}

const modelGetEntityByID = async (
    req,
    entityID,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redisTagsGetInst = serverInstance && serverInstance.redisTagsGet ? serverInstance.redisTagsGet : null
    const redisTagsInst = serverInstance && serverInstance.redisTags ? serverInstance.redisTags : null
    const redis = redisInst
    const redisTagsGet = redisTagsGetInst
    const redisTags = redisTagsInst
    const currentRef = await modelGetCurrentRef(req)
    const redisKey = `prismic:modelGetEntityByID:${entityID}:${currentRef}`

    if (refreshCache) {
        await redisTagsGet.del(redisKey)
    }

    try {
        const res = await redisTagsGet.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const entity = await Client(
        runtimeConfig.prismicApiEndpoint,
        runtimeConfig.prismicAccessToken,
        req
    ).getByID(
        entityID
    )

    redisTags.set(
        redisKey,
        entity,
        [`entity_${entity.id}`, 'prismic'],
        { /* timeout: 604800 */ /* Cache for a week */ }
    ).then(res => {
        // console.log('REDIS TAGS SET: ', redisKey)
    })

    return entity
}

export {
    modelGetGuideMasterRef,
    modelGetGuide,
    modelGetGuideCategories,
    modelGetSubcategoriesByIds,
    modelGetSubcategoriesByCategoryId,
    modelGetArticlesByCategoriesIds,
    modelGetArticlesByCategoryId,
    modelGetCategoryByUID,
    modelGetSubcategoryByUID,
    modelGetArticleByUID,
    modelGuideMarkValidate,
    modelGuideMarkFetch,
    modelGetEntityByID
}