import hash from "object-hash"
import _ from "lodash"
import Distance from "../helpers/Distance"
import specialCase from "../datas/specialCase"
import specialCaseGPS from "../datas/specialCaseGPS"
import specialCaseMapping from "../datas/specialCaseMapping"
import regionsGPS from "../datas/regionsGPS"

export const modelShoppingMallsFetch = async (
    state = null,
    location = null,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const limit = 20
    const dbIndustrialInst = serverInstance && serverInstance.dbIndustrial ? serverInstance.dbIndustrial : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const objectHash = hash({ state, location })
    const redisKey = `modelShoppingMallsFetch:${objectHash}`

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }
      
    let filter = {}
    if (location) {
        if (['commune', 'commune_all'].indexOf(location.type) !== -1) {
            if (location.type === 'commune' && typeof specialCase[location.code] !== 'undefined') {
                filter = {
                    geometry: {
                        $near: {
                            $geometry: {
                                type: "Point",
                                coordinates: [
                                    specialCaseGPS[
                                        specialCaseMapping[`${location.code}_${location.codePostal}`]
                                    ].lon,
                                    specialCaseGPS[
                                        specialCaseMapping[`${location.code}_${location.codePostal}`]
                                    ].lat
                                ]
                            },
                            $maxDistance: 30000 // 30km
                        }
                    }
                }
            }
            if (location.type === 'commune_all' || ( location.type === 'commune' && typeof specialCase[location.code] === 'undefined' )) {
                filter = {
                    geometry: {
                        $near: {
                            $geometry: {
                                type: "Point",
                                coordinates: [location.long, location.lat]
                            },
                            $maxDistance: 30000 // 30km
                        }
                    }
                }
            }
        }
        if (location.type === 'departement') {
            filter = {
                city_insee: {
                    $regex : new RegExp(`^${location.codeDepartement}`)
               }
            }
        }
    }
    if (!location && state) {
        filter = {
            region: state
        }
    }

    let cursorIndustrial = dbIndustrialInst
        .collection('centres_commerciaux')
        .find(filter)
        .limit(limit ? limit : 0)
    if ((!location && !state) || (!location && state) || (location && ['commune', 'commune_all'].indexOf(location.type) === -1)) {
        cursorIndustrial = cursorIndustrial.sort({ surface: -1 })
    }

    let shoppingMalls = []
    for await (const doc of cursorIndustrial) {
        let distance = null
        if (location && ['commune', 'commune_all'].indexOf(location.type) !== -1) {
            if (location.type === 'commune' && typeof specialCase[location.code] !== 'undefined') {
                distance = Distance(
                    specialCaseGPS[
                        specialCaseMapping[`${location.code}_${location.codePostal}`]
                    ].lat,
                    specialCaseGPS[
                        specialCaseMapping[`${location.code}_${location.codePostal}`]
                    ].lon,
                    doc.geometry.coordinates[1],
                    doc.geometry.coordinates[0]
                )
            }
            if (location.type === 'commune_all' || ( location.type === 'commune' && typeof specialCase[location.code] === 'undefined' )) {
                distance = Distance(
                    location.lat,
                    location.long,
                    doc.geometry.coordinates[1],
                    doc.geometry.coordinates[0]
                )
            }
        }
        if (!location && state) {
            const regionGPS = regionsGPS.filter(o => o.name === state)[0]
            distance = Distance(
                regionGPS.coordinates[1],
                regionGPS.coordinates[0],
                doc.geometry.coordinates[1],
                doc.geometry.coordinates[0]
            )
        }
        shoppingMalls.push(_.omit({ ...doc, distance }, ['_id', 'id_dlt', 'id_cncc']))
    }

    const result = _.uniqBy(shoppingMalls, 'id_uec')
        .slice(0, 10)

    redis.set(redisKey, JSON.stringify(result), 'EX', 60 * 60 * 24 * 365).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return result
}