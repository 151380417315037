import axios from "../config/axios"
import getConfig from "next/config"

import {
    SUBSCRIBE_STATE_SUBMIT,
    SUBSCRIBE_STATE_SUBMITTED,
    SUBSCRIBE_STATE_ERROR_SUBMITTED,
    SUBSCRIBE_STATE_RESET,
    SUBSCRIBE_STATE_GO_TO_STEP,
    /* first step */
    SUBSCRIBE_STATE_CHANGE_FIRSTNAME,
    SUBSCRIBE_STATE_CHANGE_LASTNAME,
    SUBSCRIBE_STATE_CHANGE_POSITION,
    SUBSCRIBE_STATE_CHANGE_CUSTOM_POSITION,
    /* first step */
    SUBSCRIBE_STATE_QUIT_TERMINATED_FORM,
    SUBSCRIBE_STATE_ADD_LOCATION,
    SUBSCRIBE_STATE_REMOVE_LOCATION,
    SUBSCRIBE_STATE_CHANGE_ADDRESS,
    SUBSCRIBE_STATE_ADD_LOCATION2,
    SUBSCRIBE_STATE_REMOVE_LOCATION2,
    SUBSCRIBE_STATE_CHANGE_EMAIL,
    SUBSCRIBE_STATE_CHANGE_EMAIL_CONFIRMATION,
    SUBSCRIBE_STATE_CHANGE_PHONE,
    SUBSCRIBE_STATE_TOGGLE_PRIVACY_POLICY
} from "./types"

export const subscribeStateAddLocation = location => {
    return { type: SUBSCRIBE_STATE_ADD_LOCATION, payload: location }
}

export const subscribeStateAddLocation2 = location => {
    return { type: SUBSCRIBE_STATE_ADD_LOCATION2, payload: location }
}

export const subscribeStateRemoveLocation2 = () => {
    return { type: SUBSCRIBE_STATE_REMOVE_LOCATION2 }
}

export const subscribeStateRemoveLocation = locationId => {
    return { type: SUBSCRIBE_STATE_REMOVE_LOCATION, payload: locationId }
}

export const subscribeStateSubmitFirstStep = () => {
    return {
        type: SUBSCRIBE_STATE_SUBMIT
    }
}

export const subscribeStateReset = () => {
    return {
        type: SUBSCRIBE_STATE_RESET
    }
}

export const subscribeStateGoToStep = step => {
    return { type: SUBSCRIBE_STATE_GO_TO_STEP, payload: step }
}

export const subscribeStateChangeFirstname = firstname => {
    return { type: SUBSCRIBE_STATE_CHANGE_FIRSTNAME, payload: firstname }
}

export const subscribeStateChangeLastname = lastname => {
    return { type: SUBSCRIBE_STATE_CHANGE_LASTNAME, payload: lastname }
}

export const subscribeStateChangePosition = position => {
    return { type: SUBSCRIBE_STATE_CHANGE_POSITION, payload: position }
}

export const subscribeStateChangeCustomPosition = customPosition => {
    return {
        type: SUBSCRIBE_STATE_CHANGE_CUSTOM_POSITION,
        payload: customPosition
    }
}

export const subscribeStateChangeAddress = address => {
    return { type: SUBSCRIBE_STATE_CHANGE_ADDRESS, payload: address }
}

export const subscribeStateChangeEmail = email => {
    return { type: SUBSCRIBE_STATE_CHANGE_EMAIL, payload: email }
}

export const subscribeStateChangeEmailConfirmation = emailConfirmation => {
    return {
        type: SUBSCRIBE_STATE_CHANGE_EMAIL_CONFIRMATION,
        payload: emailConfirmation
    }
}

export const subscribeStateChangePhone = phone => {
    return { type: SUBSCRIBE_STATE_CHANGE_PHONE, payload: phone }
}

export const subscribeStateQuitTerminatedForm = () => {
    return {
        type: SUBSCRIBE_STATE_QUIT_TERMINATED_FORM
    }
}

export const subscribeStateSubmit = (
    firstname,
    lastname,
    position,
    customPosition,
    location,
    locationsArea,
    address,
    email,
    phone
) => {
    return async dispatch => {
        dispatch({ type: SUBSCRIBE_STATE_SUBMIT })
        axios
            .post(
                getConfig().publicRuntimeConfig.externalAPIEndpoint +
                    "/api/subscribe_state",
                {
                    lastname,
                    firstname,
                    position,
                    custom_position: customPosition,
                    location,
                    locations_area: locationsArea,
                    address,
                    email,
                    phone
                }
            )
            .then(() => {
                dispatch({ type: SUBSCRIBE_STATE_SUBMITTED })
            })
            .catch(error => {
                dispatch({
                    type: SUBSCRIBE_STATE_ERROR_SUBMITTED,
                    payload: error.response.data.error
                })
            })
    }
}

export const subscribeStateTogglePrivacyPolicy = privacyPolicy => {
    return {
        type: SUBSCRIBE_STATE_TOGGLE_PRIVACY_POLICY,
        payload: privacyPolicy
    }
}