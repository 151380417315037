import firebaseInstance from "../config/firebase"
import axios from "../config/axios"
import algoliasearch from "algoliasearch"
import _ from "lodash"
import getConfig from "next/config"
import hash from "object-hash"

import { modelGetProfileSimple } from "./Users"
import { modelGetProspectionDescription } from "./MessagesTemplates"
import { locationIntersects, translateLocationsToAlgoliaFilters } from "../helpers/Locations"
import { buildFiltersFirestore } from "../helpers/Filters"

import activities from "../datas/Activities"
import codeRegions from "../datas/codeRegions"

let client = null
let index = null
const instance = axios.create({
    baseURL: "/api",
    timeout: 30000
})

const getViewsCountWithTest = async (
    uid,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let count = await getViewsCount(
        uid,
        prospectionId,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    if (count === null) {
        /* await updateProspectionActionLogsAggregationByUser(
            uid,
            "sum",
            0,
            runtimeConfig,
            serverInstance
        )*/
        await updateProspectionActionLogsAggregationByProspection(
            uid,
            prospectionId,
            "sum",
            0,
            runtimeConfig,
            serverInstance
        )
        count = await getViewsCount(
            uid,
            prospectionId,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    return count
}

const getPhoneViewsCountWithTest = async (
    uid,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let count = await getPhoneViewsCount(
        uid,
        prospectionId,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    if (count === null) {
        /*await updateShowPhoneLogsAggregationByUser(
            uid,
            "sum",
            0,
            runtimeConfig,
            firebaseInst
        )*/
        await updateShowPhoneLogsAggregationByProspection(
            uid,
            prospectionId,
            "sum",
            0,
            false,
            runtimeConfig,
            serverInstance
        )
        count = await getPhoneViewsCount(
            uid,
            prospectionId,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    return count
}

const getProspectionProposalCountWithTest = async (
    uid,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let count = await getProspectionProposalCount(
        uid,
        prospectionId,
        runtimeConfig,
        serverInstance,
        refreshCache
    )
    if (count === null) {
        /* await updateProspectionActionLogsAggregationByUser(
        uid,
        "sum",
        0,
        serverInstance
      )*/
        await updatePrivateMessageWaitingListAggregationByProspection(
            uid,
            prospectionId,
            "sum",
            0,
            runtimeConfig,
            serverInstance
        )
        count = await getProspectionProposalCount(
            uid,
            prospectionId,
            runtimeConfig,
            serverInstance,
            refreshCache
        )
    }
    return count
}

export {
    getViewsCountWithTest,
    getPhoneViewsCountWithTest,
    getProspectionProposalCountWithTest
}

export const modelProspectionsStateFetch = async (
    type,
    state,
    locations = [],
    filters = {},
    page = 0,
    hitsPerPage = 30,
    reloadAll = false,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false,
    distinct = true,
    refreshCache = false
) => {
    let p = []

    const locationsFilters = await translateLocationsToAlgoliaFilters(locations)

    if (type === "algolia") {
        p.push(
            new Promise((resolve, reject) => {
                if (!reloadAll) {
                    var config = {
                        query: "",
                        page,
                        hitsPerPage,
                        distinct: distinct ? 1 : 0
                    }
                } else {
                    var config = {
                        query: "",
                        page: 0,
                        hitsPerPage: (page + 1) * hitsPerPage,
                        distinct: distinct ? 1 : 0
                    }
                }

                // BEGIN FILTERS
                let filtersList = []

                // State filters
                if (state !== "all")
                    filtersList.push(
                        '(prospection.location.state:"' +
                            state +
                            '" OR prospection.location.state:"all")'
                    )

                if (filters.reference)
                    filtersList.push(
                        'prospection.reference_id:"' + filters.reference + '"'
                    )

                if (filters.type) {
                    if (filters.type === "local-commercial") {
                        filtersList.push(
                            'prospection.type: 1'
                        )
                    }
                    if(filters.type === "fonds-commerce") {
                        filtersList.push(
                            'prospection.type: 2'
                        )
                    }
                    if (filters.type === "popup-store") {
                        filtersList.push(
                            'prospection.type: 3'
                        )
                    }
                    if (filters.type === "terrain") {
                        filtersList.push(
                            'prospection.type: 4'
                        )
                    }
                    if (filters.type === "bureau") {
                        filtersList.push(
                            'prospection.type: 5'
                        )
                    }
                    if (filters.type === "entrepot") {
                        filtersList.push(
                            'prospection.type: 7'
                        )
                    }
                }

                if (filters.activity) {
                    const activity = activities.filter(
                        o => o.key === parseInt(filters.activity)
                    )

                    if (activity.length === 1) {
                        filtersList.push(
                            "prospection.activity_type: " + activity[0].key
                        )
                    }
                }

                // Location filters
                if (locationsFilters.length > 0) {
                    if (!filters.hideAll) {
                        locationsFilters.push("_tags:locations_id_country_fr")
                    }
                    filtersList.push("( " + locationsFilters.join(" OR ") + " )")
                }

                if (locationsFilters.length === 0 && filters.hideAll) {
                    filtersList.push("NOT _tags:locations_id_country_fr")
                }

                // Price filters
                if (
                    typeof filters.minPrice !== "undefined" &&
                    typeof filters.maxPrice !== "undefined"
                ) {
                    var priceFilter = ""

                    if (
                        _.isFinite(filters.minPrice) &&
                        !_.isFinite(filters.maxPrice)
                    ) {
                        priceFilter = "prospection.price >= " + filters.minPrice
                    }

                    if (
                        !_.isFinite(filters.minPrice) &&
                        _.isFinite(filters.maxPrice)
                    ) {
                        priceFilter = "prospection.price <= " + filters.maxPrice
                    }

                    if (
                        _.isFinite(filters.minPrice) &&
                        _.isFinite(filters.maxPrice)
                    ) {
                        priceFilter =
                            "prospection.price >= " +
                            filters.minPrice +
                            " AND prospection.price <= " +
                            filters.maxPrice
                    }

                    filtersList.push(priceFilter)
                }

                // Lease filters
                if (
                    typeof filters.minLease !== "undefined" &&
                    typeof filters.maxLease !== "undefined"
                ) {
                    var leaseFilter = ""

                    if (
                        _.isFinite(filters.minLease) &&
                        !_.isFinite(filters.maxLease)
                    ) {
                        leaseFilter = "prospection.lease >= " + filters.minLease
                    }

                    if (
                        !_.isFinite(filters.minLease) &&
                        _.isFinite(filters.maxLease)
                    ) {
                        leaseFilter = "prospection.lease <= " + filters.maxLease
                    }

                    if (
                        _.isFinite(filters.minLease) &&
                        _.isFinite(filters.maxLease)
                    ) {
                        leaseFilter =
                            "prospection.lease >= " +
                            filters.minLease +
                            " AND prospection.lease <= " +
                            filters.maxLease
                    }

                    filtersList.push(leaseFilter)
                }

                // groundfloor surface filters intersection
                if (
                    typeof filters.minRDC !== "undefined" &&
                    typeof filters.maxRDC !== "undefined"
                ) {
                    var x1 = "prospection.groundfloor_surface.min"
                    var x2 = "prospection.groundfloor_surface.max"

                    var y1 = filters.minRDC
                    var y2 = filters.maxRDC

                    var RDCFilter = `${x1} <= ${y2} AND ${x2} >= ${y1}`

                    filtersList.push(RDCFilter)
                }

                // total surface filters intersection
                /* if (
                    typeof filters.minTotal !== "undefined" &&
                    typeof filters.maxTotal !== "undefined"
                ) {
                    var x1 = "prospection.rest_surface.min"
                    var x2 = "prospection.rest_surface.max"

                    var y1 = filters.minTotal
                    var y2 =
                        filters.maxTotal ===
                        (filters.typeFilter.indexOf(2) != -1 ? 5000 : 800)
                            ? 9999999
                            : filters.maxTotal

                    var TotalFilter = `${x1} <= ${y2} AND ${x2} >= ${y1}`

                    filtersList.push(TotalFilter)
                } */

                // Criterias filters
                if (filters.typeFilter.indexOf(2) != -1) {
                    filtersList.push("_tags:isField")
                }
                if (filters.typeFilter.indexOf(27) !== -1) {
                    filtersList.push("NOT _tags:isField")
                }

                if (filters.typeFilter.indexOf(3) != -1) {
                    filtersList.push("_tags:allowBuy")
                }

                if (filters.typeFilter.indexOf(20) != -1) {
                    filtersList.push("_tags:restSurfaceAccess")
                }

                if (filters.typeFilter.indexOf(15) != -1) {
                    filtersList.push("_tags:secondFloorAccess")
                }

                if (filters.typeFilter.indexOf(21) != -1) {
                    filtersList.push(
                        'prospection.price_attributes:"lease_only"'
                    )
                }

                if (filters.typeFilter.indexOf(28) != -1) {
                    filtersList.push(
                        'NOT prospection.price_attributes:"lease_only"'
                    )
                }

                if (filters.typeFilter.indexOf(22) != -1) {
                    filtersList.push("prospection.display_number:true")
                }

                let status = []

                if (filters.typeFilter.indexOf(24) != -1) {
                    status.push("prospection.user_status = 2")
                }
                if (filters.typeFilter.indexOf(25) != -1) {
                    status.push("prospection.user_status = 1")
                }
                if (filters.typeFilter.indexOf(26) != -1) {
                    status.push("prospection.user_status = 3")
                }

                if (status.length > 0) {
                    filtersList.push(`(${status.join(" OR ")})`)
                }

                var placeTypes = []

                if (filters.typeFilter.indexOf(4) != -1) {
                    placeTypes.push("_tags:center")
                }

                if (filters.typeFilter.indexOf(6) != -1) {
                    placeTypes.push("_tags:numberOne")
                } else {
                    //filtersList.push('NOT _tags:numberOne')
                }

                if (filters.typeFilter.indexOf(7) != -1) {
                    placeTypes.push("_tags:numberOneBis")
                } else {
                    //filtersList.push('NOT _tags:numberOneBis')
                }

                if (filters.typeFilter.indexOf(8) != -1) {
                    placeTypes.push("_tags:axePassant")
                } else {
                    //filtersList.push('NOT _tags:axePassant')
                }

                if (filters.typeFilter.indexOf(5) != -1) {
                    placeTypes.push("_tags:mall")
                }

                if (filters.typeFilter.indexOf(9) != -1) {
                    placeTypes.push("_tags:galerie")
                }

                if (filters.typeFilter.indexOf(10) != -1) {
                    placeTypes.push("_tags:transportation")
                }

                if (filters.typeFilter.indexOf(11) != -1) {
                    placeTypes.push("_tags:suburb")
                }

                if (filters.typeFilter.indexOf(12) != -1) {
                    placeTypes.push("_tags:commercialArea")
                }

                if (filters.typeFilter.indexOf(13) != -1) {
                    placeTypes.push("_tags:industrialArea")
                }

                if (filters.typeFilter.indexOf(14) != -1) {
                    placeTypes.push("_tags:undergroundAccess")
                }

                if (filters.typeFilter.indexOf(16) != -1) {
                    placeTypes.push("_tags:requiredLicense4")
                }

                if (filters.typeFilter.indexOf(17) != -1) {
                    placeTypes.push(
                        "_tags:allowedCateringIndustryWithExtraction"
                    )
                }

                if (filters.typeFilter.indexOf(1) != -1) {
                    placeTypes.push("_tags:allowedCateringIndustry")
                }

                if (filters.typeFilter.indexOf(18) != -1) {
                    placeTypes.push("_tags:parking")
                }

                if (filters.typeFilter.indexOf(19) != -1) {
                    placeTypes.push("_tags:closeParking")
                }

                if (placeTypes.length > 0) {
                    filtersList.push("( " + placeTypes.join(" OR ") + " )")
                }

                if (
                    filters.exclude &&
                    _.isArray(filters.exclude) &&
                    filters.exclude.length > 0
                ) {
                    for (let i = 0; i < filters.exclude.length; i++) {
                        filtersList.push("NOT objectID:" + filters.exclude[i])
                    }
                }

                if (
                    filters.excludeReferences &&
                    _.isArray(filters.excludeReferences) &&
                    filters.excludeReferences.length > 0
                ) {
                    for (let i = 0; i < filters.excludeReferences.length; i++) {
                        filtersList.push(
                            "NOT prospection.reference_id:" +
                                filters.excludeReferences[i]
                        )
                    }
                }

                if (filtersList.length > 0) {
                    const filtersConditions = filtersList.join(" AND ")
                    config.filters = filtersConditions
                }

                if (filters.optionalFilters) {
                    config.optionalFilters = filters.optionalFilters
                }

                let publicRuntimeConfig = {}
                if (getConfig()) {
                    publicRuntimeConfig = getConfig().publicRuntimeConfig
                } else {
                    publicRuntimeConfig = runtimeConfig
                }

                if (index === null) {
                    client = algoliasearch(
                        publicRuntimeConfig.algoliaApplicationID,
                        publicRuntimeConfig.algoliaSearchOnlyAPIKey
                    )

                    index = client.initIndex(
                        publicRuntimeConfig.algoliaPrefix + publicRuntimeConfig.algoliaProspectionsIndexName
                    )
                }

                index.clearCache()

                index.search(config, (error, content) => {
                    if (error) reject(error)

                    if (forceAPI) {
                        let hits = []
                        let uids = []
                        let pids = []
                        for (var h in content.hits) {
                            uids.push(content.hits[h].prospection.user)
                            pids.push(content.hits[h].objectID.split("_")[0])
                            hits.push({
                                ...content.hits[h].prospection,
                                userinfo: {
                                    status:
                                        content.hits[h].prospection.user_status
                                },
                                id: content.hits[h].objectID,
                                prospection_id: content.hits[h].objectID.split(
                                    "_"
                                )[0]
                            })
                        }

                        if (uids.length > 0) {
                            Promise.all([
                                instance.get("/gp", { params: { u: uids } }),
                                instance.get("/gps", {
                                    params: { u: uids, p: pids }
                                }),
                                instance.post("/gpd", { p: hits })
                            ])
                                .then(res => {
                                    let userinfos = res[0].data.results
                                    let stats = res[1].data.results
                                    let description = res[2].data.results
                                    let pos = 0
                                    for (let i = 0; i < hits.length; i++) {
                                        hits[i].userinfo = userinfos[i]
                                        hits[i].stats = stats[pos++]
                                        hits[i].phone_stats = stats[pos++]
                                        hits[i].proposal_stats = stats[pos++]
                                        hits[i].description = description[i]
                                    }

                                    resolve([
                                        content.nbHits > 0 ? hits : [],
                                        page,
                                        content.nbHits,
                                        content
                                    ])
                                })
                                .catch(error => {
                                    console.log("error profile fetch: ", error)
                                    resolve([
                                        content.nbHits > 0 ? hits : [],
                                        page,
                                        content.nbHits,
                                        content
                                    ])
                                })
                        } else {
                            resolve([
                                content.nbHits > 0 ? hits : [],
                                page,
                                content.nbHits,
                                content
                            ])
                        }
                    } else {
                        let hits = []
                        let users = []
                        let uids = []
                        let pids = []
                        for (let h in content.hits) {
                            const uid = content.hits[h].prospection.user
                            const prospectionId = content.hits[
                                h
                            ].objectID.split("_")[0]
                            const prospection = content.hits[h].prospection
                            uids.push(uid)
                            pids.push(prospectionId)
                            users.push(
                                new Promise((resolve, reject) => {
                                    modelGetProfileSimple(
                                        "realtime",
                                        content.hits[h].prospection.user,
                                        serverInstance,
                                        refreshCache
                                    )
                                    .then(userinfo => resolve(userinfo))
                                    .catch(() => resolve(null))
                                })
                            )
                            users.push(
                                getViewsCountWithTest(
                                    uid,
                                    prospectionId,
                                    runtimeConfig,
                                    serverInstance,
                                    refreshCache
                                )
                            )
                            users.push(
                                getPhoneViewsCountWithTest(
                                    uid,
                                    prospectionId,
                                    runtimeConfig,
                                    serverInstance,
                                    refreshCache
                                )
                            )
                            users.push(
                                getProspectionProposalCountWithTest(
                                    uid,
                                    prospectionId,
                                    runtimeConfig,
                                    serverInstance,
                                    refreshCache
                                )
                            )
                            users.push(
                                modelGetProspectionDescription(
                                    prospectionId,
                                    prospection.location.id,
                                    {
                                        ...prospection,
                                        userinfo: {
                                            status: prospection.user_status
                                        }
                                    },
                                    runtimeConfig,
                                    serverInstance,
                                    refreshCache
                                )
                            )
                            hits.push({
                                ...prospection,
                                id: content.hits[h].objectID,
                                prospection_id: prospectionId
                            })
                        }

                        Promise.all(users).then(usersResults => {
                            let pos = 0
                            for (let i = 0; i < hits.length; i++) {
                                hits[i].userinfo = usersResults[pos++]
                                hits[i].stats = usersResults[pos++]
                                hits[i].phone_stats = usersResults[pos++]
                                hits[i].proposal_stats = usersResults[pos++]
                                hits[i].description = usersResults[pos++]
                            }

                            resolve([
                                content.nbHits > 0 ? hits : [],
                                page,
                                content.nbHits,
                                content
                            ])
                        })
                    }
                })
            })
        )
    }

    if (type === "firestore") {
        const filtersOrigin = filters
        filters = buildFiltersFirestore(filters)
        if (!forceAPI) {
            const firebaseInst = serverInstance && serverInstance.firebase_second ? serverInstance.firebase_second : null
            const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
            const fi = firebaseInst
            const redis = redisInst

            const cleanedLocations = locationsFilters.map(l => l.replace('_tags:', ''))
            let db = fi
                .firestore()
                .collection(`prospections_${!distinct ? 'map_' : ''}search`)
            if (locationsFilters.length > 0) {
                db = db.where('locations_tags', 'array-contains-any', cleanedLocations)
            }
            for (let i = 0; i < filters.length; i++) {
                db = db.where(filters[i].key, filters[i].operator, filters[i].value)
            }

            if (state !== "all") {
                if (distinct) {
                    if (locationsFilters.length > 0) {
                        // db = db.where(`search_tags.states.${state}`, '==', true)
                    }
                    if (locationsFilters.length === 0) {
                        db = db.where(`search_tags.states_array`, 'array-contains', state)
                    }
                }
                if (!distinct) {
                    db = db.where(`location.state`, '==', state)
                }
            }

            //console.log('cleanedLocations: ', cleanedLocations)
            // console.log('filters: ', filters)

            const querySnapshotCount = await db.get()
            const nbHits = querySnapshotCount.size
            const toExcludeReferences = filtersOrigin.excludeReferences && _.isArray(filtersOrigin.excludeReferences) ? true : false
            // console.log('nbHits: ', nbHits)
            // console.log('toExcludeReferences: ', toExcludeReferences)
            const querySnapshot = await db
                .orderBy('last_modified_ts', 'desc')
                .limit(
                    hitsPerPage +
                        (toExcludeReferences ? filtersOrigin.excludeReferences.length : 0)
                )
                .offset(page * hitsPerPage)
                .get()

            let datas = []
            querySnapshot.forEach(doc => {
                // console.log('doc: ', { id: doc.id, data: doc.data() })
                if (
                    toExcludeReferences &&
                    filtersOrigin.excludeReferences.indexOf(doc.data().search_tags.reference_id) === -1
                ) {
                    datas.push({
                        id: doc.id,
                        data: doc.data()
                    })
                }
                if (!toExcludeReferences) {
                    // console.log('doc2: ', { id: doc.id, data: doc.data() })
                    datas.push({
                        id: doc.id,
                        data: doc.data()
                    })
                }
            })
            if (toExcludeReferences) {
                datas = _.take(datas, hitsPerPage)
            }

            let hits = []
            let users = []
            let uids = []
            for (let i = 0; i < datas.length; i++) {
                const prospectionId = datas[i].id
                let prospection = datas[i].data
                delete prospection.search_tags
                delete prospection.locations_tags
                const uid = prospection.user

                if (distinct) {
                    if (locations.length > 0) {
                        Loop:
                        for (let j = 0; j < prospection.locations.length; j++) {
                            for (let k = 0; k < locations.length; k++) {
                                if (await locationIntersects(locations[k], prospection.locations[j])) {
                                    prospection.location = prospection.locations[j]
                                    break Loop
                                }
                            }
                        }
                    }
                    if (locations.length === 0) {
                        if (state !== "all") {
                            Loop2:
                            for (let j = 0; j < prospection.locations.length; j++) {
                                if (
                                    prospection.locations[j].state === state
                                ) {
                                    prospection.location = prospection.locations[j]
                                    break Loop2
                                }
                            }
                        }
                        if (state === "all") {
                            prospection.location = prospection.locations[0]
                        }
                    }
                }

                uids.push(uid)
                users.push(
                    new Promise((resolve, reject) => {
                        modelGetProfileSimple(
                            "realtime",
                            uid,
                            serverInstance,
                            refreshCache
                        )
                        .then(userinfo => resolve(userinfo))
                        .catch(() => resolve(null))
                    })
                )
                users.push(
                    getViewsCountWithTest(
                        uid,
                        prospectionId,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    )
                )
                users.push(
                    getPhoneViewsCountWithTest(
                        uid,
                        prospectionId,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    )
                )
                users.push(
                    getProspectionProposalCountWithTest(
                        uid,
                        prospectionId,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    )
                )
                users.push(
                    modelGetProspectionDescription(
                        prospectionId,
                        prospection.location.id,
                        {
                            ...prospection,
                            userinfo: {
                                status: prospection.user_status
                            }
                        },
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    )
                )
                hits.push({
                    ...prospection,
                    id: `${prospectionId}_${prospection.location.id}`,
                    prospection_id: prospectionId
                })
            }

            const usersResults = await Promise.all(users)
            let pos = 0
            for (let i = 0; i < hits.length; i++) {
                hits[i].userinfo = usersResults[pos++]
                hits[i].stats = usersResults[pos++]
                hits[i].phone_stats = usersResults[pos++]
                hits[i].proposal_stats = usersResults[pos++]
                hits[i].description = usersResults[pos++]
            }

            p.push(
                Promise.resolve([
                    nbHits > 0 ? hits : [],
                    page,
                    nbHits,
                    {}
                ])
            )
        }
        if (forceAPI) {
            const results = await instance.post("/pss", {
                state,
                locations,
                filters: filtersOrigin,
                distinct,
                page,
                per_page: hitsPerPage
            })

            p.push(Promise.resolve(results.data))
        }
    }

    return Promise.all(p)
}

export const modelProspectionsStateCountFetch = async (
    type,
    state,
    locations = [],
    filters = {},
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false,
    distinct = true,
    refreshCache = false
) => {
    let p = []

    if (type === "firestore") {
        const filtersOrigin = filters
        filters = buildFiltersFirestore(filters)
        if (!forceAPI) {
            let locationsFilters = await translateLocationsToAlgoliaFilters(locations)
            const firebaseInst = serverInstance && serverInstance.firebase_second ? serverInstance.firebase_second : null
            const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
            const fi = firebaseInst
            const redis = redisInst
            const objectHash = hash({ state, locations, filters: filtersOrigin, distinct })
            const redisKey = `getProspectionsStateCount:${objectHash}`

            if (refreshCache) {
                await redis.del(redisKey)
            }

            try {
                const res = await redis.get(redisKey)
                // console.log('REDIS GET: ', redisKey, res)

                if (res === null) {
                    throw 'doesnt_exist'
                }

                p.push(
                    Promise.resolve(
                        [
                            null,
                            null,
                            JSON.parse(res),
                            null
                        ]
                    )
                )
            } catch (error) {
                // console.log('REDIS ERROR: ', error)

                let db = fi
                    .firestore()
                    .collection(`prospections_${!distinct ? 'map_' : ''}search`)

                if (locationsFilters.length > 0) {
                    db = db.where('locations_tags', 'array-contains-any', locationsFilters.map(l => l.replace('_tags:', '')))
                }
                for (let i = 0; i < filters.length; i++) {
                    db = db.where(filters[i].key, filters[i].operator, filters[i].value)
                }
                if (state !== "all") {
                    if (distinct) {
                        if (locationsFilters.length > 0) {
                            // db = db.where(`search_tags.states.${state}`, '==', true)
                        }
                        if (locationsFilters.length === 0) {
                            db = db.where(`search_tags.states_array`, 'array-contains', state)
                        }
                    }
                    if (!distinct) {
                        db = db.where(`location.state`, '==', state)
                    }
                }

                const querySnapshotCount = await db.get()
                const nbHits = querySnapshotCount.size

                redis.set(redisKey, JSON.stringify(nbHits), 'ex', 60 * 60 * 24).then(res => {
                    // console.log('REDIS SET: ', res)
                })

                p.push(
                    Promise.resolve(
                        [
                            null,
                            null,
                            nbHits,
                            null
                        ]
                    )
                )
            }
        }
        if (forceAPI) {
            const results = await instance.post("/pssc", {
                state,
                locations,
                filters: filtersOrigin,
                distinct
            })

            p.push(
                Promise.resolve(results.data)
            )
        }
    }

    return Promise.all(p)
}

export const getViewsCount = async (
    uid,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey =
        prospectionId ? `prospection_action_logs_aggregation_p_${prospectionId}` : `prospection_action_logs_aggregation_${uid}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR: ', error)
    }
    let db = fi
        .firestore()
        .collection("prospection_action_logs_aggregation")
        .where("uid", "==", uid)
        .where("period", "==", "total")

    if (prospectionId) {
        db = db.where("type", "==", "prospection")
        db = db.where("prospection_id", "==", prospectionId)
    } else {
        db = db.where("type", "==", "user")
    }

    let querySnapshot = await db.limit(1).get()

    let count = null
    if (querySnapshot.size === 1) {
        querySnapshot.forEach(doc => {
            count = doc.data().value
        })
    }

    redis.set(redisKey, JSON.stringify(count)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return count
}

export const getPhoneViewsCount = async (
    uid,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey =
        prospectionId ? `show_phone_logs_aggregation_p_${prospectionId}` : `show_phone_logs_aggregation_${uid}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
    // console.log('REDIS ERROR: ', error)
    }

    let db = fi
        .firestore()
        .collection("show_phone_logs_aggregation")
        .where("uid", "==", uid)
        .where("period", "==", "total")
    // .where("created_at_ts", ">", Date.now() - 60 /** 60 * 24*/ * 1000)

    if (prospectionId) {
        db = db.where("type", "==", "prospection")
        db = db.where("prospection_id", "==", prospectionId)
    } else {
        db = db.where("type", "==", "user")
    }

    let querySnapshot = await db.limit(1).get()

    let count = null
    if (querySnapshot.size === 1) {
        querySnapshot.forEach(doc => {
            count = doc.data().value
        })
    }

    redis.set(redisKey, JSON.stringify(count)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return count
}

export const updateProspectionActionLogsAggregationByUser = async (
    uid,
    type,
    value,
    runtimeConfig = null,
    serverInstance = null
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    return fi.firestore().runTransaction(async transaction => {
        let querySnapshot = await transaction.get(
            fi
                .firestore()
                .collection("prospection_action_logs_aggregation")
                .where("type", "==", "user")
                .where("period", "==", "total")
                .where("uid", "==", uid)
        )

        let id = null
        let count = null
        if (querySnapshot.size === 0) {
            let querySnapshot2 = await fi
                .firestore()
                .collection("prospection_action_logs")
                .where("action_type", "==", "view")
                .where("first", "==", true)
                .where("author_uid", "==", uid)
                .get()
            count = querySnapshot2.size
        } else {
            querySnapshot.forEach(doc => {
                id = doc.id
                count = doc.data().value
            })
        }

        let newCount = count

        if (type === "sum") {
            newCount += value
        }
        if (type === "diff") {
            newCount -= value
        }

        if (id === null) {
            transaction.set(
                fi
                    .firestore()
                    .collection("prospection_action_logs_aggregation")
                    .doc(),
                {
                    type: "user",
                    period: "total",
                    uid,
                    value: newCount
                }
            )
        }

        if (id !== null) {
            transaction.update(
                fi
                    .firestore()
                    .collection("prospection_action_logs_aggregation")
                    .doc(id),
                { value: newCount }
            )
        }

        return newCount
    }).then(newCount => {
        return redis.set(`prospection_action_logs_aggregation_${uid}`, JSON.stringify(newCount)).then(res => {
            // console.log('REDIS SET: ', res)
        })
    })
}

export const updateProspectionActionLogsAggregationByProspection = async (
    uid,
    prospectionId,
    type,
    value,
    runtimeConfig = null,
    serverInstance = null
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    let fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    return fi.firestore().runTransaction(async transaction => {
        let querySnapshot = await transaction.get(
            fi
                .firestore()
                .collection("prospection_action_logs_aggregation")
                .where("type", "==", "prospection")
                .where("period", "==", "total")
                .where("uid", "==", uid)
                .where("prospection_id", "==", prospectionId)
        )

        let id = null
        let count = null
        if (querySnapshot.size === 0) {
            let querySnapshot2 = await fi
                .firestore()
                .collection("prospection_action_logs")
                .where("action_type", "==", "view")
                .where("first", "==", true)
                .where("author_uid", "==", uid)
                .where("prospection_id", "==", prospectionId)
                .get()
            count = querySnapshot2.size
        } else {
            querySnapshot.forEach(doc => {
                id = doc.id
                count = doc.data().value
            })
        }

        let newCount = count

        if (type === "sum") {
            newCount += value
        }
        if (type === "diff") {
            newCount -= value
        }

        if (id === null) {
            transaction.set(
                fi
                    .firestore()
                    .collection("prospection_action_logs_aggregation")
                    .doc(),
                {
                    type: "prospection",
                    period: "total",
                    uid,
                    prospection_id: prospectionId,
                    value: newCount
                }
            )
        }

        if (id !== null) {
            transaction.update(
                fi
                    .firestore()
                    .collection("prospection_action_logs_aggregation")
                    .doc(id),
                { value: newCount }
            )
        }

        return newCount
    }).then(newCount => {
        return redis.set(`prospection_action_logs_aggregation_p_${prospectionId}`, JSON.stringify(newCount)).then(res => {
            // console.log('REDIS SET: ', res)
        })
    })
}

export const updateShowPhoneLogsAggregationByUser = async (
    uid,
    type,
    value,
    runtimeConfig = null,
    serverInstance = null
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    return fi.firestore().runTransaction(async transaction => {
        let querySnapshot = await transaction.get(
            fi
                .firestore()
                .collection("show_phone_logs_aggregation")
                .where("type", "==", "user")
                .where("period", "==", "total")
                .where("uid", "==", uid)
        )

        let id = null
        let count = null

        if (querySnapshot.size === 0) {
            let querySnapshot2 = await fi
                .firestore()
                .collection("show_phone_logs")
                .where("uid", "==", uid)
                .get()
            count = querySnapshot2.size
        } else {
            querySnapshot.forEach(doc => {
                id = doc.id
                count = doc.data().value
            })
        }

        let newCount = count

        if (type === "sum") {
            newCount += value
        }
        if (type === "diff") {
            newCount -= value
        }

        if (id === null) {
            transaction.set(
                fi
                    .firestore()
                    .collection("show_phone_logs_aggregation")
                    .doc(),
                {
                    type: "user",
                    period: "total",
                    uid,
                    value: newCount,
                    created_at_ts: Date.now()
                }
            )
        }

        if (id !== null) {
            transaction.update(
                fi
                    .firestore()
                    .collection("show_phone_logs_aggregation")
                    .doc(id),
                {
                    value: newCount,
                    created_at_ts: Date.now()
                }
            )
        }

        return newCount
    }).then(newCount => {
        return redis.set(`show_phone_logs_aggregation_${uid}`, JSON.stringify(newCount)).then(res => {
            // console.log('REDIS SET: ', res)
        })
    })
}

export const updateShowPhoneLogsAggregationByProspection = async (
    uid,
    prospectionId,
    type,
    value,
    forceUpdate = false,
    runtimeConfig = null,
    serverInstance = null
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    return fi.firestore().runTransaction(async transaction => {
        let querySnapshot = await transaction.get(
            fi
                .firestore()
                .collection("show_phone_logs_aggregation")
                .where("type", "==", "prospection")
                .where("period", "==", "total")
                .where("uid", "==", uid)
                .where("prospection_id", "==", prospectionId)
        )

        let id = null
        let count = null
        if (querySnapshot.size === 0) {
            let querySnapshot2 = await fi
                .firestore()
                .collection("show_phone_logs")
                .where("uid", "==", uid)
                .where("prospection_id", "==", prospectionId)
                .get()
            count = querySnapshot2.size
        } else {
            querySnapshot.forEach(doc => {
                id = doc.id
                count = doc.data().value
            })
            if (forceUpdate) {
                let querySnapshot2 = await fi
                .firestore()
                .collection("show_phone_logs")
                .where("uid", "==", uid)
                .where("prospection_id", "==", prospectionId)
                .get()
                count = querySnapshot2.size
            }
        }

        let newCount = count

        if (type === "sum") {
            newCount += value
        }
        if (type === "diff") {
            newCount -= value
        }

        if (id === null) {
            transaction.set(
                fi
                    .firestore()
                    .collection("show_phone_logs_aggregation")
                    .doc(),
                {
                    type: "prospection",
                    period: "total",
                    uid,
                    prospection_id: prospectionId,
                    value: newCount,
                    created_at_ts: Date.now()
                }
            )
        }

        if (id !== null) {
            transaction.update(
                fi
                    .firestore()
                    .collection("show_phone_logs_aggregation")
                    .doc(id),
                {
                    value: newCount,
                    created_at_ts: Date.now()
                }
            )
        }

        return newCount
    }).then(newCount => {
        return redis.set(`show_phone_logs_aggregation_p_${prospectionId}`, JSON.stringify(newCount)).then(res => {
            // console.log('REDIS SET: ', res)
        })
    })
}

export const getProspectionProposalCount = async (
    uid,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey =
        prospectionId ? `private_message_waiting_list_aggregation_p_${prospectionId}` : `private_message_waiting_list_aggregation_${uid}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR: ', error)
    }

    let db = fi
        .firestore()
        .collection("private_message_waiting_list_aggregation")
        .where("uid", "==", uid)
        .where("period", "==", "total")

    if (prospectionId) {
        db = db.where("type", "==", "prospection")
        db = db.where("prospection_id", "==", prospectionId)
    } else {
        db = db.where("type", "==", "user")
    }

    let querySnapshot = await db.limit(1).get()

    let count = null
    if (querySnapshot.size === 1) {
        querySnapshot.forEach(doc => {
            count = doc.data().value
        })
    }

    redis.set(redisKey, JSON.stringify(count)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return count
}

const updatePrivateMessageWaitingListAggregationByProspection = async (
    uid,
    prospectionId,
    type,
    value,
    runtimeConfig = null,
    serverInstance = null
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    return fi.firestore().runTransaction(async transaction => {
        let querySnapshot = await transaction.get(
            fi
                .firestore()
                .collection("private_message_waiting_list_aggregation")
                .where("type", "==", "prospection")
                .where("period", "==", "total")
                .where("uid", "==", uid)
                .where("prospection_id", "==", prospectionId)
        )

        let id = null
        let count = null
        if (querySnapshot.size === 0) {
            let querySnapshot2 = await fi
                .firestore()
                .collection("private_message_waiting_list")
                .where("to_uid", "==", uid)
                .where("customFields.prospection_id", "==", prospectionId)
                .get()
            count = querySnapshot2.size
        } else {
            querySnapshot.forEach(doc => {
                id = doc.id
                count = doc.data().value
            })
        }

        let newCount = count

        if (type === "sum") {
            newCount += value
        }
        if (type === "diff") {
            newCount -= value
        }

        if (id === null) {
            transaction.set(
                fi
                    .firestore()
                    .collection("private_message_waiting_list_aggregation")
                    .doc(),
                {
                    type: "prospection",
                    period: "total",
                    uid,
                    prospection_id: prospectionId,
                    value: newCount,
                    created_at_ts: Date.now()
                }
            )
        }

        if (id !== null) {
            transaction.update(
                fi
                    .firestore()
                    .collection("private_message_waiting_list_aggregation")
                    .doc(id),
                {
                    value: newCount,
                    created_at_ts: Date.now()
                }
            )
        }

        return newCount
    }).then(newCount => {
        return redis.set(`private_message_waiting_list_aggregation_p_${prospectionId}`, JSON.stringify(newCount)).then(res => {
            // console.log('REDIS SET: ', res)
        })
    })
}

const modelProspectionFetch = async (
    type,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    if (type === 'firestore') {
        let publicRuntimeConfig = {}
        if (getConfig()) {
            publicRuntimeConfig = getConfig().publicRuntimeConfig
        } else {
            publicRuntimeConfig = runtimeConfig
        }
        const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
        const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
        const redis = redisInst
        const fi =
            firebaseInst === null
                ? firebaseInstance.init(publicRuntimeConfig)
                : firebaseInst
        const redisKey = `getProspectionFetch_${prospectionId}`
        // console.log('REDIS KEY: ', redisKey)

        if (refreshCache) {
            await redis.del(redisKey)
        }

        try {
            const res = await redis.get(redisKey)
            // console.log('REDIS GET: ', redisKey, res)

            if (res === null) {
                throw 'doesnt_exist'
            }

            return JSON.parse(res)
        } catch (error) {
            // console.log('REDIS ERROR:', error)
        }

        const doc = await fi
            .firestore()
            .collection('prospections')
            .doc(prospectionId)
            .get()

        let prospection = null
        if (doc.exists) {
            prospection = convertToDate(doc.data())

            if (prospection !== null && typeof prospection.locations === 'undefined') {
                prospection.locations = []
            }
        }

        redis.set(redisKey, JSON.stringify([prospection])).then(res => {
            // console.log('REDIS SET: ', res)
        })  

        return [prospection]
    }
}

export const modelProspectionPublicFetch = async (
    type,
    prospectionId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        if (type === "firestore") {
            const prospection = await modelProspectionFetch(
                type,
                prospectionId,
                runtimeConfig,
                serverInstance,
                refreshCache
            )

            if (prospection[0]) {
                const ps = [
                    modelGetProfileSimple(
                        "realtime",
                        prospection[0].user,
                        serverInstance,
                        refreshCache
                    ),
                    getViewsCountWithTest(
                        prospection[0].user,
                        prospectionId,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    ),
                    getPhoneViewsCountWithTest(
                        prospection[0].user,
                        prospectionId,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    ),
                    getProspectionProposalCountWithTest(
                        prospection[0].user,
                        prospectionId,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    )
                ]

                const results = await Promise.all(ps)

                return {
                    ...prospection[0],
                    userinfo: results[0],
                    view_stats: results[1],
                    phone_stats: results[2],
                    proposal_stats: results[3],
                    prospection_id: prospectionId
                }
            } else {
                throw "doesnt_exists"
            }
        } else if (type === "api") {
            const result = await instance.get("/gp2", {
                params: { p: prospectionId }
            })
            return result.data
        }
    } catch (e) {
        console.log("error prospection fetch: ", e)
        return Promise.reject(e)
    }
}

const modelProspectionFetchByReferenceId = async (
    type,
    referenceId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    if (type === 'firestore') {
        let publicRuntimeConfig = {}
        if (getConfig()) {
            publicRuntimeConfig = getConfig().publicRuntimeConfig
        } else {
            publicRuntimeConfig = runtimeConfig
        }
        const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
        const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
        const redis = redisInst
        const fi =
            firebaseInst === null
                ? firebaseInstance.init(publicRuntimeConfig)
                : firebaseInst
        const redisKey = `getProspectionFetchByReferenceId_${referenceId}`
        // console.log('REDIS KEY: ', redisKey)

        if (refreshCache) {
            await redis.del(redisKey)
        }

        try {
            const res = await redis.get(redisKey)
            // console.log('REDIS GET: ', redisKey, res)

            if (res === null) {
                throw 'doesnt_exist'
            }

            return JSON.parse(res)
        } catch (error) {
            // console.log('REDIS ERROR:', error)
        }

        const querySnapshot = await fi
            .firestore()
            .collection('prospections')
            .where("reference_id", "==", referenceId)
            .get()

        let prospection = null
        if (querySnapshot.size === 1) {
            querySnapshot.forEach(doc => {
                prospection = { id: doc.id, data: doc.data() }
            })

            if (typeof prospection.data.locations === 'undefined') {
                prospection.data.locations = []
            }
        }

        redis.set(redisKey, JSON.stringify(prospection)).then(res => {
            // console.log('REDIS SET: ', res)
        })  

        return prospection
    }
}

export const modelProspectionPublicFetchByReferenceId = async (
    type,
    referenceId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        if (type === "firestore") {
            const prospection = await modelProspectionFetchByReferenceId(
                type,
                referenceId,
                runtimeConfig,
                serverInstance,
                refreshCache
            )

            if (prospection) {
                const ps = [
                    modelGetProfileSimple(
                        "realtime",
                        prospection.data.user,
                        serverInstance,
                        refreshCache
                    ),
                    getViewsCountWithTest(
                        prospection.data.user,
                        prospection.id,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    ),
                    getPhoneViewsCountWithTest(
                        prospection.data.user,
                        prospection.id,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    ),
                    getProspectionProposalCountWithTest(
                        prospection.data.user,
                        prospection.id,
                        runtimeConfig,
                        serverInstance,
                        refreshCache
                    )
                ]
                const results = await Promise.all(ps)

                return {
                    ...prospection.data,
                    userinfo: results[0],
                    view_stats: results[1],
                    phone_stats: results[2],
                    proposal_stats: results[3],
                    prospection_id: prospection.id
                }
            } else {
                throw "doesnt_exists"
            }
        }
    } catch (e) {
        console.log("error prospection fetch by reference id: ", e)
        return Promise.reject(e)
    }
}

export const modelProspectionsStateMarkValidate = async (id, mark) => {
    const res = await instance.post("/psm", {
        type: "listing",
        id,
        mark
    })

    return res.data
}

export const modelProspectionsStateMarkFetch = async (
    id,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getProspectionsStateMark_${id}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    let doc = await fi
        .firestore()
        .collection("prospections_state_aggregate_mark")
        .doc(id)
        .get()

    let mark = null
    if (doc.exists) {
        mark = doc.data()
    }

    redis.set(redisKey, JSON.stringify(mark)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return mark
}

export const modelProspectionMarkValidate = async (id, mark) => {
    const res = await instance.post("/psm", {
        type: "prospection",
        id,
        mark
    })

    return res.data
}

export const modelProspectionMarkFetch = async (
    id,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    let publicRuntimeConfig = {}
    if (getConfig()) {
        publicRuntimeConfig = getConfig().publicRuntimeConfig
    } else {
        publicRuntimeConfig = runtimeConfig
    }
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const fi =
        firebaseInst === null
            ? firebaseInstance.init(publicRuntimeConfig)
            : firebaseInst
    const redis = redisInst
    const redisKey = `getProspectionMark_${id}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const doc = await fi
        .firestore()
        .collection("prospections_aggregate_mark")
        .doc(id)
        .get()

    let mark = null
    if (doc.exists) {
        mark = doc.data()
    }

    redis.set(redisKey, JSON.stringify(mark)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return mark
}

export const modelProspectionsViewInfosFetch = async (
    prospectionIds,
    serverInstance = null
) => {
    const instance = axios.create({
        baseURL: (serverInstance ? `https://${getConfig().publicRuntimeConfig.websiteDomain}` : '') + "/api",
        timeout: 30000
    })
    const results = await instance.post("/pvif", {
        prospection_ids: prospectionIds
    })

    return results.data
}