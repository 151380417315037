export default [
    {
        name: 'Bretagne',
        code: '53',
        coordinates: [-2.838611111111111, 48.1797222]
    },
    {
        name: 'Centre-Val de Loire',
        code: '24',
        coordinates: [1.6852777777777779, 47.4805556]
    },
    {
        name: 'Corse',
        code: '94',
        coordinates: [9.105277777777777, 42.1497222]
    },
    {
        name: 'Île-de-France',
        code: '11',
        coordinates: [2.504722222222222, 48.7091667]
    },
    {
        name: 'Pays de la Loire',
        code: '52',
        coordinates: [-0.8238888888888889, 47.4747222]
    },
    {
        name: "Provence-Alpes-Côte d'Azur",
        code: '93',
        coordinates: [6.053333333333333, 43.955]
    },
    {
        name: 'Bourgogne-Franche-Comté',
        code: '27',
        coordinates: [4.809166666666666, 47.2352778]
    },
    {
        name: 'Nouvelle-Aquitaine',
        code: '75',
        coordinates: [0.19777777777777777, 45.1922222]
    },
    {
        name: 'Normandie',
        code: '28',
        coordinates: [0.10666666666666667, 49.1211111]
    },
    {
        name: 'Grand Est',
        code: '44',
        coordinates: [5.619444444444445, 48.6891667]
    },
    {
        name: 'Occitanie',
        code: '76',
        coordinates: [2.1372222222222224, 43.7022222]
    },
    {
        name: 'Hauts-de-France',
        code: '32',
        coordinates: [2.7752777777777777, 49.9661111]
    },
    {
        name: 'Auvergne-Rhône-Alpes',
        code: '84',
        coordinates: [4.538055555555555, 45.5158333]
    }
]
