export default [
    {
        "state_code": "84",
        "name": "en Auvergne-Rhône-Alpes"
    },
    {
        "state_code": "27",
        "name": "en Bourgogne-Franche-Comté"
    },
    {
        "state_code": "53",
        "name": "en Bretagne"
    },
    {
        "state_code": "24",
        "name": "dans le Centre-Val de Loire"
    },
    {
        "state_code": "94",
        "name": "en Corse"
    },
    {
        "state_code": "44",
        "name": "dans le Grand Est"
    },
    {
        "state_code": "32",
        "name": "dans les Hauts-de-France"
    },
    {
        "state_code": "11",
        "name": "en Île-de-France"
    },
    {
        "state_code": "28",
        "name": "en Normandie"
    },
    {
        "state_code": "75",
        "name": "en Nouvelle-Aquitaine"
    },
    {
        "state_code": "76",
        "name": "en Occitanie"
    },
    {
        "state_code": "52",
        "name": "dans les Pays de la Loire"
    },
    {
        "state_code": "93",
        "name": "en Provence-Alpes-Côte d'Azur"
    }
]