import _ from "lodash"
import firebaseInstance from "../config/firebase"
import algoliasearch from "algoliasearch"
import departments from "../datas/departments"

const reflect = p =>
    p.then(
        v => ({ v, status: 'fulfilled' }),
        e => ({ e, status: 'rejected' })
    )

const modelGetProfileBase = async (
    userId,
    serverInstance = null,
    refreshCache = false
) => {
    const redisKey = `getProfileBase_${userId}`
    // console.log('REDIS KEY: ', redisKey)
  
    if (refreshCache) {
      await redis.del(redisKey)
    }
  
    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const fi = firebaseInst === null ? firebaseInstance.init() : firebaseInst
    return new Promise((resolve, reject) => {
      redis.get(redisKey, (error, profileBase) => {
        // console.log('REDIS GET: ', profileBase)
        if(!error && profileBase) {
          return resolve(JSON.parse(profileBase))
        }
        // console.log('REDIS ERROR:', error)
  
        fi
          .database()
          .ref(`/profile/${userId}`)
          .once('value')
          .then(snapshot => {
            const profileBase = snapshot.val()
    
            redis.set(redisKey, JSON.stringify(profileBase)).then(res => {
              // console.log('REDIS SET: ', res)
            })
  
            resolve(profileBase)
          }).catch(error => {
            reject(error)
          })
      })
    })
  }
  
const modelGetProfileIdentity = async (
    userId,
    serverInstance = null,
    refreshCache = false
) => {
    const redisKey = `getProfileIdentity_${userId}`
    // console.log('REDIS KEY: ', redisKey)
  
    if (refreshCache) {
      await redis.del(redisKey)
    }

    const firebaseInst = serverInstance && serverInstance.firebase ? serverInstance.firebase : null
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const fi = firebaseInst === null ? firebaseInstance.init() : firebaseInst
    return new Promise((resolve, reject) => {
      redis.get(redisKey, (error, profileIdentity) => {
        // console.log('REDIS GET: ', profileIdentity)
        if(!error && profileIdentity) {
          return resolve(JSON.parse(profileIdentity))
        }
        // console.log('REDIS ERROR:', error)
  
        fi
          .database()
          .ref(`/profile_identity/${userId}`)
          .once('value')
          .then(snapshot => {
            const profileIdentity = snapshot.val()
    
            redis.set(redisKey, JSON.stringify(profileIdentity)).then(res => {
              // console.log('REDIS SET: ', res)
            })
  
            resolve(profileIdentity)
          }).catch(error => {
            reject(error)
          })
      })
    })
  }
  
const modelGetProfileSimple = async (
    type,
    userId,
    serverInstance = null,
    refreshCache = false
) => {
    if (type == 'realtime') {
        const redisKey = `modelGetProfileSimple_${type}_${userId}`
        // console.log('REDIS KEY: ', redisKey)

        if (refreshCache) {
            await redis.del(redisKey)
        }
        
        const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
        const redis = redisInst
        return new Promise((resolve, reject) => {
            redis.get(redisKey, (error, profile) => {
                // console.log('REDIS GET: ', profile)
                if(!error && profile) {
                    let profileNew = JSON.parse(profile)
                    if (!profileNew.statusV2) {
                        /* { key: 3, name: "Porteur de projet" },
                        { key: 1, name: "Indépendant" },
                        { key: 2, name: "Enseigne" } */
                        profileNew.statusV2 = null
                        if (profileNew.status === 2) {
                            profileNew.statusV2 = 'enseigne-commerciale'
                        }
                        if (profileNew.status === 1) {
                            profileNew.statusV2 = 'commercant-independant'
                        }
                        if (profileNew.status === 3) {
                            profileNew.statusV2 = 'createur-entreprise'
                        }
                        if (profileNew.franchisee) {
                            profileNew.statusV2 = 'franchise-affilie'
                        }
                        if (profileNew.external) {
                            profileNew.statusV2 = 'developpeur-externe'
                        }
                    }
                    if (!profileNew.liberalProfession) profileNew.liberalProfession = null
                    
                    return resolve(profileNew)
                }
                // console.log('REDIS ERROR:', error)

                let p = [
                    modelGetProfileBase(userId, serverInstance, refreshCache),
                    modelGetProfileIdentity(userId, serverInstance, refreshCache)
                ]

                const toResultObject = promise => {
                    return promise
                        .then(result => ({ success: true, result }))
                        .catch(error => ({ success: false, error }))
                }

                return Promise.all(p.map(toResultObject))
                .then(values => {
                    let profile = {}
                    let identity = {
                        avatarURL: '',
                        firstname: '',
                        lastname: '',
                        currentPosition: '',
                        customCurrentPosition: ''
                    }
                    let rating = { rating: {} }

                    if (values[0].success) {
                        profile = values[0].result
                    } else {
                        reject(values[0].error)
                    }

                    if (typeof profile === 'undefined' || profile === null) {
                        reject('Undefined profile')
                    }

                    if (values[1].success) {
                        identity = values[1].result
                        delete identity.lastname
                        delete identity.firstname
                    }

                    if (!profile.activity) profile.activity = 2
                    if (!profile.locations) profile.locations = []
                    if (!profile.locations2) profile.locations2 = []
                    if (!profile.status) profile.status = 2
                    if (!profile.statusV2) {
                        /* { key: 3, name: "Porteur de projet" },
                        { key: 1, name: "Indépendant" },
                        { key: 2, name: "Enseigne" } */
                        profile.statusV2 = null
                        if (profile.status === 2) {
                            profile.statusV2 = 'enseigne-commerciale'
                        }
                        if (profile.status === 1) {
                            profile.statusV2 = 'commercant-independant'
                        }
                        if (profile.status === 3) {
                            profile.statusV2 = 'createur-entreprise'
                        }
                        if (profile.franchisee) {
                            profile.statusV2 = 'franchise-affilie'
                        }
                        if (profile.external) {
                            profile.statusV2 = 'developpeur-externe'
                        }
                    }
                    if (!profile.liberalProfession) profile.liberalProfession = null
                    if (!profile.franchisee) profile.franchisee = false
                    if (!profile.deleted) profile.deleted = false
                    if (!profile.deleted_at_ts) profile.deleted_at_ts = null

                    const final = _.assign(
                        {},
                        profile,
                        identity,
                        rating,
                        { uid: userId }
                    )

                    redis.set(redisKey, JSON.stringify(final)).then(res => {
                        // console.log('REDIS SET: ', res)
                    })

                    resolve(final)
                })
                .catch(error => {
                    reject(error)
                })
            })
        })
    } else if (type == 'firestore') {
      return Promise.reject('No implementation yet!')
    }
}

const modelGetProfileSimpleWithIdentity = async (
    type,
    userId,
    serverInstance = null,
    refreshCache = false
) => {
    if (type == 'realtime') {
        const redisKey = `modelGetProfileSimpleWithIdentity_${type}_${userId}`
        // console.log('REDIS KEY: ', redisKey)

        if (refreshCache) {
            await redis.del(redisKey)
        }
        
        const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
        const redis = redisInst
        return new Promise((resolve, reject) => {
            redis.get(redisKey, (error, profile) => {
                // console.log('REDIS GET: ', profile)
                if(!error && profile) {
                    let profileNew = JSON.parse(profile)
                    if (!profileNew.statusV2) {
                        /* { key: 3, name: "Porteur de projet" },
                        { key: 1, name: "Indépendant" },
                        { key: 2, name: "Enseigne" } */
                        profileNew.statusV2 = null
                        if (profileNew.status === 2) {
                            profileNew.statusV2 = 'enseigne-commerciale'
                        }
                        if (profileNew.status === 1) {
                            profileNew.statusV2 = 'commercant-independant'
                        }
                        if (profileNew.status === 3) {
                            profileNew.statusV2 = 'createur-entreprise'
                        }
                        if (profileNew.franchisee) {
                            profileNew.statusV2 = 'franchise-affilie'
                        }
                        if (profileNew.external) {
                            profileNew.statusV2 = 'developpeur-externe'
                        }
                    }
                    if (!profileNew.liberalProfession) profileNew.liberalProfession = null
                    
                    return resolve(profileNew)
                }
                // console.log('REDIS ERROR:', error)

                let p = [
                    modelGetProfileBase(userId, serverInstance, refreshCache),
                    modelGetProfileIdentity(userId, serverInstance, refreshCache)
                ]

                const toResultObject = promise => {
                    return promise
                        .then(result => ({ success: true, result }))
                        .catch(error => ({ success: false, error }))
                }

                return Promise.all(p.map(toResultObject))
                .then(values => {
                    let profile = {}
                    let identity = {
                        avatarURL: '',
                        firstname: '',
                        lastname: '',
                        currentPosition: '',
                        customCurrentPosition: ''
                    }
                    let rating = { rating: {} }

                    if (values[0].success) {
                        profile = values[0].result
                    } else {
                        reject(values[0].error)
                    }

                    if (typeof profile === 'undefined' || profile === null) {
                        reject('Undefined profile')
                    }

                    if (values[1].success) {
                        identity = values[1].result
                    }

                    if (!profile.activity) profile.activity = 2
                    if (!profile.locations) profile.locations = []
                    if (!profile.locations2) profile.locations2 = []
                    if (!profile.status) profile.status = 2
                    if (!profile.statusV2) {
                        /* { key: 3, name: "Porteur de projet" },
                        { key: 1, name: "Indépendant" },
                        { key: 2, name: "Enseigne" } */
                        profile.statusV2 = null
                        if (profile.status === 2) {
                            profile.statusV2 = 'enseigne-commerciale'
                        }
                        if (profile.status === 1) {
                            profile.statusV2 = 'commercant-independant'
                        }
                        if (profile.status === 3) {
                            profile.statusV2 = 'createur-entreprise'
                        }
                        if (profile.franchisee) {
                            profile.statusV2 = 'franchise-affilie'
                        }
                        if (profile.external) {
                            profile.statusV2 = 'developpeur-externe'
                        }
                    }
                    if (!profile.liberalProfession) profile.liberalProfession = null
                    if (!profile.franchisee) profile.franchisee = false
                    if (!profile.deleted) profile.deleted = false
                    if (!profile.deleted_at_ts) profile.deleted_at_ts = null

                    const final = _.assign(
                        {},
                        profile,
                        identity,
                        rating,
                        { uid: userId }
                    )

                    redis.set(redisKey, JSON.stringify(final)).then(res => {
                        // console.log('REDIS SET: ', res)
                    })

                    resolve(final)
                })
                .catch(error => {
                    reject(error)
                })
            })
        })
    } else if (type == 'firestore') {
      return Promise.reject('No implementation yet!')
    }
}

const modelGetProfileSimpleList = async (type, userIds, serverInstance = null, refreshCache = false) => {
    if (refreshCache) {
        return Promise.all(
            userIds
                .map(id => modelGetProfileSimple(type, id, serverInstance, true))
                .map(reflect)
        )
    }
  
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const results = await redis
        .pipeline(
            userIds.map(k => {
                return ['get', `modelGetProfileSimple_${type}_${k}`]
            })
        )
        .exec()
  
    let finalResults = []
    for (let i = 0; i < results.length; i++) {
        const error = results[i][0]
        const result = results[i][1]
        if (error || result === null) {
            finalResults.push(modelGetProfileSimple(type, userIds[i], serverInstance, refreshCache))
            continue
        }
        finalResults.push(Promise.resolve(JSON.parse(result)))
    }
  
    return Promise.all(finalResults.map(reflect))
}

const modelGetProfileSimpleWithIdentityList = async (type, userIds, serverInstance = null, refreshCache = false) => {
    if (refreshCache) {
        return Promise.all(
            userIds
                .map(id => modelGetProfileSimpleWithIdentity(type, id, serverInstance, true))
                .map(reflect)
        )
    }
  
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const results = await redis
        .pipeline(
            userIds.map(k => {
                return ['get', `modelGetProfileSimpleWithIdentity_${type}_${k}`]
            })
        )
        .exec()
  
    let finalResults = []
    for (let i = 0; i < results.length; i++) {
        const error = results[i][0]
        const result = results[i][1]
        if (error || result === null) {
            finalResults.push(modelGetProfileSimpleWithIdentity(type, userIds[i], serverInstance, refreshCache))
            continue
        }
        finalResults.push(Promise.resolve(JSON.parse(result)))
    }
  
    return Promise.all(finalResults.map(reflect))
}

const modelGetProfiles = async (
    type,
    userinfo,
    locations = [],
    search = "",
    filters = {},
    page = 0,
    hitsPerPage = 30,
    reloadAll = false,
    publicRuntimeConfig = null
) => {
  if (type === "algolia") {
      let suffix = null
      if (userinfo !== "search") {
          suffix =
              userinfo.role_type +
              (userinfo.role_type === 1 ? "_" + userinfo.activity : "")
      }
      if (userinfo === "search") {
          suffix = "search"
      }
      let p = []
      let clientProfile = algoliasearch(
        publicRuntimeConfig.algoliaApplicationID,
        publicRuntimeConfig.algoliaSearchOnlyAPIKey
      )
      let indexProfile = clientProfile.initIndex(`${publicRuntimeConfig.algoliaPrefix}${publicRuntimeConfig.algoliaRoleTypeBaseIndexName}_${suffix}`)
      let config = {}
      // BEGIN FILTERS
      let filtersList = []
      let users = []

      if (!reloadAll) {
          config = {
              query: search,
              page,
              hitsPerPage
          }
      } else {
          config = {
              query: search,
              page: 0,
              hitsPerPage: (page + 1) * hitsPerPage
          }
      }

      /* if (typeof filters.admin !== "undefined") {
          filtersList.push(`profile.admin = ${filters.admin ? 1 : 0}`)
      } */
      if (typeof filters.objectID !== "undefined") {
          filtersList.push(`objectID:${filters.objectID}`)
      }

      if (typeof filters.aroundLocation !== "undefined") {
          // TEMPORARY FIX
          let lat =
              filters.aroundLocation.lat < filters.aroundLocation.long
                  ? filters.aroundLocation.long
                  : filters.aroundLocation.lat
          let long =
              filters.aroundLocation.lat < filters.aroundLocation.long
                  ? filters.aroundLocation.lat
                  : filters.aroundLocation.long
          config.aroundLatLng = lat + ", " + long
          config.aroundRadius = 100000
          /* config.optionalFilters = [
              "address_locations_id_commune_" +
                  filters.aroundLocation.code +
                  "_" +
                  filters.aroundLocation.codePostal
          ] */
          config.getRankingInfo = true
      }

      if (typeof filters.counts !== "undefined") {
          if (filters.counts.state && filters.counts.type) {
              if (filters.counts.state === "all") {
                  filtersList.push(`counts.${filters.counts.type}.total > 0`)
              }
              if (
                  filters.counts.state !== "all" &&
                  codeRegions[filters.counts.state]
              ) {
                  filtersList.push(
                      `counts.${filters.counts.type}.state.${
                          codeRegions[filters.counts.state]
                      } > 0`
                  )
              }
          }
      }

      p.push(
          new Promise((resolve, reject) => {
              // Location filters
              if (locations.length > 0)
                  filtersList.push(
                      "( " +
                          locations.join(" OR ") +
                          " OR _tags:matchAllLocations )"
                  )

              // Criterias filters
              if (
                  typeof filters.typeFilter !== "undefined" &&
                  filters.typeFilter.length > 0
              ) {
                  var typeFilterList = []

                  if (filters.typeFilter.indexOf(1) !== -1) {
                      if (filters.typeFilter.indexOf(4) !== -1) {
                          typeFilterList.push(
                              "profile.role_type = 1 AND profile.activity = 1"
                          )
                      } else if (filters.typeFilter.indexOf(5) !== -1) {
                          typeFilterList.push(
                              "profile.role_type = 1 AND profile.activity = 2"
                          )
                      } else {
                          typeFilterList.push("profile.role_type = 1")
                      }
                  }

                  if (filters.typeFilter.indexOf(2) !== -1) {
                      typeFilterList.push(
                          "profile.role_type = 2 AND profile.status = 2"
                      )
                  }

                  if (filters.typeFilter.indexOf(3) !== -1) {
                      typeFilterList.push("profile.role_type = 3")
                  }

                  if (filters.typeFilter.indexOf(6) !== -1) {
                      typeFilterList.push(
                          "profile.role_type = 2 AND profile.status = 1"
                      )
                  }

                  if (filters.typeFilter.indexOf(7) !== -1) {
                      typeFilterList.push(
                          "profile.role_type = 2 AND profile.status = 3"
                      )
                  }

                  if (filters.typeFilter.indexOf(8) !== -1) {
                      typeFilterList.push("profile.role_type = 2")
                  }

                  if (typeFilterList.length > 0)
                      filtersList.push(
                          "( " + typeFilterList.join(" OR ") + " )"
                      )
              }

              if (
                  typeof filters.activities !== "undefined" &&
                  filters.activities.length > 0
              ) {
                  var activitiesList = []

                  for (var i = 0; i < filters.activities.length; i++) {
                      activitiesList.push(
                          "profile.activityBrands = " + filters.activities[i]
                      )
                  }

                  if (activitiesList.length > 0)
                      filtersList.push(
                          "( " + activitiesList.join(" OR ") + " )"
                      )
              }

              if (
                  typeof filters.codeRegion !== "undefined"
              ) {
                  filtersList.push(`_tags:address_locations_codeRegion_${filters.codeRegion}`)
              }
              if (
                typeof filters.departmentCode !== "undefined"
              ) {
                  filtersList.push(`_tags:address_locations_codeDepartement_${filters.departmentCode}`)
              }

              if (userinfo.role_type === 1) {
                  filtersList.push("NOT profile.role_type = 2")
                  filtersList.push("NOT profile.role_type = 3")
              } else if (userinfo.role_type === 3) {
                  filtersList.push("NOT profile.role_type = 1")
              } else if (userinfo.role_type === 2) {
                  filtersList.push("NOT profile.role_type = 2")
              }

              if (filters.deleted !== "undefined") {
                filtersList.push(`${!filters.deleted ? 'NOT ' : ''}_tags:deleted`)
              }

              if (filtersList.length > 0) {
                  var filtersConditions = filtersList.join(" AND ")

                  config.filters = filtersConditions
              }

              indexProfile.clearCache()
              indexProfile.search(config, (error, content) => {
                  if (error) reject(error)

                  for (let h in content.hits) {
                      if (
                          typeof filters.admin !== "undefined" &&
                          filters.admin === false &&
                          content.hits[h] &&
                          content.hits[h].profile &&
                          content.hits[h].profile.admin
                      ) {
                          continue
                      }
                      users.push({
                          ...content.hits[h].profile,
                          rankingInfo: content.hits[h]._rankingInfo
                              ? content.hits[h]._rankingInfo
                              : undefined,
                          objectID: content.hits[h].objectID
                      })
                  }

                  resolve([users, page, content.nbHits])
              })
          })
      )

      return Promise.all(p)
  }
}

const getProfilesCountByState = async (
    type,
    codeRegion,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getProfilesCountByState:${type.join('_')}:${codeRegion}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const results = await modelGetProfiles(
        "algolia",
        "search",
        [],
        '',
        {
            typeFilter: type,
            codeRegion,
            deleted: false
        },
        0,
        1,
        false,
        runtimeConfig
    )

    redis.set(redisKey, JSON.stringify(results[0][2]), 'EX', 60 * 60 * 24 * 7).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return results[0][2]
}

const getProfilesCountByDepartment = async (
    type,
    departmentCode,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getProfilesCountByDepartment:${type.join('_')}:${departmentCode}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const results = await modelGetProfiles(
        "algolia",
        "search",
        [],
        '',
        {
            typeFilter: type,
            departmentCode,
            deleted: false
        },
        0,
        1,
        false,
        runtimeConfig
    )

    redis.set(redisKey, JSON.stringify(results[0][2]), 'EX', 60 * 60 * 24 * 7).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return results[0][2]
}

const getProfilesCount = async (
    type,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst
    const redisKey = `getProfilesCount:${type.join('_')}`
    // console.log('REDIS KEY: ', redisKey)

    if (refreshCache) {
        await redis.del(redisKey)
    }

    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }

    const results = await modelGetProfiles(
        "algolia",
        "search",
        [],
        '',
        {
            typeFilter: type,
            deleted: false
        },
        0,
        1,
        false,
        runtimeConfig
    )

    redis.set(redisKey, JSON.stringify(results[0][2]), 'EX', 60 * 60 * 24 * 7).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return results[0][2]
}

export {
    modelGetProfiles,
    modelGetProfileBase,
    modelGetProfileIdentity,
    modelGetProfileSimple,
    modelGetProfileSimpleList,
    getProfilesCountByState,
    getProfilesCountByDepartment,
    getProfilesCount,
    modelGetProfileSimpleWithIdentity,
    modelGetProfileSimpleWithIdentityList
}