export default [
    {
        word_link: "dans le département du",
        department_code: 67
    },
    {
        word_link: "dans le département du",
        department_code: 68
    },
    {
        word_link: "en",
        department_code: 24
    },
    {
        word_link: "en",
        department_code: 33
    },
    {
        word_link: "dans le département des",
        department_code: 40
    },
    {
        word_link: "dans le département du",
        department_code: 47
    },
    {
        word_link: "dans le département des",
        department_code: 64
    },
    {
        word_link: "dans l'",
        department_code: 3
    },
    {
        word_link: "dans le département du",
        department_code: 15
    },
    {
        word_link: "en",
        department_code: 43
    },
    {
        word_link: "dans le département du",
        department_code: 63
    },
    {
        word_link: "dans le département du",
        department_code: 14
    },
    {
        word_link: "dans l'",
        department_code: 61
    },
    {
        word_link: "en",
        department_code: 21
    },
    {
        word_link: "dans le départment de la",
        department_code: 58
    },
    {
        word_link: "dans le départment de la",
        department_code: 71
    },
    {
        word_link: "dans l'",
        department_code: 89
    },
    {
        word_link: "dans le département des",
        department_code: 22
    },
    {
        word_link: "dans le département du",
        department_code: 29
    },
    {
        word_link: "dans l'",
        department_code: 35
    },
    {
        word_link: "dans le département du",
        department_code: 56
    },
    {
        word_link: "dans le département du",
        department_code: 18
    },
    {
        word_link: "en",
        department_code: 28
    },
    {
        word_link: "dans l'",
        department_code: 36
    },
    {
        word_link: "dans l'",
        department_code: 37
    },
    {
        word_link: "dans le département du",
        department_code: 45
    },
    {
        word_link: "dans le département du",
        department_code: 41
    },
    {
        word_link: "dans le département des",
        department_code: 8
    },
    {
        word_link: "dans l'",
        department_code: 10
    },
    {
        word_link: "dans la",
        department_code: 52
    },
    {
        word_link: "dans la",
        department_code: 51
    },
    {
        word_link: "en",
        department_code: "2A"
    },
    {
        word_link: "en",
        department_code: "2B"
    },
    {
        word_link: "dans le département du",
        department_code: 25
    },
    {
        word_link: "dans la",
        department_code: 70
    },
    {
        word_link: "dans le département du",
        department_code: 39
    },
    {
        word_link: "en",
        department_code: 90
    },
    {
        word_link: "dans l'",
        department_code: 27
    },
    {
        word_link: "en",
        department_code: 76
    },
    {
        word_link: "dans l'",
        department_code: 91
    },
    {
        word_link: "dans le département des",
        department_code: 92
    },
    {
        word_link: "à",
        department_code: 75
    },
    {
        word_link: "en",
        department_code: 77
    },
    {
        word_link: "en",
        department_code: 93
    },
    {
        word_link: "dans le département du",
        department_code: 94
    },
    {
        word_link: "dans le département du",
        department_code: 95
    },
    {
        word_link: "dans le département des",
        department_code: 78
    },
    {
        word_link: "dans l'",
        department_code: 11
    },
    {
        word_link: "dans le département du",
        department_code: 30
    },
    {
        word_link: "dans le département de l'",
        department_code: 34
    },
    {
        word_link: "en",
        department_code: 48
    },
    {
        word_link: "dans le département des",
        department_code: 66
    },
    {
        word_link: "en",
        department_code: 19
    },
    {
        word_link: "dans la",
        department_code: 23
    },
    {
        word_link: "dans la",
        department_code: 87
    },
    {
        word_link: "en",
        department_code: 54
    },
    {
        word_link: "dans la",
        department_code: 55
    },
    {
        word_link: "en",
        department_code: 57
    },
    {
        word_link: "dans le département des",
        department_code: 88
    },
    {
        word_link: "dans l'",
        department_code: 9
    },
    {
        word_link: "dans l'",
        department_code: 12
    },
    {
        word_link: "dans le département du",
        department_code: 32
    },
    {
        word_link: "en",
        department_code: 31
    },
    {
        word_link: "dans le département des",
        department_code: 65
    },
    {
        word_link: "dans le département du",
        department_code: 46
    },
    {
        word_link: "dans le département du",
        department_code: 81
    },
    {
        word_link: "dans le département du",
        department_code: 82
    },
    {
        word_link: "dans le département du",
        department_code: 59
    },
    {
        word_link: "dans le département du",
        department_code: 62
    },
    {
        word_link: "dans la",
        department_code: 50
    },
    {
        word_link: "en",
        department_code: 44
    },
    {
        word_link: "en",
        department_code: 49
    },
    {
        word_link: "en",
        department_code: 53
    },
    {
        word_link: "dans la",
        department_code: 72
    },
    {
        word_link: "en",
        department_code: 85
    },
    {
        word_link: "dans l'",
        department_code: 2
    },
    {
        word_link: "dans l'",
        department_code: 60
    },
    {
        word_link: "dans la",
        department_code: 80
    },
    {
        word_link: "en",
        department_code: 16
    },
    {
        word_link: "en",
        department_code: 17
    },
    {
        word_link: "dans le département",
        department_code: 79
    },
    {
        word_link: "dans la",
        department_code: 86
    },
    {
        word_link: "dans le département des",
        department_code: 4
    },
    {
        word_link: "dans le département des",
        department_code: 6
    },
    {
        word_link: "dans le département des",
        department_code: 13
    },
    {
        word_link: "dans le département des",
        department_code: 5
    },
    {
        word_link: "dans le département du",
        department_code: 83
    },
    {
        word_link: "dans le département du",
        department_code: 84
    },
    {
        word_link: "dans l'",
        department_code: 1
    },
    {
        word_link: "dans l'",
        department_code: 7
    },
    {
        word_link: "dans la",
        department_code: 26
    },
    {
        word_link: "en",
        department_code: 74
    },
    {
        word_link: "en",
        department_code: 38
    },
    {
        word_link: "dans le département de la",
        department_code: 42
    },
    {
        word_link: "dans le département du",
        department_code: 69
    },
    {
        word_link: "en",
        department_code: 73
    }
]
