import axios from "../config/axios"
import hash from "object-hash"

import {
    PROSPECTIONS_STATE_FETCH,
    PROSPECTIONS_STATE_FETCHED,
    PROSPECTIONS_STATE_RESET,
    PROSPECTIONS_STATE_MARK_CHANGED,
    SET_CURRENT_PARAMETERS,
    PROSPECTIONS_COUNT_FETCH,
    PROSPECTIONS_COUNT_FETCHED,
    PROSPECTIONS_COUNT_ERROR,
    PROSPECTIONS_STATE_MARK_VALIDATE,
    PROSPECTIONS_STATE_MARK_VALIDATED,
    PROSPECTIONS_STATE_MARK_ERROR,
    PROSPECTIONS_STATE_MARK_FETCHED,
    CITIES_PUBLIC_PAGE_FETCH,
    CITIES_PUBLIC_PAGE_FETCHED,
    CITIES_PUBLIC_PAGE_ERROR,
    CITIES_PUBLIC_PAGE_FETCH_V2,
    CITIES_PUBLIC_PAGE_FETCHED_V2,
    CITIES_PUBLIC_PAGE_ERROR_V2,
    DEPARTMENTS_PUBLIC_PAGE_FETCH_V2,
    DEPARTMENTS_PUBLIC_PAGE_FETCHED_V2,
    DEPARTMENTS_PUBLIC_PAGE_ERROR_V2,
    STATES_PUBLIC_PAGE_FETCH_V2,
    STATES_PUBLIC_PAGE_FETCHED_V2,
    STATES_PUBLIC_PAGE_ERROR_V2,
    COUNTRIES_PUBLIC_PAGE_FETCH_V2,
    COUNTRIES_PUBLIC_PAGE_FETCHED_V2,
    COUNTRIES_PUBLIC_PAGE_ERROR_V2,
    DEPARTMENTS_PUBLIC_PAGE_FETCH,
    DEPARTMENTS_PUBLIC_PAGE_FETCHED,
    DEPARTMENTS_PUBLIC_PAGE_ERROR,
    STATES_PUBLIC_PAGE_FETCH,
    STATES_PUBLIC_PAGE_FETCHED,
    STATES_PUBLIC_PAGE_ERROR,
    PROSPECTIONS_VIEW_INFOS_FETCH,
    PROSPECTIONS_VIEW_INFOS_FETCHED
} from "./types"

import {
    modelProspectionsStateCountFetch,
    modelProspectionsStateFetch,
    modelProspectionsStateMarkValidate,
    modelProspectionsStateMarkFetch,
    modelProspectionsViewInfosFetch
} from "../models/Prospections"

import {
    modelCitiesPublicPageFetch,
    modelCitiesPublicPageFetchV2,
    modelDepartmentsPublicPageFetchV2,
    modelStatesPublicPageFetchV2,
    modelCountriesPublicPageFetchV2,
    modelDepartmentsPublicPageFetch,
    modelStatesPublicPageFetch
} from "../models/PublicPages"

const reflect = p =>
    p.then(
        v => ({ v, status: 'fulfilled' }),
        e => ({ e, status: 'rejected' })
    )

export const statesPublicPageFetch = (
    type,
    transactionType,
    stateCode,
    version
) => {
    return async dispatch => {
        dispatch({ type: STATES_PUBLIC_PAGE_FETCH })
        try {
            const instance = axios.create({
                baseURL: "/api",
                timeout: 30000
            })
            const res = await instance.get("/gspp", {
                params: {
                    type,
                    transaction: transactionType,
                    state_code: stateCode,
                    version
                }
            })
            dispatch({ type: STATES_PUBLIC_PAGE_FETCHED, payload: res.data })
        } catch (error) {
            console.log("error: ", error)
            dispatch({ type: STATES_PUBLIC_PAGE_ERROR, payload: error })
        }
    }
}

export const asyncStatesPublicPageFetch = async (
    type,
    transactionType,
    stateCode,
    version,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const content = await modelStatesPublicPageFetch(
            type,
            transactionType,
            stateCode,
            version,
            runtimeConfig,
            serverInstance
        )
        return { type: STATES_PUBLIC_PAGE_FETCHED, payload: content }
    } catch (error) {
        console.log("error: ", error)
        return { type: STATES_PUBLIC_PAGE_ERROR, payload: error }
    }
}

export const departmentsPublicPageFetch = (
    type,
    transactionType,
    departmentCode,
    version
) => {
    return async dispatch => {
        dispatch({ type: DEPARTMENTS_PUBLIC_PAGE_FETCH })
        try {
            const instance = axios.create({
                baseURL: "/api",
                timeout: 30000
            })
            const res = await instance.get("/gdpp", {
                params: {
                    type,
                    transaction: transactionType,
                    department_code: departmentCode,
                    version
                }
            })
            dispatch({
                type: DEPARTMENTS_PUBLIC_PAGE_FETCHED,
                payload: res.data
            })
        } catch (error) {
            console.log("error: ", error)
            dispatch({ type: DEPARTMENTS_PUBLIC_PAGE_ERROR, payload: error })
        }
    }
}

export const asyncDepartmentsPublicPageFetch = async (
    type,
    transactionType,
    departmentCode,
    version,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const content = await modelDepartmentsPublicPageFetch(
            type,
            transactionType,
            departmentCode,
            version,
            runtimeConfig,
            serverInstance
        )
        return { type: DEPARTMENTS_PUBLIC_PAGE_FETCHED, payload: content }
    } catch (error) {
        console.log("error: ", error)
        return { type: DEPARTMENTS_PUBLIC_PAGE_ERROR, payload: error }
    }
}

export const citiesPublicPageFetch = (
    type,
    transactionType,
    inseeCode,
    postalCode,
    version
) => {
    return async dispatch => {
        dispatch({ type: CITIES_PUBLIC_PAGE_FETCH })
        try {
            const instance = axios.create({
                baseURL: "/api",
                timeout: 30000
            })
            const res = await instance.get("/gcpp", {
                params: {
                    type,
                    transaction: transactionType,
                    insee_code: inseeCode,
                    postal_code: postalCode,
                    version
                }
            })
            dispatch({ type: CITIES_PUBLIC_PAGE_FETCHED, payload: res.data })
        } catch (error) {
            console.log("error: ", error)
            dispatch({ type: CITIES_PUBLIC_PAGE_ERROR, payload: error })
        }
    }
}

export const asyncCitiesPublicPageFetch = async (
    type,
    transactionType,
    inseeCode,
    postalCode,
    version,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const content = await modelCitiesPublicPageFetch(
            type,
            transactionType,
            inseeCode,
            postalCode,
            version,
            runtimeConfig,
            serverInstance
        )
        return { type: CITIES_PUBLIC_PAGE_FETCHED, payload: content }
    } catch (error) {
        console.log("error: ", error)
        return { type: CITIES_PUBLIC_PAGE_ERROR, payload: error }
    }
}

export const citiesPublicPageFetchV2 = (
    type,
    inseeCode,
    postalCode,
    version
) => {
    return async dispatch => {
        dispatch({ type: CITIES_PUBLIC_PAGE_FETCH_V2 })
        try {
            const instance = axios.create({
                baseURL: "/api",
                timeout: 30000
            })
            const res = await instance.get("/gcppv2", {
                params: {
                    type,
                    insee_code: inseeCode,
                    postal_code: postalCode,
                    version
                }
            })
            dispatch({ type: CITIES_PUBLIC_PAGE_FETCHED_V2, payload: res.data })
        } catch (error) {
            console.log("error: ", error)
            dispatch({ type: CITIES_PUBLIC_PAGE_ERROR_V2, payload: error })
        }
    }
}

export const asyncCitiesPublicPageFetchV2 = async (
    type,
    inseeCode,
    version,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const content = await modelCitiesPublicPageFetchV2(
            type,
            inseeCode,
            version,
            runtimeConfig,
            serverInstance
        )
        // console.log('content: ', content)
        return { type: CITIES_PUBLIC_PAGE_FETCHED_V2, payload: content }
    } catch (error) {
        console.log("error: ", error)
        return { type: CITIES_PUBLIC_PAGE_ERROR_V2, payload: error }
    }
}

export const asyncDepartmentsPublicPageFetchV2 = async (
    type,
    deptCode,
    version,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const content = await modelDepartmentsPublicPageFetchV2(
            type,
            deptCode,
            version,
            runtimeConfig,
            serverInstance
        )
        // console.log('content: ', content)
        return { type: DEPARTMENTS_PUBLIC_PAGE_FETCHED_V2, payload: content }
    } catch (error) {
        console.log("error: ", error)
        return { type: DEPARTMENTS_PUBLIC_PAGE_ERROR_V2, payload: error }
    }
}

export const asyncStatesPublicPageFetchV2 = async (
    type,
    stateCode,
    version,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const content = await modelStatesPublicPageFetchV2(
            type,
            stateCode,
            version,
            runtimeConfig,
            serverInstance
        )
        // console.log('content: ', content)
        return { type: STATES_PUBLIC_PAGE_FETCHED_V2, payload: content }
    } catch (error) {
        console.log("error: ", error)
        return { type: STATES_PUBLIC_PAGE_ERROR_V2, payload: error }
    }
}

export const asyncCountriesPublicPageFetchV2 = async (
    type,
    countryLabel,
    version,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const content = await modelCountriesPublicPageFetchV2(
            type,
            countryLabel,
            version,
            runtimeConfig,
            serverInstance
        )
        // console.log('content: ', content)
        return { type: COUNTRIES_PUBLIC_PAGE_FETCHED_V2, payload: content }
    } catch (error) {
        console.log("error: ", error)
        return { type: COUNTRIES_PUBLIC_PAGE_ERROR_V2, payload: error }
    }
}

export const prospectionsStateMarkChanged = mark => {
    return {
        type: PROSPECTIONS_STATE_MARK_CHANGED,
        payload: mark
    }
}

export const prospectionsStateMarkValidate = (id, mark) => {
    return async dispatch => {
        dispatch({
            type: PROSPECTIONS_STATE_MARK_VALIDATE
        })

        try {
            const res = await modelProspectionsStateMarkValidate(id, mark)

            dispatch({
                type: PROSPECTIONS_STATE_MARK_VALIDATED,
                payload: res
            })
        } catch (e) {
            dispatch({
                type: PROSPECTIONS_STATE_MARK_ERROR,
                payload: e
            })
        }
    }
}

export const asyncProspectionsStateMarkFetch = async (
    id,
    runtimeConfig = null,
    serverInstance = null
) => {
    const mark = await modelProspectionsStateMarkFetch(
        id,
        runtimeConfig,
        serverInstance
    )

    return { type: PROSPECTIONS_STATE_MARK_FETCHED, payload: mark }
}

export const prospectionsStateFetch = (
    state,
    locations,
    filters = {},
    distinct = false,
    page = 0,
    hitsPerPage = 20,
    reloadAll = false,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false
) => {
    return async dispatch => {
        try {
            dispatch({ type: PROSPECTIONS_STATE_FETCH })

            const results = await modelProspectionsStateFetch(
                "firestore",
                state,
                locations,
                filters,
                page,
                hitsPerPage,
                reloadAll,
                runtimeConfig,
                serverInstance,
                forceAPI,
                distinct
            )

            const result = results[0]

            dispatch({
                type: PROSPECTIONS_STATE_FETCHED,
                payload: [...result, state],
                ssr: false
            })
        } catch (error) {
            console.log("Error prospections state list: ", error)
        }
    }
}

export const asyncProspectionsStateFetch = async (
    state,
    locations,
    filters = {},
    distinct = false,
    page = 0,
    hitsPerPage = 20,
    reloadAll = false,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false
) => {
    try {
        let results = await modelProspectionsStateFetch(
            "firestore",
            state,
            locations,
            filters,
            page,
            hitsPerPage,
            reloadAll,
            runtimeConfig,
            serverInstance,
            forceAPI,
            distinct
        ).then(results => {
            results = results[0]
            return modelProspectionsViewInfosFetch(
                results[0].map(o => ([o.user, o.prospection_id])),
                serverInstance
            )
            .then(views => {
                for (let i = 0; i < results[0].length; i++) {
                    results[0][i] = {
                        ...results[0][i],
                        views_infos: views[results[0][i].prospection_id] ? views[results[0][i].prospection_id] : null
                    }
                }

                return [results]
            })
        })
        const result = results[0]
        // console.log('results: ', result[0])
        return {
            type: PROSPECTIONS_STATE_FETCHED,
            payload: [...result, state],
            ssr: true
        }
    } catch (error) {
        console.log("Error prospections state list: ", error)
    }
}

export const asyncProspectionsCountFetch = async (
    state,
    locations,
    filters = {},
    distinct = false,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false
) => {
    try {
        const results = await modelProspectionsStateCountFetch(
            "firestore",
            state,
            locations,
            filters,
            runtimeConfig,
            serverInstance,
            forceAPI,
            distinct
        )
        const result = results[0]
        return {
            type: PROSPECTIONS_COUNT_FETCHED,
            payload: [...result, state],
            ssr: true
        }
    } catch (error) {
        console.log("Error prospections count: ", error)
    }
}

export const asyncProspectionsCountFetchList = async (
    args = [],
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false,
    refreshCache = false
) => {
    try {
        const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
        const redis = redisInst

        if (refreshCache) {
            return Promise.all(
                args
                    .map(o => 
                        modelProspectionsStateCountFetch(
                            "firestore",
                            o.state,
                            o.locations,
                            o.filters,
                            runtimeConfig,
                            serverInstance,
                            forceAPI,
                            o.distinct,
                            true
                        )
                    )
                    .map(reflect)
            )
        }
        const results = await redis
            .pipeline(
                args.map(o => {
                    const objectHash = hash({
                        state: o.state,
                        locations: o.locations,
                        filters: o.filters,
                        distinct: o.distinct
                    })
                    return ['get', `getProspectionsStateCount:${objectHash}`]
                })
            )
            .exec()
        let finalResults = []
        for (let i = 0; i < results.length; i++) {
            const error = results[i][0]
            const result = results[i][1]
            if (error || result === null) {
                finalResults.push(
                    modelProspectionsStateCountFetch(
                        "firestore",
                        args[i].state,
                        args[i].locations,
                        args[i].filters,
                        runtimeConfig,
                        serverInstance,
                        forceAPI,
                        args[i].distinct,
                        refreshCache
                    )
                )
                continue
            }
            finalResults.push(
                Promise.resolve(
                    [
                        [
                        null,
                        null,
                        JSON.parse(result),
                        null
                        ]
                    ]
                )
            )
        }

        const listResult = await Promise.all(finalResults.map(reflect))
        return listResult.map((result, i) => {
            return {
                type: PROSPECTIONS_COUNT_FETCHED,
                payload: [...result.v[0], args[i].state],
                ssr: true
            }
        })
    } catch (error) {
        console.log("Error prospections count list: ", error)
    }
}

export const prospectionsCountFetch = (
    state,
    locations,
    filters = {},
    distinct = false,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false
) => {
    return async dispatch => {
        dispatch({
            type: PROSPECTIONS_COUNT_FETCH
        })
        try {
            const results = await modelProspectionsStateCountFetch(
                "firestore",
                state,
                locations,
                filters,
                runtimeConfig,
                serverInstance,
                forceAPI,
                distinct
            )
            const result = results[0]
            dispatch({
                type: PROSPECTIONS_COUNT_FETCHED,
                payload: [...result, state],
                ssr: false
            })
        } catch (error) {
            console.log("Error prospections count: ", error)
            dispatch({
                type: PROSPECTIONS_COUNT_ERROR,
                payload: error
            })
        }
    }
}

export const setCurrentParameters = (originParameters, currentParameters) => {
    return {
        type: SET_CURRENT_PARAMETERS,
        payload: { originParameters, currentParameters }
    }
}

export const prospectionsStateReset = () => {
    return {
        type: PROSPECTIONS_STATE_RESET
    }
}

export const prospectionsViewInfosFetch = (
    prospectionIds
) => {
    return async dispatch => {
        try {
            dispatch({ type: PROSPECTIONS_VIEW_INFOS_FETCH })

            const result = await modelProspectionsViewInfosFetch(
                prospectionIds
            )

            dispatch({
                type: PROSPECTIONS_VIEW_INFOS_FETCHED,
                payload: result,
                ssr: false
            })
        } catch (error) {
            console.log("Error prospections view infos fetch: ", error)
        }
    }
}
