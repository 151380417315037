export default {
    1: "0,2",
    2: "0,3",
    3: "0,4",
    4: "0,5",
    5: "0,6",
    6: "0,7",
    7: "0,8",
    8: "0,9",
    9: "1"
}
