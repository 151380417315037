import _ from "lodash"
import hash from "object-hash"
import centroid from "@turf/centroid"

import codeRegions from "../datas/codeRegions"
import departments from "../datas/departments"
import regionsGPS from "../datas/regionsGPS"

import Distance from "../helpers/Distance"

const categoriesTypesMapping = {
    'bureau': [
        'TECHNO PARKS',
        'BUSINESS PARKS',
        'INDUSTRIAL AREA',
        'HARBOR',
        'AIRPORT',
        'LOGISTICS HUB'
    ],
    'bureau-vente': [
        'TECHNO PARKS',
        'BUSINESS PARKS',
        'INDUSTRIAL AREA',
        'HARBOR',
        'AIRPORT',
        'LOGISTICS HUB'
    ],
    'entrepot': [
        'INDUSTRIAL AREA',
        'Z.A.C.',
        'LITTLE INDUSTRY',
        'BIG COMPANY SITE',
        'AIRPORT',
        'LOGISTICS HUB',
        'HARBOR',
        'FRICHE INDUSTRIELLE',
        'FOOD INDUSTRY',
        'Z.R.U.'
    ],
    'entrepot-vente': [
        'INDUSTRIAL AREA',
        'Z.A.C.',
        'LITTLE INDUSTRY',
        'BIG COMPANY SITE',
        'AIRPORT',
        'LOGISTICS HUB',
        'HARBOR',
        'FRICHE INDUSTRIELLE',
        'FOOD INDUSTRY',
        'Z.R.U.'
    ],
    'terrain': [
        'TECHNO PARKS',
        'BUSINESS PARKS',
        'INDUSTRIAL AREA',
        'Z.A.C.',
        'LITTLE INDUSTRY',
        'BIG COMPANY SITE',
        'AIRPORT',
        'LOGISTICS HUB',
        'HARBOR',
        'FRICHE INDUSTRIELLE',
        'FOOD INDUSTRY',
        'Z.R.U.',
        'COMMERCIAL AREA',
        'HOTELS & RESTAURANTS AREA'
    ]
}

const reflect = p =>
    p.then(
        v => ({ v, status: 'fulfilled' }),
        e => ({ e, status: 'rejected' })
    )

const modelGetAreaList = async (
    areaIds,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst

    if (refreshCache) {
        return Promise.all(
            areaIds
                .map(areaId => modelGetArea(areaId, runtimeConfig, serverInstance, true))
                .map(reflect)
        )
    }

    const results = await redis
        .pipeline(
            areaIds.map(k => {
            return ['get', `modelGetArea:${k}`]
            })
        )
        .exec()

    let finalResults = []
    for (let i = 0; i < results.length; i++) {
        const error = results[i][0]
        const result = results[i][1]
        if (error || result === null) {
            finalResults.push(modelGetArea(areaIds[i], runtimeConfig, serverInstance, refreshCache))
            continue
        }
        finalResults.push(Promise.resolve(JSON.parse(result)))
    }

    return Promise.all(finalResults.map(reflect))
}

const modelGetArea = async (
    areaId,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
    const redis = redisInst

    const redisKey = `modelGetArea:${areaId}`
    // console.log('REDIS KEY: ', redisKey)
  
    if (refreshCache) {
        await redis.del(redisKey)
    }
  
    try {
        const res = await redis.get(redisKey)
        // console.log('REDIS GET: ', redisKey, res)

        if (res === null) {
            throw 'doesnt_exist'
        }

        return JSON.parse(res)
    } catch (error) {
        // console.log('REDIS ERROR:', error)
    }
  
    const dbIndustrialInst = serverInstance && serverInstance.dbIndustrial ? serverInstance.dbIndustrial : null

    const infos = await dbIndustrialInst
        .collection('zones_activites')
        .findOne({ _id: areaId })
    let area = null
    if (infos) {
        area = { id: infos._id, data: _.omit(infos, ['_id']) }
    }
  
    redis.set(redisKey, JSON.stringify(area)).then(res => {
        // console.log('REDIS SET: ', res)
    })

    return area
}

const modelIndustrialAreasFetch = async (
    type,
    state = null,
    location = null,
    runtimeConfig = null,
    serverInstance = null,
    refreshCache = false
) => {
    try {
        if (typeof categoriesTypesMapping[type] === 'undefined') {
            return []
        }

        const dbIndustrialInst = serverInstance && serverInstance.dbIndustrial ? serverInstance.dbIndustrial : null
        const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
        const redis = redisInst
        const objectHash = hash({ type, state, location })
        const redisKey = `modelIndustrialAreasFetch:${objectHash}`

        if (refreshCache) {
            await redis.del(redisKey)
        }

        try {
            const res = await redis.get(redisKey)
            if (res === null) {
                throw 'doesnt_exist'
            }

            return JSON.parse(res)
        } catch (error) {
            // console.log('REDIS ERROR:', error)
        }

        let condition = null
        if (state && codeRegions[state] && location === null) {
            condition = new RegExp(`^(${departments.filter(o => o.codeRegion === codeRegions[state]).map(o => o.code).join('|')})`, 'gi')
        }
        if (location && ['departement'].indexOf(location.type) !== -1 && location.codeDepartement) {
            condition = new RegExp(`^${location.codeDepartement}`, 'gi')
        }
        if (location && ['commune', 'commune_all'].indexOf(location.type) !== -1 && location.code) {
            condition = location.code
        }
        let coordinates = null
        if (
            state &&
            codeRegions[state] &&
            regionsGPS.filter(o => o.code === codeRegions[state]).length > 0 &&
            location === null
        ) {
            coordinates = regionsGPS.filter(o => o.code === codeRegions[state])[0].coordinates
        }
        if (
            location &&
            ['departement'].indexOf(location.type) !== -1 &&
            location.codeDepartement &&
            departments.filter(o => o.code === location.codeDepartement).length > 0
        ) {
            coordinates = departments.filter(o => o.code === location.codeDepartement)[0].coordinates
        }
        if (location && ['commune', 'commune_all'].indexOf(location.type) !== -1 && location.code) {
            coordinates = [ location.long, location.lat ]
        }

        // console.log('location: ', location)
        // console.log('coordinates: ', coordinates)

        // if (!condition) return []

        let filteredAreas = []
        let areaIds = []
        if (
            type !== 'bureau' ||
            (
                type === 'bureau' &&
                location &&
                ['commune', 'commune_all'].indexOf(location.type) !== -1
            )
        ) {
            areaIds = await dbIndustrialInst
                .collection('zones_activites')
                .distinct(
                    '_id',
                    {
                        ...(condition ? {
                            $or: [
                                { nuts: condition },
                                { nuts2: condition },
                                { nuts3: condition },
                                { nuts4: condition },
                                { nuts5: condition }
                            ]
                        } : {}),
                        category: {
                            $in: categoriesTypesMapping[type]
                        }
                    }
                )
                // .limit(10)
        }
        if (
            type === 'bureau' &&
            (
                !state ||
                (location && ['departement'].indexOf(location.type) !== -1) ||
                (state && !location)
            )
        ) {
            let cursor = dbIndustrialInst
                .collection('zones_activites')
                .find(
                    {
                        ...(condition ? {
                            $or: [
                                { nuts: condition },
                                { nuts2: condition },
                                { nuts3: condition },
                                { nuts4: condition },
                                { nuts5: condition }
                            ]
                        } : {}),
                        category: {
                            $in: [
                                'TECHNO PARKS',
                                'BUSINESS PARKS'
                            ]
                        }
                    }
                )
                .sort( { area_square_meters: -1 } )
            for await (const doc of cursor) {
                areaIds.push(doc._id)
            }
        }

        if (areaIds.length < 10 && coordinates) {
            let cursor = await dbIndustrialInst
                .collection('zones_activites')
                .find(
                    {
                        geometry: {
                            $near: {
                                $geometry: { type: "Point", coordinates }
                            }
                        },
                        category: {
                            $in: categoriesTypesMapping[type]
                        },
                        _id: {
                            $nin: areaIds
                        }
                    },
                    { _id: 1 }
                )
                .limit(10 - areaIds.length)
            if (
                type === 'bureau' &&
                (
                    !state ||
                    (location && ['departement'].indexOf(location.type) !== -1) ||
                    (state && !location)
                )
            ) {
                cursor = cursor.sort( { area_square_meters: -1 } )
            }
            for await (const doc of cursor) {
                areaIds.push(doc._id)
            }
        }

        const areas = await modelGetAreaList(areaIds, runtimeConfig, serverInstance)

        filteredAreas = areas
            .filter(a => a.status === 'fulfilled')
            .map(area => {
                let id = area.v.id
                let data = area.v.data
                const centroidCoord = centroid(data.geometry).geometry.coordinates
                let newData = {
                    category: data.category,
                    name: data.name,
                    op_type: data.gest_type,
                    op_name: data.gest_name,
                    op_phone: data.gest_phone,
                    postcode: data.postcode,
                    surface: data.area_square_meters,
                    base_slug: data.base_slug,
                    slug: data.slug,
                    city: data.city,
                    nuts: data.nuts,
                    nuts2: data.nuts2,
                    nuts3: data.nuts3,
                    nuts4: data.nuts4,
                    nuts5: data.nuts5,
                    zone_id: data.zone_id,
                    id,
                    geometry: data.geometry,
                    centroid: centroidCoord,
                    distance: coordinates ? Distance(coordinates[1], coordinates[0], centroidCoord[1], centroidCoord[0]) : null
                }
                return newData
            })

        if (
            type === 'bureau' &&
            (
                !state ||
                (location && ['departement'].indexOf(location.type) !== -1) ||
                (state && !location)
            )
        ) {
            filteredAreas = _.take(_.reverse(_.sortBy(filteredAreas, ['surface'])), 10)
        }
        if (
            type !== 'bureau' ||
            (
                type === 'bureau' &&
                location &&
                ['commune', 'commune_all'].indexOf(location.type) !== -1
            )
        ) {
            if (coordinates) {
                filteredAreas = _.take(_.sortBy(_.reverse(_.sortBy(filteredAreas, ['surface'])), ['distance']), 10)
            }
            if (!coordinates) {
                filteredAreas = _.take(_.reverse(_.sortBy(filteredAreas, ['surface'])), 10)
            }
        }

        redis.set(redisKey, JSON.stringify(filteredAreas), 'EX', 60 * 60 * 24 * 365).then(res => {
            // console.log('REDIS SET: ', res)
        })

        return filteredAreas
    } catch (error) {
        throw error
    }
}

export {
    modelGetAreaList,
    modelGetArea,
    modelIndustrialAreasFetch
}